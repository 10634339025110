import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgBan = (props) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 155 155" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M151 75.5c0 41.702-33.798 75.5-75.5 75.5S0 117.202 0 75.5 33.798 0 75.5 0 151 33.798 151 75.5zM23.809 27.145C11.968 39.785 4.719 56.802 4.719 75.5c0 39.077 31.704 70.781 70.781 70.781 18.698 0 35.715-7.255 48.102-19.081L23.809 27.145zM146.281 75.5c0-39.077-31.704-70.781-70.781-70.781-18.698 0-35.715 7.249-48.355 19.09L127.2 123.602c11.826-12.387 19.081-29.404 19.081-48.367v.265z"
                  fill="#667B9A"
                  fill-rule="nonzero"
            />
        </Svg>
    </View>
)


export {
    SvgBan
}
