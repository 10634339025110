import { Text, TouchableOpacity, View, StyleSheet } from 'react-native'
import { ThemeColours } from '../Styles/Theme'
import React, {useState} from 'react'
import { lang } from '../Providers/LocalisationProvider'

const FilterButtons = ({ onFilterChange }) => {
    const [activeFilter, setActiveFilter] = useState('')

    const filters = [
        { label: lang.t('all'), value: '' },
        { label: lang.t('requests'), value: 'requests' },
        { label: lang.t('social'), value: 'social' },
    ];

    return (
        <View style={styles.filterButtons}>
            {filters.map((filter, index) => (
                <FilterButton
                    key={filter.value}
                    label={filter.label}
                    value={filter.value}
                    isActive={activeFilter === filter.value}
                    onPress={() => {
                        setActiveFilter(filter.value);
                        onFilterChange(filter.value);
                    }}
                    position={index === 0 ? 'start' : index === filters.length - 1 ? 'end' : 'center'}
                />
            ))}
        </View>
    )
}

const FilterButton = ({ label, value, isActive, onPress, position }) => (
    <View style={{ flex: 1 }}>
        <TouchableOpacity
            activeOpacity={0.9}
            onPress={onPress}
            style={[
                position === 'start' ? styles.filterButtonStart : position === 'end' ? styles.filterButtonEnd : styles.filterButtonCenter,
                { backgroundColor: isActive ? ThemeColours.blueGrey : ThemeColours.white }
            ]}
        >
            <Text
                style={[
                    styles.filterButtonText,
                    { color: isActive ? ThemeColours.white : ThemeColours.blueGrey }
                ]}
            >
                {label}
            </Text>
        </TouchableOpacity>
    </View>
)

export default FilterButtons

const styles = StyleSheet.create({
    filterButtons: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        justifyContent: 'center'
    },
    filterButtonStart: {
        backgroundColor: ThemeColours.steelBlue,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderWidth: 1,
        borderColor: ThemeColours.steelBlue,
    },
    filterButtonCenter: {
        backgroundColor: 'white',
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderColor: ThemeColours.steelBlue,
    },
    filterButtonEnd: {
        backgroundColor: 'white',
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
        paddingHorizontal: 12,
        paddingVertical: 6,
        borderWidth: 1,
        borderColor: ThemeColours.steelBlue,
    },
    filterButtonText: {
        fontSize: 17,
        textAlign: 'center',
        color: ThemeColours.steelBlue,
    }
})