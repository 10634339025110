import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import BackendService from '../Services/BackendService'

export const fetchMessages = createAsyncThunk('messages/fetchMessages', async (formData) => {
    try {
        const { conversation_id } = formData
        const response = await BackendService.getMessages(conversation_id)
        return response.data.reverse()
    } catch (error) {
        console.log(error)
        return []
    }
})

const initialState = {
    messages: [],
    conversation_id: -1,
    status: 'idle',
    error: null,
}

export const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        addMessage: (state, action) => {
            // Ensure data is an object, not string of json.
            let data = {}
            let message = {
                photo_url: null
            }
            if (typeof action.payload == 'string') {
                data = JSON.parse(action.payload)
                message = data.message
            } else {
                data = action.payload
                message = data.message
            }

            // Ensure message is part of conversation before adding.
            if (message?.conversation_id === state.conversation_id) {
                // Set photo_url when receive message with image via Pusher
                if (!message.photo_url && message.media && message?.media[0] && message?.media[0].collection_name === 'images') {
                    message = {
                        ...message,
                        photo_url: message.media[0].original_url
                    };
                }
                state.messages = [message, ...state.messages]
            }
        },
        setMessages: (state, action) => {
            state.messages = action.payload
        },
        setConversationId: (state, action) => {
            state.conversation_id = action.payload
        },
        resetMessagesStatus: (state, action) => {
            state.status = initialState.status
        },
        resetMessages: (state, action) => {
            state.messages = []
        },
        setMessageStatusLoading: (state, action) => {
            state.status = 'loading'
        },
        setMessageStatusSucceeded: (state, action) => {
            state.status = 'succeeded'
        },
        removeMessage: (state, action) => {
            // Ensure data is object, not string of json.
            let data = {}
            let message = {}
            if (typeof action.payload == 'string') {
                data = JSON.parse(action.payload)
                message = data.message
            } else {
                data = action.payload
                message = data.message
            }

            // Remove deleted message.
            state.messages = state.messages.filter(item => item.id !== message?.id)
        },
        setMessageIdFromResponse: (state, action) => {
            const { response, response: { token } } = action.payload
            if (!token) {
                return
            }
            const index = state.messages.findIndex(item => item.id === token)
            if (index !== -1) {
                const messagesCopy = state.messages
                messagesCopy[index].id = response.id
                state.messages = messagesCopy
            }
        }
    },
    extraReducers(builder) {
        builder
            .addCase(fetchMessages.pending, (state, action) => {
                state.status = 'loading'
            })
            .addCase(fetchMessages.fulfilled, (state, action) => {
                state.status = 'succeeded'
                // Add any fetched posts to the array
                state.messages = action.payload
            })
            .addCase(fetchMessages.rejected, (state, action) => {
                state.status = 'failed'
                state.error = action.error.message
            })
    }
})

// Action creators are generated for each case reducer function
export const {
    addMessage,
    setMessages,
    resetMessagesStatus,
    setMessageStatusLoading,
    setMessageStatusSucceeded,
    setConversationId,
    removeMessage,
    setMessageIdFromResponse,
    resetMessages
} = messagesSlice.actions

export const selectMessages = (state) => state.messages.messages

export const selectMessageById = (state, messageId) =>
    state.messages.messages.find((message) => message.id === messageId)

export default messagesSlice.reducer