import {
    StyleSheet,
    Text,
    TextInput,
    TouchableOpacity,
    View,
    PixelRatio,
    TouchableWithoutFeedback,
    Alert
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useState } from 'react'
import { lang } from '../Providers/LocalisationProvider'
import CountryCodes from '../data/CountryCodes.json'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import * as Contacts from 'expo-contacts'
import Database from '../data/ContactsDB'
import ContactsService from '../Services/ContactsService'
import PhoneNumberInput from '../Components/PhoneNumberInput'
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import dismissKeyboard from '../utils/dismissKeyboard'


const NewContactScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneCountry, setPhoneCountry] = useState()
    const [phoneDigits, setPhoneDigits] = useState('')
    const [errors, setErrors] = useState({})

    const canSave = () => {
        let validation = {}
        if (!firstName || firstName.length < 1) {
            validation.first_name = lang.t('please_enter_a_first_name')
        }
        // five digits excluding the country code
        if (!phoneDigits || phoneDigits.length < 5) {
            validation.phone = lang.t('please_enter_a_phone_number')
        }

        setErrors(validation)

        return Object.keys(validation).length === 0
    }

    function getNewContactData() {
        return {
            [Contacts.Fields.FirstName]: firstName,
            [Contacts.Fields.LastName]: lastName,
            [Contacts.Fields.PhoneNumbers]: [
                {
                    label: "mobile",
                    number: getFormattedPhoneNumber(),
                    // digits: '', // Option is set automatically from number.
                    // countryCode: '', // Option is set automatically from number.
                    isPrimary: true,
                }
            ],
        };
    }

    const getFormattedPhoneNumber = () => {
        const parsedNumber = parsePhoneNumberFromString(phoneDigits, phoneCountry)
        if (!parsedNumber) {
            // Potentially invalid number.
            return `${getCountryDialCode(phoneCountry)}${phoneDigits}`
        }
        return parsedNumber.number
    }

    const handleSave = async () => {
        // Validate
        if (false === canSave()) {
            return
        }

        // Check for permissions
        const { status } = await Contacts.requestPermissionsAsync()

        if (status === 'granted') {
            const contact = getNewContactData()

            // Save contact
            try {
                const contactId = await Contacts.addContactAsync(contact)
                //console.log(`Contact saved with ID: ${contactId}`)

                // Get contact data and insert into the database.
                const contactsData = await ContactsService.getContactsWithImages([{ id: contactId }])
                if (contactsData && contactsData.length) {
                    Database.insertContact(contactsData[0])
                }

            } catch(error) {
                console.log(`Failed to save new contact: ${error.message}`)

                Alert.alert(lang.t('unable_to_save_contact'), lang.t('we_were_unable_to_save_this_new_contact'), [
                    { text: lang.t('cancel'), onPress: () => navigation.goBack(), style: 'cancel', },
                    { text: lang.t('try_again'), onPress: () => handleSave() },
                ])
            }

            // Show success message, go back to previous screen
            Alert.alert(lang.t('new_contact_saved'),  lang.t('your_new_contact_has_been_saved'), [
                { text: lang.t('ok'), onPress: () => navigation.goBack() },
            ])
        } else {
            console.log('Permission to access contacts was denied.')
            // Show alert, give user options to retry or cancel
            Alert.alert(lang.t('unable_to_access_contacts'), lang.t('please_give_our_app_permission_to_save_contact'), [
                { text: lang.t('cancel'), onPress: () => navigation.goBack(), style: 'cancel', },
                { text: lang.t('try_again'), onPress: () => handleSave() },
            ])
        }
    }


    const handleCancel = () => {
        navigation.goBack()
    }

    const getCountryDialCode = (countryCode) => {
        countryCode = countryCode.toUpperCase()
        const country = CountryCodes.find((item) => item.code === countryCode)

        return country?.dial_code
    }

    const handlePhoneNumberChange = (phoneDigits, phoneCountry) => {
        setPhoneDigits(phoneDigits)
        setPhoneCountry(phoneCountry)
    }

    return (
    <View style={styles.screen}>
        <TouchableWithoutFeedback style={{flex: 1}} onPress={dismissKeyboard}>
            <View style={{flex:1, paddingTop: insets.top}}>
                <View>
                    <View style={styles.header}>
                        <View>
                            <TouchableOpacity onPress={handleCancel}>
                                <Text style={styles.headerOptionText}>{lang.t('cancel')}</Text>
                            </TouchableOpacity>
                        </View>
                        <View style={{flex: 1}}>
                            <Text style={styles.headerText}>{lang.t('new_contact')}</Text>
                        </View>
                        <View>
                            <TouchableOpacity onPress={handleSave}>
                                <Text style={styles.headerOptionText}>{lang.t('save')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
                <View style={styles.form}>
                    <View style={styles.formGroup}>
                        <Text style={styles.formLabel}>{lang.t('first_name')}</Text>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.formControl}
                                onChangeText={setFirstName}
                                value={firstName}
                                placeholder={lang.t('first_name')}
                            />
                        </View>
                        {errors?.firstName &&
                            <Text style={styles.formValidationLabel}>{errors.firstName}</Text>
                        }
                    </View>
                    <View style={styles.formGroup}>
                        <Text style={styles.formLabel}>{lang.t('last_name')}</Text>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.formControl}
                                onChangeText={setLastName}
                                value={lastName}
                                placeholder={lang.t('last_name')}
                            />
                        </View>
                        {/* Optional */}
                    </View>
                    <PhoneNumberInput
                        phoneDigits={phoneDigits}
                        phoneCountry={phoneCountry}
                        onPhoneNumberChange={handlePhoneNumberChange}
                    />
                </View>
            </View>
        </TouchableWithoutFeedback>
    </View>
    )
}

export default NewContactScreen

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        paddingVertical: 15,
        paddingHorizontal: 20,
        flexDirection: 'row',
        justifyContent: 'center',
    },
    headerText: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 18,
        color: ThemeColours.midnightBlue,
        paddingTop: 5,
        lineHeight: 32 * PixelRatio.getFontScale(),
        textAlign: 'center',
    },
    headerOptionText: {
        color: ThemeColours.sunshineGold,
        fontSize: 18,
        lineHeight: 32 * PixelRatio.getFontScale(),
    },
    form: {
        paddingHorizontal: 15,
        backgroundColor: ThemeColours.white,
    },
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
})