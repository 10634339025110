import { Text, StyleSheet, View, SafeAreaView, TouchableOpacity, ImageBackground, Image, ScrollView, Animated } from 'react-native'
import { useLayoutEffect, useEffect, useRef } from 'react'
import { useNavigation } from '@react-navigation/native'
import { signOut, useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import * as Clipboard from 'expo-clipboard';

const ProfileScreen = () => {
    const fadeAnim = useRef(new Animated.Value(0)).current;

    const navigation = useNavigation()
    const lang = useLocalisationContext()
    const { user, setAuthenticated } = useAuthContext()
    const isLoggedIn = !!user?.id //convert to boolean
    const insets = useSafeAreaInsets()

    const arrowIcon = (<Image
        source={require('../assets/images/chevronRightIcon-2x.png')}
        style={{ width: 8, height: 15 }}
    />)
    const handleCopyToClipboardAnimation = () => {
        Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 100,
            useNativeDriver: true,
        }).start();
        setTimeout(() => {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 1000,
                useNativeDriver: true,
            }).start();
        }, 1000)
    }
    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
    }, [])

    useEffect(() => {
        console.log(user);
    }, [user]);

    if (!isLoggedIn) {
        return (
            <SafeAreaView style={styles.container}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <View style={styles.header}>
                    <Text style={styles.headerText}>{lang.t('profile')}</Text>
                </View>
                <View style={styles.content}>
                    <Text style={styles.h1}>{lang.t('log_in_to_see_your_profile')}</Text>
                    <Text style={styles.subheading}>{lang.t('make_changes_and_update_your_image')}</Text>
                    <View style={{maxWidth: 335, marginBottom: 15}}>
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('TermsAndConditionsScreen')}>
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('terms_and_conditions')}</Text>
                            {arrowIcon}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('PrivacyPolicyScreen')}>
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('privacy_policy')}</Text>
                            {arrowIcon}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('EulaScreen')}>
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('eula')}</Text>
                            {arrowIcon}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('CookiePolicyScreen')}>
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('cookies_policy')}</Text>
                            {arrowIcon}
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('AboutScreen')}>
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('about')}</Text>
                            {arrowIcon}
                        </TouchableOpacity>
                    </View>
                    <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={[styles.button, { maxWidth: 335 }]}>
                        <Text style={Theme.buttonText}>{lang.t('log_in')}</Text>
                    </TouchableOpacity>
                </View>

            </SafeAreaView>
        )
    }

    return (
        <ScrollView contentContainerStyle={{ paddingBottom: 48 }}>
            <FocusAwareStatusBar barStyle="light-content" />
            <ImageBackground
                source={{ uri: user.cover_photo }}
                style={{ height: (148 + insets.top), width: '100%', paddingTop: insets.top, backgroundColor: ThemeColours.steelBlue }}
            >
                <View style={{marginTop: 40}}>
                    <View style={styles.headerRow}>
                        <View style={{ position: 'absolute', right: 15 }}>
                            <TouchableOpacity onPress={() => navigation.navigate('ProfileEditScreen')} style={styles.buttonEdit} activeOpacity={0.75}>
                                <Text style={styles.buttonTextEdit}>{lang.t('edit')}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ImageBackground>
            <View style={{ flex: 1 }}>
                <View style={styles.profileContainer}>
                    <View style={styles.profileImageContainer}>
                        <Image
                            source={{ uri: user.avatar }}
                            style={styles.profileImage}
                        />
                        {user.is_verified &&
                            <Image
                                source={require('../assets/images/verifiedIconMini-2x.png')}
                                style={styles.profileBadge}
                            />
                        }
                    </View>
                    <Text style={styles.h1} numberOfLines={1}>{user.name}</Text>
                    {user?.trade?.name &&
                        <Text style={styles.profileTrade}>{user.trade.name}</Text>
                    }
                    {user.company_name &&
                        <Text style={styles.profileMeta}>{user.company_name}</Text>
                    }
                    {user.email && <View style={{}}>
                        <Text style={styles.profileMeta} onLongPress={async () => {
                            await Clipboard.setStringAsync(user.email || '');
                            handleCopyToClipboardAnimation();
                        }}>{user.email}</Text>
                    </View>}

                    {user?.full_phone_number &&
                        <View style={{}}>
                        <Text style={styles.profileMeta} onLongPress={async () => {
                            await Clipboard.setStringAsync(user.full_phone_number || '');
                            handleCopyToClipboardAnimation();
                        }}>{user.full_phone_number}</Text>
                        </View>
                    }
                    <Animated.Text style={{ marginLeft: 12, opacity: fadeAnim,}}>{lang.t('copied_to_clipboard')}</Animated.Text>
                </View>

                {!user.email_verified_at &&
                    <View style={styles.lowerThrid}>
                        <Text style={styles.lowerThridHeading}>{lang.t('why_verify_your_profile')}</Text>
                        <Text style={styles.lowerThridText}>{lang.t('verify_profile_reason')}</Text>
                        <TouchableOpacity style={styles.button} onPress={() => navigation.navigate('AccountEmailVerifyScreen')}>
                            <Text style={styles.buttonText}>{lang.t('verify_account')}</Text>
                        </TouchableOpacity>
                    </View>
                }

                { isLoggedIn &&
                    <View style={styles.buttonGroup}>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('ReferAndEarnScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('refer_and_earn')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('NotificationPreferencesScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('notifications')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('PaywallScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('subscription')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('ForgottenPasswordScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('password')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    </View>
                }
                <View style={styles.buttonGroup}>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('TermsAndConditionsScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('terms_and_conditions')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('PrivacyPolicyScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('privacy_policy')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('EulaScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('eula')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('CookiePolicyScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('cookies_policy')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                    <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('AboutScreen')}>
                        <Text style={styles.buttonText} numberOfLines={1}>{lang.t('about')}</Text>
                        {arrowIcon}
                    </TouchableOpacity>
                </View>


                <View style={styles.buttonGroup}>
                    {isLoggedIn ?
                        <TouchableOpacity
                            style={styles.button}
                            activeOpacity={0.8}
                            onPress={async () => {
                                try {
                                    await signOut();
                                    setAuthenticated(undefined);
                                } catch (error) {
                                    setAuthenticated(undefined);
                                    console.error(error);
                                }
                                //then redirect to home.
                                if (user?.is_merchant) {
                                    navigation.navigate('HomeTabs', { screen: 'Requests' });
                                } else {
                                    navigation.navigate('Home');
                                }
                            }}
                        >
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('sign_out')}</Text>
                        </TouchableOpacity>
                        :
                        <TouchableOpacity style={styles.button} activeOpacity={0.8} onPress={() => navigation.navigate('LoginScreen')} >
                            <Text style={styles.buttonText} numberOfLines={1}>{lang.t('sign_in')}</Text>
                        </TouchableOpacity>
                    }
                </View>
                {isLoggedIn &&
                    <TouchableOpacity style={[styles.button, styles.buttonDelete]} activeOpacity={0.8} onPress={() => navigation.navigate('DeleteAccountScreen')} >
                        <Text style={[styles.buttonText, styles.buttonDeleteText]} numberOfLines={1}>{lang.t('delete_account')}</Text>
                    </TouchableOpacity>
                }
            </View>
        </ScrollView>
    )
}

export default ProfileScreen;

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    headerRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerText: {
        textAlign: 'center',
        lineHeight: 70,
        fontSize: 18,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    headerTextLight: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
    },
    buttonEdit: {
        backgroundColor: '#ffffff',
        paddingVertical: 5,
        paddingHorizontal: 15,
        borderRadius: 16,
    },
    buttonTextEdit: {
        fontSize: 18,
        lineHeight: 20,
        color: ThemeColours.midnightBlue,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    h1: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        marginBottom: 25,
        fontSize: 25,
        letterSpacing: 0,
        textAlign: 'center',
        lineHeight: 32,
        maxWidth: 275,
        fontFamily: 'ITCAvantGardeProBold',
    },
    subheading: {
        ...Theme.subheadingText,
        color: ThemeColours.blueGrey,
        marginBottom: 35,
        textAlign: 'center',
        width: '100%',
        marginHorizontal: 18,
    },
    messageIcon: {
        width: 59,
        height: 44,
        marginBottom: 25,
    },
    loadingIcon: {
        marginBottom: 25,
    },
    profileContainer: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
    },
    profileMeta: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        lineHeight: 20,
        marginBottom: 10,
    },
    profileTrade: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        lineHeight: 20,
        paddingVertical: 5,
        paddingHorizontal: 15,
        marginBottom: 20,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccd3dd',
        borderRadius: 16
    },
    profileImageContainer: {
        position: 'relative',
        height: 100,
        width: 100,
        marginBottom: 25,
        marginTop: -46,
        //backgroundColor: ThemeColours.blueGrey,
        borderRadius: 3,
    },
    profileImage: {
        height: 100,
        width: 100,
        borderRadius: 6,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: ThemeColours.white,
        backgroundColor: ThemeColours.blueGrey,
    },
    profileBadge: {
        position: 'absolute',
        bottom: -17,
        right: -17,
        elevation: 2,
        width: 34,
        height: 34,
    },
    lowerThrid: {
        paddingTop: 20,
        paddingHorizontal: 20,
        paddingBottom: 22,
        marginTop: 25,
        backgroundColor: ThemeColours.cloudWhite,
    },
    lowerThridHeading: {
        fontSize: 18,
        lineHeight: 20,
        marginBottom: 5,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    lowerThridText: {
        fontSize: 14,
        letterSpacing: 0,
        color: ThemeColours.blueGrey,
        lineHeight: 20,
        marginBottom: 16,
    },
    header: {
        height: 70,
        width: '100%',
        backgroundColor: ThemeColours.white,
        shadowColor: ThemeColours.steelBlueShadow,
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
    },
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 50,
        width: '100%',
        backgroundColor: ThemeColours.white,
        paddingLeft: 25,
        paddingRight: 40,
    },
    buttonText: {
        flex: 1,
        lineHeight: 50,
        fontSize: 18,
        color: ThemeColours.midnightBlue,
    },
    buttonDelete: {
        backgroundColor: 'transparent',
        marginTop: 40,
        marginBottom: 40,
    },
    buttonDeleteText: {
        color: ThemeColours.denialRed,
    },
    buttonGroup: {
        marginTop: 15,
    },
    profileContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    profileImageContainer: {
        position: 'relative',
        height: 100,
        width: 100,
        marginBottom: 25,
        marginTop: 46,
        backgroundColor: ThemeColours.blueGrey,
        borderRadius: 3,
    },
    profileImage: {
        height: 100,
        width: 100,
    },
    profileBadge: {
        position: 'absolute',
        bottom: -17,
        right: -17,
        elevation: 2,
        width: 34,
        height: 34,
    },
    profileName: {
        marginBottom: 30,
        fontSize: 23,
        lineHeight: 28,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    }
})