import React from 'react'
import { Pressable, Text, Alert, StyleSheet } from 'react-native'
import Purchases from 'react-native-purchases'
import { ThemeColours } from '../Styles/Theme'
import { useLocalisationContext } from '../Providers/LocalisationProvider'


const RestorePurchasesButton = () => {

    const lang = useLocalisationContext()

    const restorePurchases = async () => {
        try {
            await Purchases.restorePurchases()
        } catch (e) {
            Alert.alert('Error restoring purchases', e.message);
        }
    }

    return (
        <Pressable onPress={restorePurchases} style={styles.restoreButton}>
            <Text style={styles.restoreButtonText}>{lang.t('restore_purchases')}</Text>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    restoreButton: {
        minHeight: 50,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        marginHorizontal: 15,
    },
    restoreButtonText: {
        fontSize: 14,
        textAlign: 'center',
        color: ThemeColours.midnightBlue
    }
})

export default RestorePurchasesButton