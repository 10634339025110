import * as SQLite from 'expo-sqlite';
import { Platform } from 'react-native';

class Database {
    static instance;

    constructor() {
        if (Database.instance) {
            return Database.instance;
        }

        if (Platform.OS === "web") {
            Database.instance = {
                transaction: () => {
                    return {
                        executeSql: () => { },
                    };
                },
            };
            this.db = {
                transaction: () => {
                    return {
                        executeSql: () => { },
                    };
                },
            };
        } else {
            this.db = SQLite.openDatabase('contacts.db');
        }


        this.createTable();

        Database.instance = this;
    }

    createTable() {
        this.db.transaction(tx => {
            tx.executeSql(
                `CREATE TABLE IF NOT EXISTS contacts (
                    id INTEGER PRIMARY KEY NOT NULL, 
                    is_material_network_user INTEGER,
                    material_network_id INTEGER,
                    is_blocked INTEGER, 
                    raw_contact_id TEXT, 
                    number TEXT, 
                    country_code TEXT,
                    full_phone_number TEXT, 
                    display_name TEXT, 
                    avatar_url TEXT
                    );`,
                [],
                () => console.log('Contacts table created successfully'),
                (_, error) => console.log('Error creating contacts table:', error)
            );
        });
    }

    resetDatabase() {
        this.db.transaction(tx => {
            tx.executeSql(
                'DROP TABLE IF EXISTS contacts;',
                [],
                () => {
                    console.log('Contacts table dropped successfully');
                    this.createTable();
                },
                (_, error) => console.log('Error dropping contacts table:', error)
            );
        });
    }

    getContactByRawId(rawId, callback) {
        this.db.transaction(tx => {
            tx.executeSql(
                'SELECT * FROM contacts WHERE raw_contact_id = ?;',
                [rawId],
                (_, { rows }) => {
                    if (rows.length > 0) {
                        callback(rows.item(0));
                    } else {
                        callback(null);
                    }
                },
                (_, error) => console.log('Error retrieving contact:', error)
            );
        });
    }

    findContact(contactId, callback) {
        this.db.transaction(tx => {
            tx.executeSql(
                'SELECT * FROM contacts WHERE id = ?;',
                [contactId],
                (_, { rows }) => {
                    if (rows.length > 0) {
                        callback(rows.item(0));
                    } else {
                        callback({});
                    }
                },
                (_, error) => console.log('Error retrieving contact:', error)
            );
        });
    }

    getContactCount(callback) {
        this.db.transaction(tx => {
            tx.executeSql(
                'SELECT COUNT(*) as cnt FROM contacts;',
                [],
                (_, { rows }) => callback(rows.item(0).cnt),
                (_, error) => console.log('Error retrieving contact:', error)
            );
        });
    }

    getContacts(callback) {
        this.db.transaction(tx => {
            tx.executeSql(
                'SELECT * FROM contacts ORDER BY display_name ASC;',
                [],
                (_, { rows }) => callback(rows),
                (_, error) => console.log('Error retrieving contacts:', error)
            );
        });
    }

    insertContact(contact) {
        this.db.transaction(tx => {
            tx.executeSql(
                `INSERT INTO contacts (
                    is_material_network_user,
                    material_network_id,
                    is_blocked,
                    raw_contact_id,
                    number,
                    country_code,
                    full_phone_number,
                    display_name,
                    avatar_url
                )
                VALUES (?, ?, ?, ?, ?, ?, ?, ?, ?);`,
                [
                    //contact.id, 
                    0,
                    0,
                    0,
                    contact.id,
                    contact.phoneNumbers ? contact.phoneNumbers[0].number : null,
                    contact.phoneNumbers ? contact.phoneNumbers[0].countryCode : null,
                    contact.phoneNumbers ? contact.phoneNumbers[0].fullPhoneNumber : null,
                    contact.name,
                    contact.imageAvailable ? contact.image.uri : null
                ],
                () => console.log('Contact inserted successfully'),
                (_, error) => console.log('Error inserting contact:', error)
            );
        });
    }

    updateContact(fullPhoneNumber, contactData, callback) {
        this.db.transaction(
            tx => {
                let values = [
                    contactData.is_material_network_user,
                    contactData.material_network_id,
                    contactData.is_blocked,
                    contactData.avatar_url,
                    fullPhoneNumber
                ];
                tx.executeSql(`UPDATE contacts 
                    SET is_material_network_user = ?, material_network_id = ?, is_blocked = ?, avatar_url = ? 
                    WHERE full_phone_number = ?;`, values);
            },
            null,
            callback
        );
    }

    updateExistingNonMember(contactID, contact, callback) {
        this.db.transaction(
            tx => {
                let values = [
                    contact.phoneNumbers ? contact.phoneNumbers[0].number : null,
                    contact.phoneNumbers ? contact.phoneNumbers[0].countryCode : null,
                    contact.phoneNumbers ? contact.phoneNumbers[0].fullPhoneNumber : null,
                    contact.name,
                    contact.imageAvailable ? contact.image.uri : null,
                    contactID
                ];
                tx.executeSql(`UPDATE contacts 
                    SET number = ?, country_code = ?, full_phone_number = ?, display_name = ?, avatar_url = ? 
                    WHERE id = ?;`, values);
            },
            null,
            callback
        );
    }

    setContactBlockedStatus(contactID, status, callback) {
        this.db.transaction(
            tx => {
                let values = [
                    status,
                    contactID
                ];
                tx.executeSql(`UPDATE contacts 
                    SET is_blocked = ?
                    WHERE id = ?;`, values);
            },
            null,
            callback
        );
    }

}

export default new Database();
