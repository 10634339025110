import {
  Text,
  StyleSheet,
  View,
  TouchableOpacity,
  SafeAreaView,
  Image,
  ActivityIndicator,
  FlatList,
  TouchableWithoutFeedback
} from 'react-native'
import React, {useLayoutEffect, useEffect, useState, useCallback} from 'react'
import { useNavigation } from '@react-navigation/native'
import {Theme, ThemeColours} from '../Styles/Theme'
import ConversationItem from '../Components/ConversationItem'
import { selectRequests, fetchRequests, toggleArchived } from '../Store/requestsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useAuthContext } from '../auth'
import { lang } from '../Providers/LocalisationProvider'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useIsFocused } from '@react-navigation/native'
import MessagesHeader from '../Components/MessagesHeader'
import SearchInput from '../Components/SearchInput'
import ArchivedMessagesButton from '../Components/ArchivedMessagesButton'
import FilterButtons from '../Components/FilterButtons'
import BackendService from '../Services/BackendService'
import dismissKeyboard from '../utils/dismissKeyboard'
import ConversationTypes from '../data/ConversationTypes'


const RequestsScreen = () => {

  const navigation = useNavigation()
  const dispatch = useDispatch()
  const requests = useSelector(selectRequests)
  const requestsStatus = useSelector(state => state.requests.status)
  const errorMessage = useSelector(state => state.requests.error)
  const { user } = useAuthContext()
  const isLoggedIn = !!user?.id //convert to boolean
  const isFocused = useIsFocused()
  const [filteredRequests, setFilteredRequests] = useState(requests)
  const [activeFilter, setActiveFilter] = useState('')
  const [showArchived, setShowArchived] = useState(false)
  const [archivedCount, setArchivedCount] = useState(0)
  const [searchResultIds, setSearchResultIds] = useState([])
  const [searchText, setSearchText] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [searchStatusText, setSearchStatusText] = useState('')
  let previousOpenedRow
  let debounceTimeout


  useLayoutEffect(() => {
    navigation.setOptions({
        headerShown: false,
    });
  }, [])

  useEffect(() => {
    if(isFocused){
      dispatch(fetchRequests())
    }
  }, [isFocused])

  useEffect(() => {
    if (isLoggedIn && requestsStatus === 'idle') {
      dispatch(fetchRequests())
    }
  }, [requestsStatus, isLoggedIn])

  useEffect(() => {
    handleFilterChange(activeFilter)
    const count = requests.filter(request => request?.is_archived).length
    setArchivedCount(count)
  }, [requests, showArchived, searchResultIds, isSearching])

  useEffect(() => {
    const text = getSearchStatusText()
    setSearchStatusText(text)
  }, [searchResultIds, isSearching, searchText])

  const handleFilterChange = (value) => {
    setActiveFilter(value)
    let filtered = requests
    // Filter based on tabs
    switch (value) {
      case 'requests':
        filtered = requests.filter(request => request?.conversation_type === ConversationTypes.REQUEST)
        break
      case 'social':
        filtered = requests.filter(request => request?.conversation_type === ConversationTypes.SOCIAL)
        break
      default:
        break
    }
    // Filter based on search query
    if (searchText && searchResultIds && searchResultIds.length) {
      filtered = filtered.filter(request => searchResultIds.indexOf(request.id) !== -1)
    }
    // Filter based on archive option
    if (showArchived) {
      filtered = filtered.filter(request => request?.is_archived === 1)
    } else {
      filtered = filtered.filter(request => request?.is_archived === 0)
    }
    setFilteredRequests(filtered)
  }

  const onRefresh = () => {
    dispatch(fetchRequests())
  }

  const openArchive = () => {
    setShowArchived(!showArchived)
    handleFilterChange(activeFilter)
  }

  const handleActionOpen = (rowReference) => {
    if (previousOpenedRow && previousOpenedRow !== rowReference) {
      previousOpenedRow.close()
    }
    previousOpenedRow = rowReference
  }

  const handleArchiveItem = (id) => {
    // Find the conversation to archive
    const conversation = filteredRequests.find((c) => c.id === id)

    // Find conversation in the redux slice, change the archived property value
    dispatch(toggleArchived({id}))

    // Call the api endpoint
    if (conversation?.is_archived === 1) {
      BackendService.unarchiveConversation(id).then(r => {}).catch(error => console.log(error))
    } else {
      BackendService.archiveConversation(id).then(r => {}).catch(error => console.log(error))
    }
  }

  const search = (value) => {
    setSearchText(value)
    value = value.trim()

    if (!value) {
      setSearchResultIds([])
      return
    }

    // Clear the previous timeout
    if (debounceTimeout) {
      clearTimeout(debounceTimeout)
    }

    // Searching
    setIsSearching(true)

    debounceTimeout = setTimeout(async () => {
      try {
        const result = await BackendService.searchConversations(value)
        //console.log(result)
        const conversationIds = result.map((item) => item.conversation_id)
        setSearchResultIds(conversationIds)
        setIsSearching(false)
      } catch (error) {
        console.log(error)
        setSearchResultIds([])
        setIsSearching(false)
      }
    }, 700)
  }

  const getSearchStatusText = () => {
    const len = searchResultIds.length
    let statusText = ''
    switch (len) {
      case 0:
        statusText = `${lang.t('no_results_for')} "${searchText}"`
        break;
      case 1:
        statusText = `${lang.t('one_result_for')} "${searchText}"`
        break;
      default:
        statusText = `${ lang.t('showing_x_results_for', {resultsCount: searchResultIds.length}) } "${searchText}"`
    }

    if (isSearching) {
      statusText = lang.t('searching')
    }

    return statusText
  }

  const handleCloseArchive = () => {
    setShowArchived(false)
    handleFilterChange(activeFilter)
  }

  const keyExtractor = useCallback(item => item.id, [])

  if (!isLoggedIn) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <MessagesHeader showEdit={false} showNew={false} />
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('log_in_to_see_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('deals_on_materials_right_at_your_fingertips')}</Text>
          <TouchableOpacity onPress={() => navigation.navigate('LoginScreen')} style={[Theme.button, styles.button]}>
            <Text style={Theme.buttonText}>{lang.t('log_in')}</Text>
          </TouchableOpacity>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'loading') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <MessagesHeader showEdit={false} showNew={false} />
        <View style={styles.content}>
          <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
          <Text style={styles.h1}>{lang.t('loading')}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'error') {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <MessagesHeader showEdit={false} showNew={false} />
        <View style={styles.content}>
          <Text style={styles.h1}>{lang.t('sorry_something_went_wrong')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('we_were_unable_to_load_your_requests')}</Text>
          <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{errorMessage}</Text>
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'succeeded' && requests.length === 0) {
    return (
      <SafeAreaView style={styles.container}>
        <FocusAwareStatusBar barStyle="dark-content" />
        <MessagesHeader showEdit={false} showNew={true} />
        <View style={styles.content}>
          <Image source={require('../assets/images/messagesIconLarge-2x.png')} 
            style={styles.messageIcon}/>
          <Text style={styles.h1}>{lang.t('no_messages')}</Text>
          {
            !user?.is_merchant ? <>
              <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('any_requests_you_make_for_materials_will_be_stored_here')}</Text>
              <TouchableOpacity onPress={() => navigation.navigate('HomeTabs', { screen: 'Home' })} style={[Theme.button, styles.button]}>
                <Text style={Theme.buttonText}>{lang.t('start_a_request')}</Text>
              </TouchableOpacity>
            </> :
            <Text style={[Theme.subheadingText, Theme.textBlueGrey, styles.subheading]}>{lang.t('merchant_any_requests_you_make_for_materials_will_be_stored_here')}</Text>
          }
        </View>
      </SafeAreaView>
    )
  }

  if (requestsStatus === 'succeeded') {
    const renderItem = ({item}) => {
      return (
          <ConversationItem
              key={item.id}
              id={item.id}
              user={user}
              item={item}
              // name={name}
              unreadCount={item.unread_count}
              dateTime={item?.last_message[0]?.created_at}
              lastMessage={item?.last_message[0]?.message}
              isArchived={item?.is_archived}
              onActionOpen={handleActionOpen}
              onArchiveItem={handleArchiveItem}
              // logoUrl={{ uri: logoUrl }}
              imgUrl={{ uri: user.is_merchant ? null : item?.merchant?.cover_photo }}
              merchantId={item?.merchant?.id}
              addressString={item?.merchant_address}
              merchantLat={ item?.merchant?.lat }
              merchantLng={ item?.merchant?.lng }
          />
      );
    }

    if (showArchived) {
      return (
        <SafeAreaView style={styles.container}>
          <FocusAwareStatusBar barStyle="dark-content" />
          <MessagesHeader
              showEdit={false}
              showNew={false}
              headerText={lang.t('archived_messages')}
              showCloseArchive={true}
              onCloseArchive={handleCloseArchive}
          />
          <FlatList
              style={styles.requestsListArchive}
              contentContainerStyle={styles.contentContainer}
              data={filteredRequests}
              renderItem={renderItem}
              keyExtractor={keyExtractor}
              onRefresh={onRefresh}
              refreshing={requestsStatus === 'loading'}
          />
        </SafeAreaView>
      )
    }

    return (
      <TouchableWithoutFeedback onPress={dismissKeyboard}>
        <SafeAreaView style={styles.container}>
          <FocusAwareStatusBar barStyle="dark-content" />
          <MessagesHeader showEdit={false} showNew={true} />
          <SearchInput
              text={searchText}
              onSearch={search}
              placeholder={lang.t('search_messages')}
              containerStyle={styles.searchInput}
          />
          <ArchivedMessagesButton onPress={openArchive} count={archivedCount} />
          <FilterButtons onFilterChange={handleFilterChange} />
          <View style={styles.paddingTop15}></View>
          {(searchText && searchResultIds) ?
              <View style={styles.searchStatusBar}>
                <Text style={styles.searchStatusText}>{searchStatusText}</Text>
              </View>
              : null}
          <FlatList 
            style={styles.requestsList}
            contentContainerStyle={styles.contentContainer}
            data={filteredRequests}
            renderItem={renderItem}
            keyExtractor={keyExtractor}
            onRefresh={onRefresh}
            refreshing={requestsStatus === 'loading'}
            />
        </SafeAreaView>
      </TouchableWithoutFeedback>
    )
  }
}

export default RequestsScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ThemeColours.cloudWhite,
  },
  header: {
    height: 73,
    // backgroundColor: 'red',
    // justifyContent: 'center',
    // alignItems: 'center'
  },
  headerText: {
    fontSize: 18,
    lineHeight: 32,
    textAlign: 'center',
    fontWeight: 'bold',
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  content: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  },
  h1: {
    marginBottom: 25,
    fontSize: 25,
    letterSpacing: 0,
    textAlign: 'center',
    lineHeight: 32,
    maxWidth: 275,
    color: ThemeColours.midnightBlue,
    fontFamily: 'ITCAvantGardeProBold',
  },
  subheading: {
    marginBottom: 35,
    textAlign: 'center',
    maxWidth: 275,
  },
  button: {
    maxWidth: 335,
    width: '100%',
  },
  messageIcon: {
    width: 59,
    height: 44,
    marginBottom: 25,
  },
  loadingIcon: {
    marginBottom: 25,
  },
  contentContainer: {
    shadowColor: ThemeColours.steelBlueShadow,
    shadowOpacity: 0.5,
    shadowRadius: 15,
    shadowOffset: {
      width: 0,
      height: -10
    },
  },
  paddingTop15: {
    paddingTop: 15,
  },
  searchInput: {
    marginBottom: 24,
  },
  requestsList: {
    backgroundColor: ThemeColours.white,
  },
  requestsListArchive: {
    backgroundColor: ThemeColours.white,
    paddingTop: 15,
  },
  searchStatusBar: {
    paddingHorizontal: 20,
    paddingVertical: 8,
    backgroundColor: '#fdd79d',
  },
  searchStatusText: {
    color: ThemeColours.midnightBlue,
    fontSize: 16,
  }
})
