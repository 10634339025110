import { StyleSheet, TouchableOpacity, Image } from 'react-native'

const closeIcon = require('../assets/images/closeIcon-2x.png')
const CloseButton = ({style, onPress}) => {
  return (
    <TouchableOpacity style={[styles.button, styles.buttonClose, style]} 
        onPress={onPress}>
        <Image source={closeIcon} style={styles.closeIcon}/>
    </TouchableOpacity>
  )
}

export default CloseButton

const styles = StyleSheet.create({
    button: {
        backgroundColor: 'rgb(251,176,59)',
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        elevation: 2, //Android only shadow property
        borderRadius: 3,
        padding: 13,
    },
    buttonClose: {
        height: 40,
        width: 40,
        position: 'absolute',
        right: 15,
        top: 15,
    },
    closeIcon: {
        width: 14,
        height: 14,
    }
})