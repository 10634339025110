import {Alert, StyleSheet, Text, TextInput, TouchableOpacity, View} from 'react-native'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import Header from '../Components/Header'
import {useEffect, useState} from 'react'
import Database from '../data/ContactsDB'
import {useNavigation, useRoute} from '@react-navigation/native'
import * as Contacts from 'expo-contacts'
import ContactsService from '../Services/ContactsService'
import PhoneNumberInput from '../Components/PhoneNumberInput'
import {Theme, ThemeColours} from '../Styles/Theme'
import CountryCodes from '../data/CountryCodes.json'
import {parsePhoneNumberFromString} from 'libphonenumber-js'
import {lang} from "../Providers/LocalisationProvider";

const ContactEditScreen = () => {

    const navigation = useNavigation()
    const {params: {contactId}} = useRoute()
    const [contact, setContact] = useState({})
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneCountry, setPhoneCountry] = useState()
    const [phoneDigits, setPhoneDigits] = useState('')
    const [phoneId, setPhoneId] = useState('')
    const [errors, setErrors] = useState({})

    //TODO:
    // ✅️ load contact
    // ✅️ render values to form
    // ✅️ handle saving of contact
    // ✅️ Ensure we are updating a contacts existing phone number, not replacing all numbers,
    // [Contacts.Fields.PhoneNumbers]: [...] causing numbers to be wiped out
    // ✅️ Test phone number component is updating country code + digits correctly

    useEffect(() => {
        (async () => {
            if (contactId) {
                Database.findContact(contactId, async (contact) => {
                    setContact(contact)
                    //console.log('ContactEditScreen!!!')
                    //console.log({contact})

                    const fullContact = await Contacts.getContactByIdAsync(contact.raw_contact_id, [Contacts.Fields.PhoneNumbers]);
                    //console.log({fullContact})
                    if (fullContact) {
                        setFirstName(fullContact?.firstName ?? '')
                        setLastName(fullContact?.lastName ?? '')
                        // Combines to make display_name
                    }
                    if (contact) {
                        setPhoneDigits(contact?.number ?? '')
                        setPhoneCountry(contact?.country_code ?? 'GB')
                        // Combines to make full_phone_number

                        //todo: refactor...
                        // const filtered = ContactsService.getMatchingPhoneNumbersForContact(fullContact, numberToMatch)
                        // numberToMatch => full_phone_number
                        const filtered = fullContact.phoneNumbers.filter((number) => {
                            const parsedNumber = parsePhoneNumberFromString(number.number, number.countryCode || 'GB')
                            if (!parsedNumber) {
                                return false;
                            }
                            return (parsedNumber.number === contact?.full_phone_number)
                        })
                        // Saving phone number id so contact can be updated later.
                        if (filtered.length > 0) {
                            setPhoneId(filtered[0].id)
                        }
                    }
                })
            }
        })()
    }, [contactId])

    const canSave = () => {
        let validation = {}
        if (!firstName || firstName.length < 1) {
            validation.first_name = lang.t('please_enter_a_first_name')
        }
        // five digits excluding the country code
        if (!phoneDigits || phoneDigits.length < 5) {
            validation.phone = lang.t('please_enter_a_phone_number')
        }

        setErrors(validation)

        return Object.keys(validation).length === 0
    }

    const handleSave = async () => {
        // Validate
        if (false === canSave()) {
            return
        }

        // Check for permissions
        const { status } = await Contacts.requestPermissionsAsync()

        if (status === 'granted') {
            const contactData = await getContactData()

            // Save contact
            try {
                // IMPORTANT: `updateContactAsync` is only available on iOS, use `presentFormAsync` on Android
                await Contacts.updateContactAsync(contactData)
                // Get contact data and update in the database.
                const contactsData = await ContactsService.getContactsWithImages([{ id: contact.raw_contact_id }])
                if (contactsData && contactsData.length) {
                    Database.updateExistingNonMember(contact.id, contactsData[0])
                }

                // Show success message, go back to previous screen
                Alert.alert(lang.t('contact_updated'), lang.t('your_contact_has_been_updated'), [
                    { text: lang.t('ok'), onPress: () => navigation.goBack() },
                ])

            } catch(error) {
                console.log(`Failed to update contact: ${error.message}`)
                console.error(error)

                Alert.alert(lang.t('unable_to_update_contact'), lang.t('we_were_unable_to_update_this_contact'), [
                    { text: lang.t('cancel'), onPress: () => navigation.goBack(), style: 'cancel', },
                    { text: lang.t('try_again'), onPress: () => handleSave() },
                ])
            }
        } else {
            console.log('Permission to access contacts was denied.')
            // Show alert, give user options to retry or cancel
            Alert.alert(lang.t('unable_to_access_contacts'), lang.t('please_give_us_permission_to_update_contact'), [
                { text: lang.t('cancel'), onPress: () => navigation.goBack(), style: 'cancel', },
                { text: lang.t('try_again'), onPress: () => handleSave() },
            ])
        }
    }

    const getContactData = async () => {
        const fullNumber = getCountryCode(phoneCountry) + phoneDigits
        const data = await Contacts.getContactByIdAsync(contact.raw_contact_id, [Contacts.Fields.PhoneNumbers])
        const phoneNumberIndex = data.phoneNumbers.findIndex(phone => phone.id === phoneId)
        //console.log({phoneNumberIndex})
        data[Contacts.Fields.FirstName] = firstName
        data[Contacts.Fields.LastName] = lastName
        if (phoneNumberIndex !== -1) {
            //console.log('HERE: phoneNumberIndex !== -1')
            data.phoneNumbers[phoneNumberIndex] = {
                id: phoneId,
                number: fullNumber,
            }
        } else if(data.phoneNumbers.length > 0) {
            //console.log('HERE: data.phoneNumbers.length > 0')
            data.phoneNumbers.push({
                label: "mobile",
                number: fullNumber,
            })
        } else {
            //console.log('HERE: else')
            data.phoneNumbers = [{
                label: "mobile",
                number: fullNumber,
            }]
        }

        return data;
    }

    const getCountryCode = (countryCode) => {
        countryCode = countryCode.toUpperCase()
        const country = CountryCodes.find((item) => item.code === countryCode)

        return country?.dial_code
    }

    const handlePhoneNumberChange = (phoneDigits, phoneCountry) => {
        setPhoneDigits(phoneDigits)
        setPhoneCountry(phoneCountry)
    }


    const SaveContactButton = () => {
        return (
            <View>
                <TouchableOpacity onPress={handleSave}>
                    <Text style={styles.saveButtonText}>{lang.t('save')}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    return (
        <View style={styles.flex1}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <Header title={lang.t('edit_contact')} secondaryButton={<SaveContactButton />} />
            <View style={styles.flex1}>
                {/* First Name, Last Name, Phone Number(country code, digits) */}
                <View style={styles.form}>
                    <View style={styles.formGroup}>
                        <Text style={styles.formLabel}>{lang.t('first_name')}</Text>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.formControl}
                                onChangeText={setFirstName}
                                value={firstName}
                                placeholder={lang.t('first_name')}
                            />
                        </View>
                        {errors?.firstName &&
                            <Text style={styles.formValidationLabel}>{errors.firstName}</Text>
                        }
                    </View>
                    <View style={styles.formGroup}>
                        <Text style={styles.formLabel}>{lang.t('last_name')}</Text>
                        <View style={styles.inputContainer}>
                            <TextInput
                                style={styles.formControl}
                                onChangeText={setLastName}
                                value={lastName}
                                placeholder={lang.t('last_name')}
                            />
                        </View>
                        {/* Optional */}
                    </View>
                    <PhoneNumberInput
                        onPhoneNumberChange={handlePhoneNumberChange}
                        phoneDigits={phoneDigits}
                        phoneCountry={phoneCountry}
                    />
                </View>

            </View>
        </View>
    )
}

export default ContactEditScreen

const styles = StyleSheet.create({
    form: {
        paddingHorizontal: 15,
        paddingVertical: 20,
        backgroundColor: ThemeColours.white,
    },
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
    flex1: {
        flex: 1,
    },
    saveButtonText: {
        fontSize: 18,
        color: ThemeColours.sunshineGold
    }
})