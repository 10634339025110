const formatDateTime = (date) => {
    if (!date) {
        return '-';
    }
    const d = new Date(date);
    const dateStr = d.toLocaleDateString();
    const timeStr = d.toLocaleTimeString();

    return `${dateStr} ${timeStr}`;
}
export default formatDateTime