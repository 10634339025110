import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useEffect, useLayoutEffect } from 'react';
import { Image } from 'react-native';
import { useAuthContext } from '../auth';
import HomeScreen from '../Screens/HomeScreen';
import ProfileScreen from '../Screens/ProfileScreen';
import RequestsScreen from '../Screens/RequestsScreen';
import NotificationsScreen from '../Screens/NotificationsScreen';

import MerchantScreen from '../Screens/MerchantScreen';
import { ThemeColours } from '../Styles/Theme';
import { useSelector } from 'react-redux';
import { countUnreadConversations } from '../Store/requestsSlice';
import { countUnreadNotifications } from '../Store/notificationsSlice';

const Tab = createBottomTabNavigator();

const HomeTabs = ({navigation}) => {

    const { user } = useAuthContext()
    const unreadCount = useSelector(countUnreadConversations)
    const unreadNotificationsCount = useSelector(countUnreadNotifications)

    useEffect(() => {
      
      //console.log(user);

    }, [user]);

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        });
    }, [])

  return (
    <Tab.Navigator
    screenOptions={({ route }) => ({
      tabBarIcon: ({ focused, color, size }) => {
        let iconSource;
        switch(route.name) {
          case 'Home':
            iconSource = focused
            ? require('../assets/images/sourceIconSelected-2x.png')
            : require('../assets/images/sourceIconDefault-2x.png');
            size = {width: 25, height: 20};
            break;
          case 'Messages':
            iconSource = focused
            ? require('../assets/images/requestsIconSelected-2x.png')
            : require('../assets/images/requestsIconDefault-2x.png');
            size = {width: 20, height: 20};
            break;
          case 'Profile':
            iconSource = focused
            ? require('../assets/images/profileIconSelected-2x.png')
            : require('../assets/images/profileIconDefault-2x.png');
            size = {width: 18, height: 21};
            break;
          // case 'Settings':
          //   iconSource = focused
          //   ? require('../assets/images/settingsIconSelected-2x.png')
          //   : require('../assets/images/settingsIconDefault-2x.png');
          //   size = {width: 19, height: 20};
          //   break;

          case 'Notifications':
            iconSource = focused
            ? require('../assets/images/notificationsIconSelected.png')
            : require('../assets/images/notificationsIconDefault.png');
            size = {width: 19, height: 20};
            break;
          case 'Merchant':
              iconSource = focused
              ? require('../assets/images/merchantIconSelected-2x.png')
              : require('../assets/images/merchantIconDefault-2x.png');
              size = {width: 19, height: 20};
              break;
        }

        // You can return any component that you like here!
        return <Image source={iconSource} style={size} color={color} />;
      },
      tabBarActiveTintColor: ThemeColours.midnightBlue,
      tabBarInactiveTintColor: ThemeColours.steelBlue,
    })}
    >
      {user?.is_merchant ? null : <Tab.Screen name="Home" component={HomeScreen} initialParams={{ searchString: '' }} />}
      <Tab.Screen name="Messages" component={RequestsScreen} options={user?.id ? {tabBarBadge : unreadCount} : {}} />
      {
      user?.is_merchant ? 
      <Tab.Screen 
        name="Merchant" 
        component={MerchantScreen} 
        initialParams={{
          id: user?.merchant?.id,
          imgUrl: {uri: user?.merchant?.cover_photo_url},
          logoUrl: {uri: user?.merchant?.logo_url},
          name: user?.merchant?.name,
         }}
        />
      : null
      }
      <Tab.Screen name="Profile" component={ProfileScreen} />

      { user && user.id && !user.is_merchant ?
        <Tab.Screen name="Notifications" component={NotificationsScreen} options={{tabBarBadge : unreadNotificationsCount}}  />
        : null
      }
    </Tab.Navigator>
  );
}

export {
    HomeTabs
}