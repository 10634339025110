import {
    Image,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    PixelRatio,
    Alert,
    Modal,
    ActivityIndicator,
    Platform,
    FlatList
} from 'react-native'
import {useFocusEffect, useNavigation, useRoute} from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Database from '../data/ContactsDB'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import Header from '../Components/Header'
import { Theme, ThemeColours } from '../Styles/Theme'
import PhoneNumberInput from '../Components/PhoneNumberInput'
import contactsService from '../Services/ContactsService'
import {lang} from '../Providers/LocalisationProvider'
import CloseButton from '../Components/CloseButton'
import {SvgPadlock} from '../Components/PadlockSVG'
import {SvgBan} from '../Components/BanSVG'
import {SvgFlag} from '../Components/FlagSVG'
import { presentFormAsync } from 'expo-contacts'
import ImageViewer from 'react-native-image-zoom-viewer'

const ContactInfoScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const hasFocusedBefore = useRef(false)
    const {params: {contactId, contactReported}} = useRoute()
    const [contact, setContact] = useState({})

    const [media, setMedia] = useState([])
    const [mostRecentMedia, setMostRecentMedia] = useState([])
    const [lightboxImage, setLightboxImage] = useState([])
    const [showLightbox, setShowLightbox] = useState(false)
    const [showAllMedia, setShowAllMedia] = useState(false)
    const mediaToShow = 4

    const [showModal, setShowModal] = useState(false)
    const ModalStates = {
        IDLE: 'IDLE',
        LOADING: 'LOADING',
        COMPLETED_BLOCK: 'COMPLETED_BLOCK',
        COMPLETED_UNBLOCK: 'COMPLETED_UNBLOCK',
        ERROR_DURING_BLOCK: 'ERROR_DURING_BLOCK',
        ERROR_DURING_UNBLOCK: 'ERROR_DURING_UNBLOCK',
        COMPLETED_REPORT: 'COMPLETED_REPORT',
    }
    const [modalState, setModalState] = useState(ModalStates.IDLE)

    //TODO:
    // ✅ grab the contact id from route params.
    // ✅ load contact data from the local DB.
    // ✅ make a request to the api to fetch media. (Create endpoint first.)
    // ✅ handle updating of contact.
    // ✅ handle back.
    // ✅ handle blocking user.
    // ✅ handle unblocking user.
    // ✅ handle reporting user.
    // ✅ handle view all media. (Maybe add media to slice)

    useEffect(() => {
        if (contactId) {
            Database.findContact(contactId, (contact) => {
                setContact(contact)
                //console.log(contact)
            })
        }
        if (contactReported) {
            setShowModal(true)
            setModalState(ModalStates.COMPLETED_REPORT)
        }
    }, [contactId, contactReported])

    useFocusEffect(
        useCallback(() => {
            if (hasFocusedBefore.current) {
                Database.findContact(contactId, (contact) => {
                    setContact(contact)
                    //console.log(contact)
                })
            } else {
                hasFocusedBefore.current = true;
            }
        }, [])
    );

    useEffect(() => {
        loadMedia().then(r => {}).catch(e => console.error(e))
    }, [contact])

    useEffect(() => {
        const mostRecent = media.slice(0, mediaToShow);
        setMostRecentMedia(mostRecent)
    }, [media])

    const loadMedia = async () => {
        if (!contact.material_network_id) {
            setMedia([])
            return
        }
        contactsService.getSharedMedia(contact.material_network_id).then((response) => {
            setMedia(response)
        }).catch(error => {
            console.log(error)
            setMedia([])
        })
    }

    const handleBlockUser = async () => {
        Alert.alert(lang.t('are_you_sure'), lang.t('you_are_about_to_block_x', {contactName: contact.display_name}), [
            { text: lang.t('cancel'), onPress: () => null, style: 'cancel' },
            { text: lang.t('block'), onPress: () => blockUser() },
        ])
    }
    const blockUser = async () => {
        setModalState(ModalStates.LOADING)
        setShowModal(true)
        contactsService.blockUser(contact.material_network_id).then(() => {
            // Update the db, then update the state
            Database.setContactBlockedStatus(contactId, 1,() => {
                const contactCpy = contact
                contactCpy.is_blocked = 1
                setContact(contactCpy)
                // Update modal to show successfully blocked message
                setModalState(ModalStates.COMPLETED_BLOCK)
            })
        }).catch(error => {
            console.error(error)
            // Show friendly error message to user
            setModalState(ModalStates.ERROR_DURING_BLOCK)
        })
    }

    const handleUnblockUser = async () => {
        Alert.alert(lang.t('are_you_sure'), lang.t('you_are_about_to_unblock_x', {contactName: contact.display_name}), [
            { text: lang.t('cancel'), onPress: () => null, style: 'cancel' },
            { text: lang.t('unblock'), onPress: () => unblockUser() },
        ])
    }
    const unblockUser = async () => {
        setModalState(ModalStates.LOADING)
        setShowModal(true)
        contactsService.unblockUser(contact.material_network_id).then(() => {
            // Update the db, then update the state
            Database.setContactBlockedStatus(contactId, 0, () => {
                const contactCpy = contact
                contactCpy.is_blocked = 0
                setContact(contactCpy)
                // Update modal to show successfully unblocked message
                setModalState(ModalStates.COMPLETED_UNBLOCK)
            })
        }).catch(error => {
            console.error(error)
            // Show friendly error message to user
            setModalState(ModalStates.ERROR_DURING_UNBLOCK)
        })
    }

    const handleReportUser = async () => {
        navigation.navigate('ContactReportAccountScreen', {contactId: contactId})
    }

    const handleOpenAllMedia = () => {
        setShowAllMedia(true)
    }
    const handleCloseAllMedia = () => {
        setShowAllMedia(false)
    }

    const handleFormDismissed = async () => {
        // Reload contact from device contacts, as it may have been updated in the device contact form.
        try {
            const contactsData = await contactsService.getContactsWithImages([{ id: contact.raw_contact_id }])
            // Update local contact data
            if (contactsData && contactsData.length) {
                Database.updateExistingNonMember(contact.id, contactsData[0])
            }
            // Reload contact data
            Database.findContact(contactId, (contact) => {
                setContact(contact)
                //console.log(contact)
            })
        } catch (error) {
            console.error(error)
        }
    }

    const onEditContactPress = async () => {
        // Note: `updateContactAsync` is only available on iOS, so we use `presentFormAsync` on Android
        if (Platform.OS === 'ios') {
            navigation.navigate('ContactEditScreen', {contactId: contactId})
        } else if (Platform.OS === 'android') {
            try {
                await presentFormAsync(contact.raw_contact_id);
                await handleFormDismissed();
            } catch (error) {
                console.error(error);
            }
        }
    }

    const handleImageOpen = (imageData) => {
        setLightboxImage(imageData)
        setShowLightbox(true)
    }
    const renderMediaItem = ({item}) => {
        const photo = {uri: item.photo_url}
        return (
        <TouchableOpacity
            style={styles.mediaItemContainer}
            onPress={() => handleImageOpen([{url: item.photo_url}] )}>
            <Image
                source={photo}
                style={styles.mediaItemImage}
            />
        </TouchableOpacity>
        )
    }

    const MediaListEmptyComponent = () => (
        <View style={styles.noMediaSent}>
            <Text style={styles.noMediaSentText}>{lang.t('no_media_sent')}</Text>
        </View>
    )

    const keyExtractor = useCallback(item => item.message_id, [])

    const EditContactButton = () => {
        return (
            <View>
                <TouchableOpacity onPress={onEditContactPress}>
                    <Text style={styles.editButtonText}>{lang.t('edit')}</Text>
                </TouchableOpacity>
            </View>
        )
    }

    if (showAllMedia) {
        return (
            <View style={styles.flex1}>
                <Header title={lang.t('shared_media')} onGoBack={handleCloseAllMedia} />
                <FlatList
                    data={media}
                    renderItem={renderMediaItem}
                    keyExtractor={keyExtractor}
                    numColumns={2}
                    ListEmptyComponent={MediaListEmptyComponent}
                />
                <Modal visible={showLightbox}>
                    <ImageViewer
                        style={styles.imageViewer}
                        imageUrls={lightboxImage}
                        onCancel={() => {
                            setShowLightbox(false)
                        }}
                        enableSwipeDown
                    />
                </Modal>
            </View>
        )
    }

    return(
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
            <FocusAwareStatusBar barStyle="dark-content" />

            {/* Header: Contact Info, options: back, edit */}
            <Header title={lang.t('contact_info')} secondaryButton={<EditContactButton />} />

            <Modal animationType="slide" transparent={true} visible={showModal}>
                <View style={[styles.modalBg, {paddingTop: 20 + insets.top, paddingBottom: 20 + insets.bottom}]}>
                    <View style={styles.modalContent}>
                        <CloseButton onPress={() => {setShowModal(false)}} />
                        {modalState === ModalStates.LOADING ? (
                            <>
                                <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
                                <Text style={styles.loadingText}>{lang.t('loading')}</Text>
                            </>
                        ) : null}
                        {modalState === ModalStates.COMPLETED_BLOCK ? (
                            <>
                                <View style={{width: 155, height: 155, marginBottom: 25}}>
                                    <SvgBan />
                                </View>
                                <Text style={styles.modalTitle}>{lang.t('blocked')}</Text>
                                <Text style={styles.modalCopy}>{lang.t('user_blocked_successfully')}</Text>
                            </>
                        ) : null}
                        {modalState === ModalStates.ERROR_DURING_BLOCK ? (
                            <>
                                <Text style={styles.modalTitle}>{lang.t('error')}</Text>
                                <Text style={styles.modalCopy}>{lang.t('error_while_blocking_user')}</Text>
                            </>
                        ) : null}
                        {modalState === ModalStates.COMPLETED_UNBLOCK ? (
                            <>
                                <View style={{width: 132, height: 155, marginBottom: 25}}>
                                    <SvgPadlock />
                                </View>
                                <Text style={styles.modalTitle}>{lang.t('unblocked')}</Text>
                                <Text style={styles.modalCopy}>{lang.t('user_unblocked_successfully')}</Text>
                            </>
                        ) : null}
                        {modalState === ModalStates.ERROR_DURING_UNBLOCK ? (
                            <>
                                <Text style={styles.modalTitle}>{lang.t('error')}</Text>
                                <Text style={styles.modalCopy}>{lang.t('error_while_unblocking_user')}</Text>
                            </>
                        ) : null}
                        {modalState === ModalStates.COMPLETED_REPORT ? (
                            <>
                                <View style={{width: 151, height: 151, marginBottom: 25}}>
                                    <SvgFlag />
                                </View>
                                <Text style={styles.modalTitleDenial}>{lang.t('reported')}</Text>
                                <Text style={[styles.modalCopy, {marginBottom: 18}]}>{lang.t('we_are_looking_into_it')}</Text>
                                <Text style={styles.modalCopy}>{lang.t('thank_you_for_reporting_user')}</Text>
                            </>
                        ) : null}
                    </View>
                </View>
            </Modal>

            <Modal visible={showLightbox}>
                <ImageViewer
                    style={styles.imageViewer}
                    imageUrls={lightboxImage}
                    onCancel={() => {
                        setShowLightbox(false)
                    }}
                    enableSwipeDown
                />
            </Modal>

            {contact?.avatar_url ? (
                <Image source={{ uri: contact.avatar_url }} style={styles.profileImage} />
            ) : (
                <Image source={require('../assets/images/user-default-avatar.png')} style={styles.profileImage} />
            )}
            <Text style={styles.contactName}>{contact.display_name}</Text>

            {/* Phone number input */}
            <View style={{backgroundColor: ThemeColours.white, paddingHorizontal: 25, paddingVertical: 16}}>
                <PhoneNumberInput
                    fullPhoneNumber={contact.full_phone_number}
                    readonly={true}
                />
            </View>

            {/* Media section */}
            <View style={{flex: 1, width: '100%', paddingTop: 20}}>
                <View style={{flexDirection: 'row', marginBottom: 15, paddingHorizontal: 15}}>
                    <View style={{flex: 1}}>
                        <Text style={styles.headingMedia}>{lang.t('media')}</Text>
                    </View>
                    <View>
                        { media.length ?
                            <TouchableOpacity onPress={handleOpenAllMedia}>
                                <Text style={styles.headingMediaAll}>{lang.t('view_all')}</Text>
                            </TouchableOpacity>
                        : null }
                    </View>
                </View>
                <FlatList
                    data={mostRecentMedia}
                    renderItem={renderMediaItem}
                    keyExtractor={keyExtractor}
                    numColumns={2}
                    ListEmptyComponent={MediaListEmptyComponent}
                />

            </View>

            <View style={[styles.actionsSection, {paddingBottom: (20 + insets.bottom)}]}>
                {contact.material_network_id ? (
                    <View style={styles.actionRow}>
                    {contact.is_blocked ?
                        <>
                            <TouchableOpacity onPress={handleUnblockUser} style={{width: '100%', flexDirection: 'row'}}>
                                <Image source={require('../assets/images/unlock-2x.png')}
                                       style={{width: 22, height: 25, marginRight: 10}} />
                                <View style={{flex: 1}}>
                                    <Text style={styles.actionTextBlock}>{lang.t('unblock_user')}</Text>
                                </View>
                            </TouchableOpacity>
                        </>
                    :
                        <>
                            <TouchableOpacity onPress={handleBlockUser} style={{width: '100%', flexDirection: 'row'}}>
                                <Image source={require('../assets/images/ban-2x.png')}
                                       style={{width: 22, height: 22, marginRight: 10}} />
                                <View style={{flex: 1}}>
                                    <Text style={styles.actionTextBlock}>{lang.t('block_user')}</Text>
                                </View>
                            </TouchableOpacity>
                        </>
                    }
                    </View>
                ) : null}
                {contact.material_network_id ? (
                    <View style={styles.actionRow}>
                        <TouchableOpacity onPress={handleReportUser} style={{width: '100%', flexDirection: 'row'}}>
                            <Image source={require('../assets/images/report-account-2x.png')}
                                   style={{width: 22, height: 22, marginRight: 10}} />
                            <View style={{flex: 1}}>
                                <Text style={styles.actionTextReport}>{lang.t('report_account')}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                ) : null}
            </View>

        </View>
    )
}

export default ContactInfoScreen

const styles = StyleSheet.create({
    flex1: {
        flex: 1,
    },
    profileImage: {
        width: 100,
        height: 100,
        borderRadius: 6,
        marginTop: 16,
        marginBottom: 20,
    },
    contactName: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        lineHeight: 40 * PixelRatio.getFontScale(),
        marginBottom: 20,
        textAlign: 'center',
    },
    headingMedia: {
        fontSize: 20,
        color: ThemeColours.midnightBlue,
    },
    headingMediaAll: {
        fontSize: 20,
        color: ThemeColours.steelBlue,
    },
    noMediaSent: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    noMediaSentText: {
        fontSize: 18,
        color: ThemeColours.blueGrey,
    },
    actionsSection: {
        paddingHorizontal: 15,
        paddingBottom: 21,
    },
    actionRow: {
        width: '100%',
        borderTopWidth: 1,
        borderTopColor: ThemeColours.steelBlue,
        paddingHorizontal: 10,
        paddingVertical: 20
    },
    actionTextBlock: {
        fontSize: 18,
        color: ThemeColours.steelBlue,
    },
    actionTextReport: {
        fontSize: 18,
        color: ThemeColours.denialRed,
    },
    loadingIcon: {
        marginBottom: 23,
    },
    loadingText: {
        ...Theme.h1,
        color: ThemeColours.blueGrey,
    },
    modalBg: {
        flex: 1,
        backgroundColor: 'rgba(0, 35, 87, 0.3)',
        paddingHorizontal: 20,
        paddingBottom: 0,
        paddingTop: 0,
        justifyContent: 'center',
    },
    modalContent: {
        //flex: 1,
        //justifyContent: 'center',
        paddingVertical: 90,
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
    },
    modalTitle: {
        ...Theme.h1,
        marginBottom: 15,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
        textAlign: 'center',
    },
    modalTitleDenial: {
        ...Theme.h1,
        marginBottom: 15,
        color: ThemeColours.denialRed,
        fontFamily: 'ITCAvantGardeProBold',
        textAlign: 'center',
    },
    modalCopy: {
        fontSize: 18,
        color: ThemeColours.steelBlue,
        maxWidth: 265,
        textAlign: 'center',
    },
    editButtonText: {
        fontSize: 18,
        color: ThemeColours.blueGrey,
        textTransform: 'capitalize'
    },
    mediaItemContainer: {
        flex: 1,
        padding: 7.5,
        justifyContent: 'center',
        alignItems: 'center'
    },
    mediaItemImage: {
        borderRadius: 6,
        minWidth: 165,
        width: '100%',
        height: 100,
        resizeMode: 'cover'
    },
    imageViewer: {
        paddingTop: 15,
        backgroundColor: 'black'
    }
})