import { StyleSheet, View, Text, FlatList, Alert, PixelRatio, ScrollView, Dimensions, Platform, ActivityIndicator } from 'react-native'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import Purchases from 'react-native-purchases'
import PackageItem from '../Components/PackageItem'
import { ThemeColours } from '../Styles/Theme'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import RestorePurchasesButton from '../Components/RestorePurchasesButton'
import { SvgTickCircleMiniFull } from '../Components/TickSVG'
import { useNavigation } from '@react-navigation/native'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import Header from '../Components/Header'
import { PURCHASES_API_KEY_IOS, PURCHASES_API_KEY_ANDROID } from '../config';

const PaywallScreen = () => {
    // - State for all available package
    const [packages, setPackages] = useState([])

    // - State for displaying an overlay view
    const [isPurchasing, setIsPurchasing] = useState(false)

    const insets = useSafeAreaInsets()
    const navigation = useNavigation()
    const lang = useLocalisationContext()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [])

    useEffect(() => {
        // Get current available packages
        const getPackages = async () => {
            try {

                const isConfigured = await Purchases.isConfigured();
                if (!isConfigured) {
                    if (Platform.OS === 'ios') {
                        await Purchases.configure({ apiKey: PURCHASES_API_KEY_IOS, appUserID: null, observerMode: false, useAmazon: false });
                    }
                    else if (Platform.OS === 'android') {
                        await Purchases.configure({ apiKey: PURCHASES_API_KEY_ANDROID, appUserID: null, observerMode: false, useAmazon: false });
                    }
                }

                const offerings = await Purchases.getOfferings();
                if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
                    setPackages(offerings.current.availablePackages);
                } else {
                    alert(JSON.stringify(offerings));
                }
            } catch (e) {
                Alert.alert('Error getting offers', e.message);
                alert(JSON.stringify(e));
            }
        };

        getPackages();
    }, []);

    return (
        <View style={{ flex: 1 }}>
            <FocusAwareStatusBar barStyle="dark-content" />
            <Header title={lang.t('subscription')} />
            <ScrollView style={styles.page} contentContainerStyle={{}} keyboardShouldPersistTaps={'handled'}>

                <Text style={styles.title}>{lang.t('paywall_headline')}</Text>

                <View style={styles.featureBenefits}>
                    <View style={styles.featureBenefitRow}>
                        <View style={styles.featureBenefitIconContainter}>
                            <SvgTickCircleMiniFull style={styles.featureBenefitIcon} />
                        </View>
                        <Text style={styles.featureBenefitText}>{lang.t('feature_benefit_1')}</Text>
                    </View>
                    <View style={styles.featureBenefitRow}>
                        <View style={styles.featureBenefitIconContainter}>
                            <SvgTickCircleMiniFull style={styles.featureBenefitIcon} />
                        </View>
                        <Text style={styles.featureBenefitText}>{lang.t('feature_benefit_2')}</Text>
                    </View>
                    <View style={styles.featureBenefitRow}>
                        <View style={styles.featureBenefitIconContainter}>
                            <SvgTickCircleMiniFull style={styles.featureBenefitIcon} />
                        </View>
                        <Text style={styles.featureBenefitText}>{lang.t('feature_benefit_3')}</Text>
                    </View>
                    <View style={styles.featureBenefitRow}>
                        <View style={styles.featureBenefitIconContainter}>
                            <SvgTickCircleMiniFull style={styles.featureBenefitIcon} />
                        </View>
                        <Text style={styles.featureBenefitText}>{lang.t('feature_benefit_4')}</Text>
                    </View>
                </View>

                <View style={styles.badgeRow}>
                    <View style={styles.badgeFreeTrial}>
                        <Text style={styles.headingFreeTrial}>{lang.t('paywall_freetrial')}</Text>
                    </View>
                    <View style={styles.badgeSpacer}></View>
                </View>

                {/* The paywall flat list displaying each package */}
                <FlatList
                    data={packages}
                    horizontal={true}
                    renderItem={({ item }) => <PackageItem purchasePackage={item} setIsPurchasing={setIsPurchasing} />}
                    keyExtractor={(item) => item.identifier}
                    ItemSeparatorComponent={() => <View style={{ width: 20 }} />}
                    contentContainerStyle={{ paddingLeft: 15, paddingRight: 15, marginBottom: 25 }}
                />

                {isPurchasing ?
                    <View style={styles.overlay}>
                        <ActivityIndicator size={'large'} color={ThemeColours.sunshineGold} />
                    </View>
                    : null}

                <View style={[styles.footer, { paddingBottom: insets.bottom }]}>
                    <Text style={styles.legalText}>{Platform.OS == 'ios' ? lang.t('ios_paywall_legal_text') : lang.t('android_paywall_legal_text')}</Text>
                    <View style={{ flexDirection: 'row', flexWrap: 'wrap', marginBottom: 15 }}>
                        <Text style={styles.linkText} onPress={() => navigation.navigate('TermsAndConditionsScreen')}>{lang.t('terms_and_conditions')}</Text>
                        <Text style={[styles.legalText, { marginLeft: 12, marginRight: 12 }]}> | </Text>
                        <Text style={styles.linkText} onPress={() => navigation.navigate('PrivacyPolicyScreen')}>{lang.t('privacy_policy')}</Text>
                    </View>
                    <RestorePurchasesButton />
                </View>
            </ScrollView>
        </View>
    )
}

export default PaywallScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 18,
        color: ThemeColours.midnightBlue,
        lineHeight: 23,
        marginBottom: 15,
        marginHorizontal: 15,
    },
    badgeRow: {
        flexDirection: 'row',
    },
    badgeSpacer: {},
    badgeFreeTrial: {
        marginBottom: 30,
        backgroundColor: ThemeColours.white,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        paddingVertical: 9,
        flexShrink: 1,
        paddingRight: 10,
    },
    headingFreeTrial: {
        fontSize: 15,
        // fontFamily: 'ITCAvantGardeProBold',
        color: ThemeColours.midnightBlue,
        lineHeight: 23,
        marginLeft: 34,
        marginRight: 15,
    },
    featureBenefits: {
        marginBottom: 10,
        width: Dimensions.get('window').width - 30,
    },
    featureBenefitRow: {
        flexDirection: 'row',
        width: '100%',
        marginHorizontal: 15,
    },
    featureBenefitText: {
        fontSize: 14,
        color: ThemeColours.midnightBlue,
        marginBottom: 5,
        marginLeft: 4,
    },
    featureBenefitIconContainter: {
        justifyContent: 'space-around',
        width: 14 * PixelRatio.getFontScale(),
        height: 14 * PixelRatio.getFontScale(),
        marginBottom: 13 * PixelRatio.getFontScale(),
    },
    featureBenefitIcon: {

    },
    page: {
        // flex: 1,
        paddingTop: 25,
    },
    footer: {
        marginBottom: 15,
        marginHorizontal: 15,
    },
    legalText: {
        fontSize: 10,
        color: ThemeColours.steelBlue
    },
    linkText: {
        textDecorationLine: 'underline',
        fontSize: 10,
        color: ThemeColours.steelBlue,
    },
    overlay: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        opacity: 0.5,
        backgroundColor: 'black',
    },
})