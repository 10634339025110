import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgTickCircleMiniFull = (props) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path
            d="M0 7a7 7 0 0 1 7-7 7 7 0 0 1 7 7 7 7 0 0 1-7 7 7 7 0 0 1-7-7zm10.166-1.209a.767.767 0 0 0 0-1.082.767.767 0 0 0-1.082 0L6.125 7.667 4.916 6.46a.767.767 0 0 0-1.082 0 .767.767 0 0 0 0 1.082l1.75 1.75a.767.767 0 0 0 1.082 0l3.5-3.5z"
            fill="#73E213"
            />
        </Svg>
    </View>
)


export {
    SvgTickCircleMiniFull
}
