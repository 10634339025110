import React, { useState } from 'react'
import { StyleSheet, View, Text, Pressable, Alert, PixelRatio } from 'react-native'
import Purchases from 'react-native-purchases'
import { useNavigation } from '@react-navigation/native'
import { ThemeColours } from '../Styles/Theme'
import { ENTITLEMENT_ID } from '../config'
import { useLocalisationContext } from '../Providers/LocalisationProvider'


const PackageItem = ({ purchasePackage, setIsPurchasing }) => {
    
    const {
        packageType,
        product: { title, description, priceString, price },
    } = purchasePackage
    const navigation = useNavigation()
    const [termString, setTermString] = useState('')
    const [monthlyPriceString, setMonthlyPriceString] = useState('')
    const [badgeString, setBadgeString] = useState('')
    const [badgeColor, setBadgeColor] = useState()
    const lang = useLocalisationContext()


    React.useEffect(() => {
        if (packageType === "ANNUAL") {
            setMonthlyPriceString(priceString.replace(/([,.\d]+)/g, (price / 12).toFixed(2)))
            setTermString(lang.t('billing_term_year', {priceString}))
            setBadgeString(lang.t('best_value'))
            setBadgeColor(ThemeColours.sunshineGold)
        }
        if (packageType === "SIX_MONTH") {
            setMonthlyPriceString(priceString.replace(/([,.\d]+)/g, (price / 6).toFixed(2)))
            setTermString(lang.t('billing_term_six_months', {priceString}))
            setBadgeString(lang.t('saver_plus'))
            setBadgeColor(ThemeColours.midnightBlue)
        }
        if (packageType === "THREE_MONTH") {
            setMonthlyPriceString(priceString.replace(/([,.\d]+)/g, (price / 3).toFixed(2)))
            setTermString(lang.t('billing_term_three_months', {priceString}))
            setBadgeString(lang.t('saver_flex'))
            setBadgeColor(ThemeColours.midnightBlue)
        }
        if (packageType === "MONTHLY") {
            setMonthlyPriceString(priceString)
            setTermString(lang.t('billed_monthly'))
            setBadgeString(lang.t('popular'))
            setBadgeColor(ThemeColours.verifiedGreen)
        }
        //console.log(purchasePackage)
    })

    const onSelection = async () => {
        setIsPurchasing(true)

        try {
            const { customerInfo } = await Purchases.purchasePackage(purchasePackage)

            if (typeof customerInfo.entitlements.active[ENTITLEMENT_ID] !== 'undefined') {
                navigation.goBack()
                // Unlock that great "pro" content
            }
        } catch (e) {
            if (!e.userCancelled) {
                Alert.alert('Error purchasing package', e.message)
            }
            console.error(e)
        } finally {
            setIsPurchasing(false)
        }
    }

    return (
        <Pressable onPress={onSelection} style={styles.container}>
            <View style={styles.badge} backgroundColor={badgeColor}>
                <Text style={styles.badgeText}>{badgeString}</Text>
            </View>
            <View style={styles.content}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.price}>{monthlyPriceString}/ {lang.t('month_abbr_unit')}</Text>
                <Text style={styles.terms}>{termString}</Text>
            </View>
            <View style={styles.button}>
                <Text style={styles.buttonText}>{lang.t('upgrade_now')}</Text>
            </View>
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        height: (225) * PixelRatio.getFontScale(),
        minWidth: 265 * PixelRatio.getFontScale(),
        padding: 20,
        paddingTop: 60,
        backgroundColor: ThemeColours.white,
        shadowColor: 'rgba(23, 68, 162, 0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 0.5,
        borderRadius: 5,
        position: 'relative'
    },
    content: {
        flex: 1
    },
    badge: {
        position: 'absolute',
        minHeight: 25,
        minWidth: 100 * PixelRatio.getFontScale(),
        top: 20,
        backgroundColor: ThemeColours.verifiedGreen,
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        justifyContent: 'center',
    },
    badgeText: {
        fontSize: 14,
        paddingLeft: 15,
        color: ThemeColours.white
    },
    title: {
        fontSize: 20,
        color: ThemeColours.midnightBlue,
        marginBottom: 4,
    },
    price: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 32,
        color: ThemeColours.midnightBlue,
        marginBottom: 6,
    },
    terms: {
        fontSize: 14,
        color: ThemeColours.blueGrey,
        marginBottom: 20,
    },
    button: {
        minHeight: 50,
        backgroundColor: ThemeColours.sunshineGold,
        shadowColor: 'rgba(162, 113, 23, 0.3)',
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 24,
        shadowOpacity: 1,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonText: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.midnightBlue
    },
})

export default PackageItem;