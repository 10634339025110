import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import RelativeLocalDateTime from './RelativeLocalDateTime'
import { markNotificationAsRead } from '../Store/notificationsSlice'
import { useDispatch } from 'react-redux'
import { ThemeColours } from '../Styles/Theme'

const NotificationItem = ({ notification }) => {
    const { id, data, created_at, read_at } = notification;
    const { title, body, merchant_id, logo } = data;
    const navigation = useNavigation();
    const dispatch = useDispatch()

    return <TouchableOpacity style={{
        ...styles.container,
        backgroundColor: !read_at ? ThemeColours.steelBlueShadow : null
    }} activeOpacity={0.5}
        onPress={() => {
            if (!read_at) {
                dispatch(markNotificationAsRead(id))
            }
            navigation.navigate('MerchantSpecialsOfferScreen', { merchantId: merchant_id });
        }}>
        <View style={styles.content}>
            <Image source={{ uri: logo }} style={styles.avatar} />

            <View style={styles.dataContainer}>
                <Text style={styles.title}>{title}</Text>
                <Text style={styles.body}>{body}</Text>
                <RelativeLocalDateTime dateTime={created_at} color={ThemeColours.midnightBlue} />
            </View>
            {!read_at ? <View style={styles.unreadContainer}>
                <View style={styles.unreadIndicator} />
            </View> : null}
        </View>
    </TouchableOpacity>
}

export default NotificationItem

const styles = StyleSheet.create({
    avatar: {
        width: 40,
        height: 40,
        marginRight: 10,
    },
    container: {
        flex: 1,
        padding: 16,
        // marginBottom: 8,
        borderBottomWidth: 1,
        borderColor: '#ccc',
        borderStyle: 'solid',
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center'
    },
    title: {
        fontWeight: 'bold',
        fontSize: 16,
        color: ThemeColours.midnightBlue,
        marginBottom: 6,
    },
    body: {
        color: ThemeColours.midnightBlue,
        marginBottom: 4,
    },
    dataContainer: {
        flex: 6
    },
    unreadContainer: {
        alignItems: 'center',
        flex: 1,
        justifyContent: 'center'
    },
    unreadIndicator: {
        backgroundColor: 'red',
        width: 12,
        height: 12,
        borderRadius: 6
    }
});