import React, {useEffect, useLayoutEffect} from 'react'
import { useNavigation } from '@react-navigation/native'
import { View, Text, ImageBackground, StyleSheet, Alert, Platform, KeyboardAvoidingView, Keyboard, TouchableWithoutFeedback, TouchableOpacity, SafeAreaView } from 'react-native'
import {Theme} from '../Styles/Theme'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { GOOGLE_MAPS_API_KEY } from '../config'
import { lang } from '../Providers/LocalisationProvider'
import { setLocation, setAddressString } from '../Store/locationSlice'
import { useDispatch } from 'react-redux'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import dismissKeyboard from '../utils/dismissKeyboard'

const SearchScreen = () => {
    
    const navigation = useNavigation()
    const dispatch = useDispatch()

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
        if(Platform.OS === 'web'){
            navigation.navigate('HomeTabs', { screen: 'Requests' });
        }
    }, [])

    useEffect(() => {
        // (async () => {
        //     try {
        //         let { status } = await Location.requestForegroundPermissionsAsync()
        //         if (status !== 'granted') {
        //           //setErrorMsg('Permission to access location was denied');
        //           return
        //         }
        //     } catch (error) {
        //         console.error(error?.message)
        //     }
        // })()
    }, [])

  return (
    <View style={styles.container}>
        <FocusAwareStatusBar translucent={true} hidden={false} barStyle='light-content' />
        <ImageBackground 
            source={require("../assets/images/postcodeBgImage-3x.png")} 
            resizeMode="cover" 
            style={styles.image}
            >
            <KeyboardAvoidingView behavior={Platform.OS === "ios" ? "padding" : "height"}>
                <TouchableWithoutFeedback onPress={dismissKeyboard}>
                    <View style={styles.content}>
                        <Text style={[Theme.heroTitle, {fontFamily: 'ITCAvantGardeProBold', fontSize: 30, lineHeight: 38}]}>{lang.t('get_searching_for_materials_you_need')}</Text>
                        <Text style={[Theme.subheadingText, Theme.textWhite]}>{lang.t('enter_your_location_to_start')}</Text>
                        <View style={styles.spacer}></View>
                        <View style={styles.searchContainer}>
                            <GooglePlacesAutocomplete
                            placeholder={lang.t('enter_your_location')}
                            onPress={(data, details = null) => {
                                const {geometry: {location }} = details
                                const {formatted_address} = details
                                dispatch(setLocation(location))
                                dispatch(setAddressString(formatted_address))
                                navigation.replace('HomeTabs')
                            }}
                            onFail={error => console.log(error)}
                            nearbyPlacesAPI={'GoogleReverseGeocoding'}
                            currentLocation={true}
                            currentLocationLabel={lang.t('use_my_current_location')}
                            enablePoweredByContainer={false}
                            minLength={2}
                            fetchDetails={true}
                            debounce={400}
                            styles={{
                                container: {
                                    flex: 0,
                                },
                                textInput: {
                                    ...styles.input,
                                }
                            }}
                            query={{
                                key: GOOGLE_MAPS_API_KEY,
                                language: lang?.locale || 'en',
                                components: 'country:uk',
                            }}
                            />
                            {/* <TouchableOpacity 
                                style={styles.btnPrimary} 
                                onPress={() => Alert.alert('Simple Button pressed')}>
                                <Image
                                    style={styles.searchIcon}
                                    source={require('../assets/images/searchIcon.png')}
                                    fadeDuration={0}
                                />
                            </TouchableOpacity> */}
                        </View>
                        <View style={styles.spacer}></View>
                        <View style={{alignItems: 'center'}}>
                            <TouchableOpacity onPress={() => navigation.navigate('HomeTabs')} style={{width: 120}} activeOpacity={0.7}>
                                <Text style={{color: 'white', textAlign: 'center', paddingVertical: 10, fontSize: 14}}>Skip</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </TouchableWithoutFeedback>
            </KeyboardAvoidingView>
        </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    content: {
        padding: 15,
    },
    image: {
      flex: 1,
      justifyContent: "center"
    },
    searchContainer: {
        padding: 5,
        backgroundColor: '#ffffff',
        borderRadius: 3,
        shadowColor: '#99a7bc',
        shadowOffset: {width: -2, height: 4},
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    input: {
        minHeight: 40,
        marginLeft: 10,
        marginRight: 7,
        borderWidth: 1,
        padding: 10,
        flex: 1,
        fontSize: 18,
        borderColor: 'transparent',
    },
    btnPrimary: {
        backgroundColor: '#fbb03b',
        borderRadius: 3,
        width: 40,
        height: 40,
        padding: 10,
    },
    searchIcon: {
        width: 20,
        height: 20,
        backgroundColor: '#fbb03b',
    },
    spacer: {
        height: 86,
    }
  });

export default SearchScreen