import React, { useCallback } from 'react'
import {
    StyleSheet,
    TouchableOpacity,
    View,
    Text,
    Image,
    Platform,
    Linking,
    TouchableWithoutFeedback
} from 'react-native'
import { lang } from '../Providers/LocalisationProvider'
import { ThemeColours } from "../Styles/Theme";
import { useNavigation } from "@react-navigation/native";

const defaultAvatar = require('../assets/images/user-default-avatar.png')

const ContactItem = ({ item,
                       materialNetworkId,
                       fullPhoneNumber = '',
                       displayName = ''
}) => {

    const navigation = useNavigation()

    // Define the SMS invite message and link
    const inviteMessage = lang.t('invite_contact_sms_message')
    const urlEncodedMessage = encodeURIComponent(inviteMessage)

    const sendSMS = useCallback((contact) => {
        if (contact.full_phone_number) {
            const phoneNumber = contact.full_phone_number
            const url = Platform.select({
                ios: `sms:${phoneNumber}&body=${urlEncodedMessage}`,
                android: `sms:${phoneNumber}?body=${urlEncodedMessage}`
            })
            Linking.canOpenURL(url).then(() => {
                Linking.openURL(url).then(() => {}).catch((error) => console.log(error))
            }).catch((error) => console.log(error))
        }
    }, [urlEncodedMessage])

    const messageUser = useCallback((contact) => {
        navigation.navigate('ConversationScreen', {
            recipientId: contact.material_network_id,
            name: contact.display_name,
            logoUrl: contact.avatar_url || defaultAvatar,
        })
    }, [navigation])

    const showInfo = useCallback((contact) => {
        navigation.navigate('ContactInfoScreen', {
            contactId: contact.id
        })
    }, [navigation])

    return (
        <TouchableWithoutFeedback style={styles.item}>
            <View style={styles.itemRow}>
            <View>
                <TouchableOpacity onPress={() => showInfo(item)}>
                    {item?.avatar_url ? (
                        <Image source={{ uri: item.avatar_url }} style={styles.profileImage} />
                    ) : (
                        <Image source={defaultAvatar} style={styles.profileImage} />
                    )}
                </TouchableOpacity>
            </View>
            <View style={{flex: 1}}>
                <Text style={styles.displayName}>{ item.display_name }</Text>
            </View>
            {item.is_material_network_user === 1 ? (
                <TouchableOpacity onPress={() => messageUser(item)}>
                    <Text style={styles.actionText}>{lang.t('message')}</Text>
                </TouchableOpacity>
            ) : (
                <TouchableOpacity onPress={() => sendSMS(item)}>
                    <Text style={styles.actionText}>{lang.t('invite')}</Text>
                </TouchableOpacity>
            )}
            </View>
        </TouchableWithoutFeedback>
    )
}

export default React.memo(ContactItem)

const styles = StyleSheet.create({
    item: {},
    itemRow: {
        paddingHorizontal: 20,
        paddingBottom: 15,
        flexDirection: 'row',
        flex: 1,
        alignItems: 'center',
    },
    profileImage: {
        width: 50,
        height: 50,
        marginRight: 10,
        borderRadius: 3,
    },
    displayName: {
        fontSize: 18,
        color: ThemeColours.midnightBlue,
    },
    actionText: {
        fontSize: 16,
        color: ThemeColours.blueGrey,
    }
})