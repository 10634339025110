import {
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
    ActivityIndicator,
    TextInput,
    TouchableWithoutFeedback,
    Keyboard,
    ScrollView, Alert
} from 'react-native'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import Header from '../Components/Header'
import React, {useEffect, useState} from 'react'
import {useNavigation, useRoute} from '@react-navigation/native'
import {useSafeAreaInsets} from 'react-native-safe-area-context'
import {lang} from '../Providers/LocalisationProvider'
import {Theme, ThemeColours} from '../Styles/Theme'
import RadioButtonOption from '../Components/RadioButtonOption'
import contactsService from '../Services/ContactsService'
import Database from '../data/ContactsDB'

const ContactReportAccountScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const {params: {contactId}} = useRoute()
    const [contact, setContact] = useState({})
    const [isLoading, setLoading] = useState(false)
    const [reasonId, setReasonId] = useState()
    const [otherText, setOtherText] = useState('')
    const [canSubmit, setCanSubmit] = useState(false)

    const reasons = [
        {id: 1, text: lang.t('reason_sharing_pornographic_content')},
        {id: 2, text: lang.t('reason_sending_abusive_messages')},
        {id: 3, text: lang.t('reason_bullying')},
        {id: 4, text: lang.t('reason_spamming')},
        {id: 5, text: lang.t('reason_other')},
    ]

    useEffect(() => {
        if (contactId) {
            Database.findContact(contactId, (contact) => {
                setContact(contact)
            })
        }
    }, [contactId])

    const handleSave = async () => {
        setLoading(true)
        const data = {
            reason: reasonId,
            other: otherText
        }
        try {
            const response = await contactsService.reportAccount(contact.material_network_id, data)
            setLoading(false)
            // Pass parameter to previous screen to show success modal.
            navigation.navigate({
                name: 'ContactInfoScreen',
                params: {contactId: contactId, contactReported: true},
                merge: true,
            });
        } catch(e) {
            setLoading(false)
            Alert.alert(lang.t('generic_error_message'))
            console.error(e)
        }
    }

    if (isLoading) {
        return(
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <Header title={lang.t('report_account')} />
                <View style={{flex: 1}}>
                    <ActivityIndicator size="large" color="rgb(251,176,59)" style={styles.loadingIcon} />
                    <Text style={styles.loadingText}>{lang.t('loading')}</Text>
                </View>
            </View>
        )
    }

    return(
        <TouchableWithoutFeedback style={{flex: 1}} onPress={() => Keyboard.dismiss()}>
            <ScrollView style={{flex: 1}} contentContainerStyle={{flex: 1}}>
                <FocusAwareStatusBar barStyle="dark-content" />
                <Header title={lang.t('report_account')} />
                <View style={styles.contentContainer}>
                    <View style={{flex: 1}}>
                        <Text style={styles.instructionalText}>{lang.t('please_provide_reason_for_reporting_account')}</Text>
                        {reasons.map((item) => (
                            <RadioButtonOption
                                optionId={item.id}
                                optionText={item.text}
                                onPress={() => {
                                    setReasonId(item.id)
                                    setCanSubmit(true)
                                }}
                                selected={(reasonId === item.id)}
                                key={item.id}
                            />
                        ))}
                        {(reasonId === 5) ? (
                            <View style={{marginTop: 20}}>
                                <Text style={styles.formLabel}>{lang.t('please_explain')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formTextarea}
                                        value={otherText}
                                        onChangeText={(value) => setOtherText(value)}
                                        placeholder={lang.t('please_explain')}
                                        multiline={true}
                                        />
                                </View>
                            </View>
                        ) : null}
                    </View>
                    <View style={{paddingBottom: 20 + insets.bottom}}>
                        <TouchableOpacity
                            onPress={handleSave}
                            style={[styles.button, {opacity: canSubmit ? 1 : 0.3 }]}
                            disabled={!canSubmit}
                        >
                            <Text style={styles.buttonText}>{lang.t('submit_feedback')}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </ScrollView>
        </TouchableWithoutFeedback>
    )
}

export default ContactReportAccountScreen

const styles = StyleSheet.create({
    contentContainer: {
        flex: 1,
        justifyContent: 'center',
        paddingTop: 30,
        paddingHorizontal: 30,
        width: '100%',
        backgroundColor: ThemeColours.cloudWhite,
    },
    instructionalText: {
        fontSize: 16,
        color: ThemeColours.blueGrey,
        marginBottom: 40,
        maxWidth: 280,
    },
    loadingIcon: {
        marginBottom: 23,
    },
    loadingText: {
        ...Theme.h1,
        color: ThemeColours.blueGrey,
    },
    button: {
        ...Theme.button,
        shadowColor: 'transparent',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formTextarea: {
        ...Theme.formControl,
        minHeight: 135,
        height: 'auto',
    },
})