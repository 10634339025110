import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgArchive = ({color="#99A7BC", ...props}) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 20 19" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="m8.125.75 2.5 2.5h7.5C19.16 3.25 20 4.09 20 5.125v11.25c0 1.035-.84 1.875-1.875 1.875H1.875A1.875 1.875 0 0 1 0 16.375V2.625C0 1.59.84.75 1.875.75h6.25z"
                  fill={color}
                  fill-rule="nonzero"
            />
        </Svg>
    </View>
)
const SvgUnarchive = ({color="#99A7BC", ...props}) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 24 21" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path d="M21.75 3h-9l-3-3h-7.5A2.25 2.25 0 0 0 0 2.25v16.5A2.25 2.25 0 0 0 2.25 21h19.5A2.25 2.25 0 0 0 24 18.75V5.25A2.25 2.25 0 0 0 21.75 3zm-5.245 9.755c-.178.262-.464.37-.755.37-.29 0-.576-.11-.795-.33l-1.83-1.83v5.16a1.125 1.125 0 0 1-2.25 0v-5.16l-1.87 1.79a1.125 1.125 0 1 1-1.591-1.591l3.75-3.75c.44-.44 1.151-.44 1.59 0l3.75 3.75c.479.48.479 1.192 0 1.59z"
                  fill={color}
                  fill-rule="nonzero"
            />
        </Svg>
    </View>
)


export {
    SvgArchive,
    SvgUnarchive
}
