import * as Device from 'expo-device'
import { Platform } from "react-native";

const resolveDeviceName = () => {
    let deviceName = '';
    if (Platform.OS !== 'web') {
        deviceName = Device.deviceName;
        if (!deviceName) {
            deviceName = `${Platform.OS} device`;
        }
        return deviceName;
    }
    return 'web;'
}
export default resolveDeviceName;