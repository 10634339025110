import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { ThemeColours } from '../Styles/Theme'
import { lang } from '../Providers/LocalisationProvider'
import { useNavigation } from '@react-navigation/native'

const backIcon = require('../assets/images/chevronLeftIcon-2x.png')
const newMessageIcon = require('../assets/images/newMessageIcon-2x.png')

const MessagesHeader = ({
    showEdit,
    showNew,
    headerText = '',
    showCloseArchive = false,
    onCloseArchive = () => {}
}) => {

    const navigation = useNavigation()

    return (
    <View style={styles.header}>
        <View style={styles.farEdge}>
            {showCloseArchive ?
                <TouchableOpacity
                    onPress={onCloseArchive}
                    style={styles.actionButton}
                >
                    <Image source={backIcon} style={styles.icon} />
                </TouchableOpacity>
            : null}
            {showEdit ? 
            <TouchableOpacity style={styles.actionButton}>
                <Text style={styles.editText}>{lang.t('edit')}</Text>
            </TouchableOpacity>
            : null}
        </View>
        <View style={styles.flex1}>
            <Text style={styles.headerText}>{headerText ? headerText : lang.t('request_messages')}</Text>
        </View>
        <View style={styles.farEdge}>
            {showNew ? 
            <TouchableOpacity onPress={() => navigation.navigate('NewMessageScreen')}
                              style={styles.actionButton}>
                <Image source={newMessageIcon} style={styles.newMessageIcon} />
            </TouchableOpacity>
            : null}
        </View>
    </View>
    )
}

export default MessagesHeader

const styles = StyleSheet.create({
    header: {
        //height: 73,
        flexDirection: 'row',
        paddingLeft: 25,
        marginBottom: 24,
        paddingTop: 15,
    },
    headerText: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    newMessageIcon: {
        width: 20,
        height:20
    },
    editText: {
        fontSize: 20,
        color: ThemeColours.steelBlue,
        textTransform: 'capitalize',
    },
    icon: {
        width:9,
        height: 16,
    },
    flex1: {
        flex: 1,
    },
    actionButton: {
        paddingVertical: 7,
        paddingLeft: 15,
        paddingRight: 25,
    },
    farEdge: {
        minWidth: 60,
        alignItems: 'flex-end'
    }
})