import { Image, StyleSheet, TextInput, TouchableOpacity, View } from 'react-native'
import { ThemeColours } from '../Styles/Theme'
import { createRef, useState } from 'react'

const searchIcon = require('../assets/images/searchIcon-2x.png')

const SearchInput = ({
                         text = '',
                         onSearch = (value) => {},
                         placeholder = 'Search',
                         containerStyle = {}
}) => {

    const searchBox = createRef()

    return(
        <View style={{...containerStyle}}>
            <View style={styles.searchContainer}>
                <TouchableOpacity onPress={() => {searchBox.current.focus()}} style={styles.searchButton}>
                    <Image source={searchIcon} style={styles.searchIcon}/>
                </TouchableOpacity>
                <TextInput
                    style={styles.searchBox}
                    value={text}
                    ref={searchBox}
                    onChangeText={(value) => {
                        onSearch(value)
                    }}
                    placeholder={placeholder}
                    placeholderTextColor={ThemeColours.steelBlue}
                />
            </View>
        </View>
    )
}

export default SearchInput

const styles = StyleSheet.create({
    searchContainer: {
        backgroundColor: ThemeColours.white,
        minHeight: 50,
        borderRadius: 3,
        flexDirection: 'row',
        shadowColor: ThemeColours.steelBlueShadow,
        shadowOffset: {
            width: 0,
            height: 10
        },
        shadowRadius: 12,
        shadowOpacity: 1,
        elevation: 2, //Android only shadow property
        marginHorizontal: 15,
        //marginBottom: 24,
    },
    searchBox: {
        minHeight: 50,
        flex: 1,
        fontSize: 18,
    },
    searchIcon: {
        width: 20,
        height: 20,
    },
    searchButton: {
        padding: 15,
    }
})