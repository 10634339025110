import { Dimensions, StyleSheet, Text, View, ScrollView, TouchableOpacity, Alert, TextInput, Image, Switch, Modal, Pressable, ActivityIndicator, Platform } from 'react-native'
import React, { useState, useLayoutEffect, useRef, useEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { Theme, ThemeColours } from '../Styles/Theme'
import ScrollIndicator from '../Components/ScrollIndicator'
import Dropdown from '../Components/Dropdown'
import BackendService from '../Services/BackendService'
import { useAuthContext } from '../auth'
import * as ImagePicker from 'expo-image-picker'
import dismissKeyboard from "../utils/dismissKeyboard"
import formatOpeningTime from "../utils/formatOpeningTime"
//import { Camera } from 'expo-camera'
import * as MediaLibary from 'expo-media-library'
import DateTimePicker from '@react-native-community/datetimepicker'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { GOOGLE_MAPS_API_KEY } from '../config'
import { Picker } from '@react-native-picker/picker'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import PhoneNumberInput from '../Components/PhoneNumberInput'
import {lang} from '../Providers/LocalisationProvider'


const AccountSetupScreen = () => {

    const navigation = useNavigation()
    const {width} = Dimensions.get('window')
    const insets = useSafeAreaInsets()
    const slideWidth = width;
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/
    const [currentPosition, setSlidePosition] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [templates, setTemplates] = useState([1, 2, 3, 4])
    const [userType, setUserType] = useState(0)
    const scrollViewRef = useRef(null)
    const { user, setAuthenticated } = useAuthContext()
    const [tradeModalVisible, setTradeModalVisible] = useState(false)
    const [openedDatepicker, setOpenedDatepicker] = useState({
        day: null,
        index: null,
        time: null
    })

    // Tradesperson
    const [selectedTrade, setSelectedTrade] = useState(undefined)
    const [tradesData, setTradesData] = useState([])
    const [companyName, setCompanyName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [phoneCountry, setPhoneCountry] = useState('')
    const [name, setName] = useState('')
    const [avatar, setAvatar] = useState()

    // Merchant
    const defualtOpenTime = new Date('Jan 1, 2000, 09:00:00')
    const defualtCloseTime = new Date('Jan 1, 2000, 17:00:00')
    let openingTimesDefault = [
        {name: 'Monday', day_of_week: 1, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Tuesday', day_of_week: 2, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Wednesday', day_of_week: 3, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Thursday', day_of_week: 4, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Friday', day_of_week: 5, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Saturday', day_of_week: 6, is_open: false, open_at: defualtOpenTime, close_at: defualtCloseTime},
        {name: 'Sununday', day_of_week: 7, is_open: false, open_at: defualtOpenTime, close_at: defualtCloseTime},
    ]
    const [openingTimes, setOpeningTimes] = useState(openingTimesDefault)
    const [hasToilets, setHasToilets] = useState(false)
    const [hasRefreshments, setHasRefreshments] = useState(false)
    const [hasParking, setHasParking] = useState(false)
    const [categories, setCategories] = useState([])
    const [materialCategories, setMaterialCategories] = useState([])
    const [locationChosen, setLocationChosen] = useState(false)
    const [addressLine1, setAddressLine1] = useState('')
    const [addressPostcode, setAddressPostcode] = useState('')
    const [addressTownCity, setAddressTownCity] = useState('')
    const [addressCounty, setAddressCounty] = useState('')
    const [locationLat, setLocationLat] = useState(0)
    const [locationLon, setLocationLon] = useState(0)


    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [navigation])

    useEffect(() => {
        const loadTrades = async () => {
            const response = await BackendService.getTrades().catch((error) => console.error(error))
            // console.log(response.data);
            setTradesData(response.data)
        }
        loadTrades()

        //TODO: load trades via Redux
        const loadCategories = async () => {
            const response = await BackendService.getCategories().catch((error) => console.error(error))
            // console.log(response.data);
            const selectable = response.data.filter((cat) => cat.parent === null)
            setMaterialCategories(selectable)
        }
        loadCategories()
        
        if (user) {
            setEmail(user.email)
            setPhone(user.phone)
            setPhoneCountry(user?.phone_country_code ?? 'GB')
            setName(user.name)
            setCompanyName(user?.company_name ?? user?.merchant?.name)
            setSelectedTrade(user?.trade?.id)
        }
    }, [user])

    const handleEndScroll = (event) => {
        // the current offset, {x: number, y: number} 
        const position = event.nativeEvent.contentOffset
        // page index 
        const index = Math.round(position.x / slideWidth)

        if (index !== currentPosition) {
            // onPageDidChanged
            setSlidePosition(index)
        }
    }

    const choosePhotoAsync = async (setter, aspect) => {
        // Display the media libary to the user and wait for them to take a photo or to cancel
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            allowsEditing: true,
            aspect: aspect ? aspect : [16, 9],
            quality: 0.2,
        });

        //console.log(result);
      
        if (result.canceled) {
            //setter(undefined);
            return;
        }
      
        // ImagePicker saves the taken photo to disk and returns a local URI to it
        let localUri = result.assets[0].uri;
        let filename = localUri.split('/').pop();
      
        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        setter({
            uri: localUri,
            name: filename,
            type
        });
    }

    function isDatePickerOpened(day, index, time){
        if(Platform.OS === "ios"){
            return true;
        }
        return openedDatepicker && 
            openedDatepicker.time === time &&
            openedDatepicker.day === day && 
            openedDatepicker.index === index;
    }

    const doSave = async () => {
        
        let validation = {}

        // Validation for Tradesperson
        if (userType === 2) {
            if ((currentPosition + 1) === 2 && !selectedTrade) {
                validation.selectedTrade = 'Please choose your trade'
            }
            if ((currentPosition + 1) === 3) {
                if (!email || reg.test(email) === false) {
                    validation.email = 'Please enter your email'
                }
                if (!phone || phone.length < 5) {
                    validation.phone = 'Please enter your phone number'
                }
            }
            if ((currentPosition + 1) === 4 && !name) {
                validation.name = 'Please enter your name'
            }
        }
        // Validation for Merchant
        if (userType === 1) {
            if ((currentPosition + 1) === 2 && !companyName) {
                validation.companyName = 'Please enter your company name'
            }
            if ((currentPosition + 1) === 2 && (!phone || phone.length < 5)) {
                validation.phone = 'Please enter your company phone number'
            }
            if ((currentPosition + 1) === 6 && !locationChosen) {
                validation.locationChosen = 'Please enter your location'
            }
        }
        setErrors(validation)
        
        if (Object.keys(validation).length) {
            return;
        }

        // Animates to next screen
        const pos = (currentPosition + 1) * width
        scrollViewRef.current?.scrollTo({x: pos, animated: true})

        // If we're not on the last screen, return.
        if (templates.length !== currentPosition + 1) {
            setSlidePosition(currentPosition + 1);
            return;
        };
        
        const formData = new FormData()
        setLoading(true)

        // Build form data object.
        if (userType === 2) {
            formData.append('is_merchant', '0')
            if (selectedTrade) {
                formData.append('trade_id', Number(selectedTrade))
            }
            if (companyName) {
                formData.append('company_name', companyName)
            }
            if (email) {
                formData.append('email', email)
            }
            if (phone) {
                formData.append('phone', phone)
            }
            if (phoneCountry) {
                formData.append('phone_country_code', phoneCountry)
            }
            if (name) {
                formData.append('name', name)
            }
            if (avatar) {
                formData.append('avatar', avatar)
            }
        }
        if (userType === 1) {
            formData.append('is_merchant', '1')
            formData.append('name', companyName)
            if (phone) {
                formData.append('phone', phone)
            }
            if (phoneCountry) {
                formData.append('phone_country_code', phoneCountry)
            }
            if (avatar) {
                formData.append('logo', avatar)
            }
            categories.forEach((value) => {
                formData.append('categories[]', value)
            })
            formData.append('has_toilets', hasToilets ? '1' : '0')
            formData.append('has_refreshments', hasRefreshments ? '1' : '0')
            formData.append('has_parking', hasParking ? '1' : '0')
            if (addressLine1) {
                formData.append('address_line_1', addressLine1)
            }
            if (addressTownCity) {
            formData.append('address_town_city', addressTownCity)
            }
            if (addressCounty) {
            formData.append('address_county', addressCounty)
            }
            if (addressPostcode) {
                formData.append('address_postcode', addressPostcode)
            }
            if (locationLat) {
                formData.append('lat', locationLat)
            }
            if (locationLon) {
                formData.append('lng', locationLon)
            }
            openingTimes.forEach((value, index) => {
                formData.append(`opening_times[${index}][day_of_week]`, value.day_of_week)
                formData.append(`opening_times[${index}][is_open]`, value.is_open ? '1' : '0')
                formData.append(`opening_times[${index}][open_at]`, value.open_at.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) )
                formData.append(`opening_times[${index}][close_at]`, value.close_at.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }) )
            })
        }

        try {
            const response = await BackendService.accountSetup(formData)

            setAuthenticated(response.data)
            setLoading(false)
    
            //for tradesperson when final save navigate to page asking for user to subscribe.
            //for merchant go to messages/requests
            if (userType === 2) {
                navigation.replace('HomeTabs')
            }
            if (userType === 1) {
                navigation.replace('HomeTabs')
            }
        } catch(error) {
            console.error(error)
            //TODO: handle 422 errors
            if (error?.code && error.code === 422) {
                Alert.alert(error.message)
                let validation = {}
                if (error?.response?.data) {
                    Object.keys(error.response.data.errors).forEach((key) => {
                        validation[key] = error.response.data.errors[key][0]
                    })
                    setErrors(validation)
                }
            } else {
                Alert.alert('Sorry somthing went wrong, please try again later')
            }
            setLoading(false)
        }
    }

    const handlePhoneNumberChange = (phoneDigits, phoneCountry) => {
        setPhone(phoneDigits)
        setPhoneCountry(phoneCountry)
    }


    if (isLoading) {
        return (
            <View style={{flex:1, alignItems: 'center', justifyContent: 'center'}}>
                <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
            </View>
        );
    }


    return (
    <View style={[styles.container, {paddingTop: insets.top, paddingBottom: insets.bottom}]}>
        <ScrollView 
        style={styles.container}
        // scrollEnabled={false}
        horizontal={true}
        decelerationRate={'fast'}
        pagingEnabled={true}
        snapToInterval={slideWidth}
        snapToAlignment={"center"}
        ref={scrollViewRef}
        showsHorizontalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        onMomentumScrollEnd={handleEndScroll}>
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>What best describes you</Text>
                    <Text style={styles.headerText}>Let’s get your account going</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 156}}>
                    <TouchableOpacity 
                        onPress={() => {
                            setUserType(1)
                            setTemplates([1, 2, 3, 4, 5, 6])
                        }}
                        style={{flexDirection: 'row', marginBottom: 15}}>
                        <View style={styles.radioButton} borderColor={userType === 1 ? ThemeColours.midnightBlue : ThemeColours.steelBlue}>
                            {userType === 1 && 
                            <View style={styles.radioCenter}></View>
                            }
                        </View>
                        <Text style={[styles.optionLabel, {color: userType === 1 ? ThemeColours.midnightBlue : ThemeColours.steelBlue}]}>I’m a Merchant</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        setUserType(2)
                        setTemplates([1, 2, 3, 4])
                        }} 
                        style={{flexDirection: 'row'}}>
                        <View style={styles.radioButton} borderColor={userType === 2 ? ThemeColours.midnightBlue : ThemeColours.steelBlue}>
                            {userType === 2 && 
                            <View style={styles.radioCenter}></View>
                            }
                        </View>
                        <Text style={[styles.optionLabel, {color: userType === 2 ? ThemeColours.midnightBlue : ThemeColours.steelBlue}]}>I’m a Tradesperson</Text>
                    </TouchableOpacity>
                </View>
            </View>
            {userType === 2 &&
            <ScrollView style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Add your skillset</Text>
                    <Text style={styles.headerText}>Add your trade and the name of the company you work for</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 75}}>
                    <View style={styles.form}>

                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Your Trade</Text>
                            <View style={styles.inputContainer}>
                                <TouchableOpacity style={styles.formControl} onPress={() => {
                                    setTradeModalVisible(true)
                                }}>
                                    <Text style={{fontSize: 18, lineHeight: 48, color: ThemeColours.blueGrey}}>{tradesData.find((item) => item.id === selectedTrade) ? tradesData.find((item) => item.id === selectedTrade).name : 'Select Trade' }</Text>
                                </TouchableOpacity>
                                {errors.selectedTrade && <Text style={styles.formValidationLabel}>{errors.selectedTrade}</Text>}
                                <Modal
                                transparent={true}
                                visible={tradeModalVisible}
                                animation="slide"
                                onRequestClose={() => {
                                    setTradeModalVisible(!tradeModalVisible)
                                }}
                                style={{flex: 1}}
                                >
                                    <Pressable style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onPress={() => setTradeModalVisible(false)}></Pressable>
                                    <View style={{flex: 1, backgroundColor: 'white', width: '100%'}}>
                                        <TouchableOpacity
                                        onPress={() => setTradeModalVisible(false)}
                                        style={{height: 40, width: '100%', paddingHorizontal: 20, flexDirection: 'row', alignItems: 'flex-end'}}
                                        >
                                            <Text style={{color: ThemeColours.midnightBlue, lineHeight: 40, fontSize: 18, marginLeft: 'auto'}}>Done</Text>
                                        </TouchableOpacity>
                                        <Picker
                                        selectedValue={selectedTrade}
                                        // style={{position: 'absolute'}}
                                        onValueChange={(itemValue, itemIndex) => {
                                            // console.log(itemValue)
                                            setSelectedTrade(itemValue)
                                            // setTradeModalVisible(false)
                                        }}
                                        >
                                            {tradesData.map((trade) => (
                                                <Picker.Item label={trade.name} key={trade.id} value={trade.id} />
                                            ))}
                                        </Picker>
                                    </View>
                                </Modal>
                            </View>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Company Name</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setCompanyName}
                                value={companyName}
                                placeholder='Company Name'
                                onSubmitEditing={() => dismissKeyboard()}
                                />
                            </View>
                        </View>
                    </View>
                </View>
            </ScrollView>
            }
            {userType === 2 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Stay connected</Text>
                    <Text style={styles.headerText}>Confirm your contact details</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 75}}>
                    <View style={styles.form}>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Email Address</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setEmail}
                                value={email}
                                placeholder='Your email'
                                keyboardType='email-address'
                                spellCheck={false}
                                autoCapitalize='none'
                                autoCorrect={false}
                                />
                            </View>
                            {errors.email && <Text style={styles.formValidationLabel}>{errors.email}</Text>}
                        </View>

                        <View style={styles.formGroup}>
                            <PhoneNumberInput
                                onPhoneNumberChange={handlePhoneNumberChange}
                                phoneDigits={phone}
                                phoneCountry={phoneCountry}
                            />
                            {errors.phone ? <Text style={styles.formValidationLabel}>{errors.phone}</Text> : null}
                        </View>
                    </View>
                </View>
            </View>
            }
            {userType === 2 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Add some personality</Text>
                    <Text style={styles.headerText}>{'Add a photo & your name'}</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 75}}>
                    <View style={styles.form}>
                        <View style={[styles.formGroup, {flexDirection: 'row', justifyContent: 'center'}]}>
                            <TouchableOpacity onPress={() => choosePhotoAsync(setAvatar, [1,1])} style={{width: 125}}>
                                <View style={styles.avatarContainer}>
                                    {!avatar?.uri ?
                                    <Image source={require('../assets/images/profileImageIcon-2x.png')} style={{width: 33, height: 37}} />
                                    :
                                    <Image source={{uri: avatar.uri}} style={{width: 125, height: 125}} />
                                    }
                                </View>
                                <Text style={[styles.formLabel, {marginTop:4, textAlign: 'center'}]}>Add a photo</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Your Name</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setName}
                                value={name}
                                placeholder='Your Name'
                                />
                                {errors.name && <Text style={styles.formValidationLabel}>{errors.name}</Text>}
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            }
            {userType === 1 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Add your brand</Text>
                    <Text style={styles.headerText}>Add your logo & company name</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 30}}>
                    <View style={styles.form}>
                        {/* logo, company name */}
                        <View style={[styles.formGroup, {flexDirection: 'row', justifyContent: 'center'}]}>
                            <TouchableOpacity onPress={() => choosePhotoAsync(setAvatar, [1,1])} style={{width: 125}}>
                                <View style={styles.avatarContainer}>
                                    {!avatar?.uri ?
                                    <Image source={require('../assets/images/plusIcon-2x.png')} style={{width: 33, height: 37}} />
                                    :
                                    <Image source={{uri: avatar.uri}} style={{width: 125, height: 125}} />
                                    }
                                </View>
                                <Text style={[styles.formLabel, {marginTop:4, textAlign: 'center'}]}>Add your logo</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Your Company Name</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setCompanyName}
                                value={companyName}
                                placeholder='Your Company Name'
                                />
                                {errors.companyName && <Text style={styles.formValidationLabel}>{errors.companyName}</Text>}
                            </View>
                        </View>

                        <View style={styles.formGroup}>
                            <PhoneNumberInput
                                onPhoneNumberChange={handlePhoneNumberChange}
                                phoneDigits={phone}
                                phoneCountry={phoneCountry}
                                labelText={lang.t('company_phone_number')}
                            />
                            {errors.phone ? <Text style={styles.formValidationLabel}>{errors.phone}</Text> : null}
                        </View>

                    </View>
                </View>
            </View>
            }
            {userType === 1 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>When are you open</Text>
                    <Text style={styles.headerText}>Let our tradespeople know when you’re open by adding your opening times</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 30}}>
                    {/* opening times */}
                    {openingTimes.map((day, index) => (
                        <View style={[styles.row, {marginBottom: 8, minHeight: 34}]} key={index}>
                            <View style={styles.col}>
                                <Text style={styles.dayName}>{day.name}</Text>
                            </View>
                            <View style={styles.col}>
                                <View style={{flex:1, flexDirection: 'row'}}>
                                    <Switch
                                        trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                                        thumbColor={day.is_open ? ThemeColours.white : ThemeColours.white}
                                        ios_backgroundColor="#efeff0"
                                        onValueChange={(value) => {
                                            const times = [...openingTimes];
                                            times[index] = {...times[index], is_open: value};
                                            setOpeningTimes(times);
                                        }}
                                        value={day.is_open}
                                    />
                                    {/* <Text>{day.is_open ? 'Opem' : 'Closed'}</Text> */}
                                </View>
                            </View>
                            {!day.is_open && <View style={styles.col}></View>}
                            {!day.is_open && <View style={styles.col}></View>}
                            {day.is_open &&
                            <View style={[styles.col, {paddingRight: 5}]}>
                                {Platform.OS !== "ios" ? <TouchableOpacity onPress={()=>{
                                    setOpenedDatepicker({
                                        day,
                                        index,
                                        time: 'open_at'
                                    })
                                }} style={styles.datePickerButton} activeOpacity={0.5}><Text>{formatOpeningTime(day.open_at)}</Text></TouchableOpacity> : null}
                                { isDatePickerOpened(day, index, 'open_at') ? <DateTimePicker 
                                    mode="time" 
                                    value={day.open_at}
                                    onChange={(event, time) => {
                                        const times = [...openingTimes];
                                        times[index] = {...times[index], open_at: time};
                                        setOpeningTimes(times);
                                    }}/> : null
                                }
                            </View>
                            }
                            {day.is_open &&
                            <View style={styles.col}>
                                {Platform.OS !== "ios" ? <TouchableOpacity onPress={()=>{
                                    setOpenedDatepicker({
                                        day,
                                        index,
                                        time: 'close_at'
                                    })
                                }} style={styles.datePickerButton} activeOpacity={0.5}><Text>{formatOpeningTime(day.close_at)}</Text></TouchableOpacity> : null}
                                { isDatePickerOpened(day, index, 'close_at') ? <DateTimePicker 
                                    mode="time" 
                                    value={day.close_at}
                                    onChange={(event, time) => {
                                        const times = [...openingTimes];
                                        times[index] = {...times[index], close_at: time};
                                        setOpeningTimes(times);
                                    }}/> : null
                                }
                            </View>
                            }
                        </View>
                    ))
                    }
                </View>
            </View>
            }
            {userType === 1 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Product categories</Text>
                    <Text style={styles.headerText}>Let everyone know what product types your stock.</Text>
                </View>
                <View style={{paddingHorizontal: 20, flex: 1, marginTop: 30}}>
                    {/* product categories */}
                    <ScrollView style={{flex: 1}}>
                        <View style={{flex:1, width: '100%', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {materialCategories.map((cat, index) => {
                                const isSelected = categories.indexOf(cat.id) !== -1;
                                return (
                                <TouchableOpacity key={index} 
                                onPress={() => {
                                    const myCats = [...categories]
                                    const index = myCats.indexOf(cat.id);
                                    if (index !== -1) {
                                        myCats.splice(index, 1)
                                    } else {
                                        myCats.push(cat.id)
                                    }
                                    setCategories(myCats)
                                }}
                                style={[styles.category, isSelected ? styles.categorySelected : styles.categoryDefault]}
                                borderColor={isSelected ? ThemeColours.midnightBlue : '#ccd3dd'}>
                                    <Text 
                                    style={[styles.categoryText, isSelected ? styles.categoryTextSelected : styles.categoryTextDefault]} 
                                    numberOfLines={1}>{cat.name}
                                    <Text style={{width: 44, textAlign: 'center', lineHeight: 44, color: ThemeColours.midnightBlue, fontSize: 18}}>{isSelected ? '✔️' : '+' }</Text>
                                    </Text>
                                </TouchableOpacity>
                            )
                        })}
                        </View>
                    </ScrollView>
                </View>
            </View>
            }
            {userType === 1 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Facilities</Text>
                    <Text style={styles.headerText}>What facilities do you have at your location?</Text>
                </View>
                <View style={{paddingHorizontal: 20, marginTop: 60}}>
                    {/* facalities */}
                    <View style={[styles.row, {minHeight: 34, marginBottom: 30}]}>
                        <Text style={styles.facalityLabel}>Have toilets that the public can use</Text>
                        <Switch
                            trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                            thumbColor={hasToilets ? ThemeColours.white : ThemeColours.white}
                            ios_backgroundColor="#efeff0"
                            onValueChange={setHasToilets}
                            value={hasToilets}
                        />
                    </View>
                    <View style={[styles.row, {minHeight: 34, marginBottom: 30}]}>
                        <Text style={styles.facalityLabel}>Have food and refreshments on site</Text>
                        <Switch
                            trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                            thumbColor={hasRefreshments ? ThemeColours.white : ThemeColours.white}
                            ios_backgroundColor="#efeff0"
                            onValueChange={setHasRefreshments}
                            value={hasRefreshments}
                        />
                    </View>
                    <View style={[styles.row, {minHeight: 34, marginBottom: 30}]}>
                        <Text style={styles.facalityLabel}>Free parking to customers</Text>
                        <Switch
                            trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                            thumbColor={hasParking ? ThemeColours.white : ThemeColours.white}
                            ios_backgroundColor="#efeff0"
                            onValueChange={setHasParking}
                            value={hasParking}
                        />
                    </View>
                </View>
            </View>
            }
            {userType === 1 &&
            <View style={{width: width, flex: 1}}>
                <View style={styles.header}>
                    <Text style={styles.headerTitle}>Merchant Location</Text>
                    <Text style={styles.headerText}>Add your address so people know where to find you.</Text>
                </View>
                <View style={{paddingHorizontal: 20, flex:1, marginTop: 30}}>
                    {/* Enter Company Postcode, Select Your Business Address */}
                    <Text style={styles.formLabel}>Business Address</Text>
                    <GooglePlacesAutocomplete
                    placeholder='Enter your location'
                    onPress={(data, details = null) => {
                        //TODO: set bool to show address fields, set address fields, render ui
                        setLocationChosen(true)
                        const {address_components} = details;
                        // console.log({
                        //     location: details.geometry.location,
                        //     formatted_address: details.formatted_address,
                            // address_components,
                        // })
                        const _streetNumber = address_components.find(component => component.types.indexOf('street_number') !== -1)
                        const _street = address_components.find(component => component.types.indexOf('route') !== -1)
                        const _postcode = address_components.find(component => component.types.indexOf('postal_code') !== -1)
                        let _town = address_components.find(component => component.types.indexOf('locality') !== -1)
                        if (!_town) {
                            _town = address_components.find(component => component.types.indexOf('postal_town') !== -1)
                        }
                        const _region = address_components.find(component => component.types.indexOf('administrative_area_level_2') !== -1)
                        // const _country = address_components.find(component => component.types.indexOf('country') !== -1)

                        if (details?.geometry?.location) {
                            setLocationLat(details.geometry.location.lat)
                            setLocationLon(details.geometry.location.lng)
                        }
                        if (_street?.long_name) {
                            setAddressLine1(`${_streetNumber?.long_name} ${_street.long_name}`)
                        }
                        if (_postcode?.long_name) {
                            setAddressPostcode(_postcode.long_name)
                        }
                        if (_town?.long_name) {
                            setAddressTownCity(_town.long_name)
                        }
                        if (_region?.long_name) {
                            setAddressCounty(_region.long_name)
                        }

                        //do the lat lang on the server, as they might change the address.
                    }}
                    nearbyPlacesAPI={'GooglePlacesSearch'}
                    enablePoweredByContainer={false}
                    minLength={2}
                    fetchDetails={true}
                    debounce={400}
                    styles={{
                        container: {
                            flex: 0,
                        },
                        textInput: {
                            ...styles.formControl,
                        }
                    }}
                    query={{
                        key: GOOGLE_MAPS_API_KEY,
                        language: 'en',
                    }}
                    />
                    {errors.locationChosen && <Text style={styles.formValidationLabel}>{errors.locationChosen}</Text>}
                    
                    {locationChosen && 
                    <View>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Address Line 1</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setAddressLine1}
                                value={addressLine1}
                                placeholder='Address Line 1'
                                />
                            </View>
                        </View>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Town/City</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setAddressTownCity}
                                value={addressTownCity}
                                placeholder='Postcode'
                                />
                            </View>
                        </View>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Region/County</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setAddressCounty}
                                value={addressCounty}
                                placeholder='Region/County'
                                />
                            </View>
                        </View>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>Postcode</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                style={styles.formControl}
                                onChangeText={setAddressPostcode}
                                value={addressPostcode}
                                placeholder='Postcode'
                                />
                            </View>
                        </View>
                    </View>
                    }
                </View>
            </View>
            }
        </ScrollView>
        <View>
            <ScrollIndicator data={templates} currentPosition={currentPosition} />
        </View>
        <View style={styles.buttonContainer}>
            <TouchableOpacity style={styles.button} onPress={doSave}>
                <Text style={styles.buttonText}>Save details</Text>
            </TouchableOpacity>
        </View>
    </View>
    )
}

export default AccountSetupScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: ThemeColours.white,
    },
    row: {
        // flex:1,
        flexDirection: 'row',
    },
    col: {
        flex:1,
    },
    header: {
        marginTop: 35,
        paddingHorizontal: 20,
    },
    headerTitle: {
        color: ThemeColours.midnightBlue,
        fontWeight: 'bold',
        fontSize: 23,
        marginBottom: 5,
    },
    headerText: {
        color: ThemeColours.blueGrey,
        fontSize: 18,
    },
    buttonContainer: {
        padding: 15,
    },
    button: {
        ...Theme.button,
    },
    buttonText: {
        ...Theme.buttonText,
    },
    datePickerButton : {
        padding: 6,
        backgroundColor: '#ccc',
        borderRadius: 10,
        alignItems: 'center'
    },
    radioButton: {
        height: 30,
        width: 30,
        borderRadius: 15,
        paddingHorizontal: 4,
        paddingVertical: 4,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.steelBlue,
    },
    radioCenter: {
        height: 20,
        width: 20,
        borderRadius: 10,
        backgroundColor: ThemeColours.sunshineGold,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.sunshineGold,
    },
    optionLabel: {
        fontSize: 18,
        color: ThemeColours.steelBlue,
        lineHeight: 30,
        marginLeft: 10,
    },
    avatarContainer: {
        height: 125,
        width: 125,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.midnightBlue,
        borderRadius: 3,
        justifyContent: 'center', 
        alignItems: 'center'
    },
    dayName: {
        color: ThemeColours.midnightBlue,
        fontSize: 14,
    },
    facalityLabel: {
        flex: 1,
        width: '100%',
        fontSize: 16,
        color: ThemeColours.midnightBlue,
    },
    category: {
        paddingHorizontal: 14,
        marginBottom: 10,
        marginRight: 10,
        height: 44,
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 22,
    },
    categoryDefault: {
        borderColor : '#ccd3dd',
    },
    categorySelected: {
        borderColor: ThemeColours.midnightBlue,
    },
    categoryText: {
        lineHeight: 44,
        fontSize: 14,
    },
    categoryTextDefault: {
        color : '#ccd3dd',
    },
    categoryTextSelected: {
        color: ThemeColours.midnightBlue,
    },

    //Form
    form: {
        ...Theme.form,
    },
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
})