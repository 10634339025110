import axios from 'axios';
import { getToken } from '../auth';
import { BASE_URL } from '../config';
import * as Contacts from "expo-contacts";
import { parsePhoneNumberFromString } from 'libphonenumber-js';


class ContactsService {
    
    baseUrl = BASE_URL;

    constructor() {
        if (ContactsService._instance) {
            return ContactsService._instance
        }

        ContactsService._instance = this;
    }

    async getSharedMedia(contactId){
        const token = await getToken();

        try {
            const response = await axios.get(`${this.baseUrl}/contact/${contactId}/shared-media`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response.data.data);
        } catch(error) {
            if (error?.response?.status !== 404) {
                console.error(error);
            } else {
                console.log(error);
            }
            return Promise.reject(error);
        }
    }

    async syncContacts(contactsData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + '/contacts/sync', contactsData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response.data.data);
        } catch(error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async blockUser(contactId) {
        const token = await getToken();
        try {
            const response = await axios.post(`${this.baseUrl}/contact/${contactId}/block`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response.data);
        } catch(error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async unblockUser(contactId) {
        const token = await getToken();
        try {
            const response = await axios.post(`${this.baseUrl}/contact/${contactId}/unblock`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response.data);
        } catch(error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getContactsWithImages(data) {
        return (await Promise.all(
            data.map(async (contact) => {
                const fullContact = await Contacts.getContactByIdAsync(contact.id, [Contacts.Fields.Image, Contacts.Fields.PhoneNumbers]);

                // console.log({'OriginalFullContactData': fullContact});
                // console.log({'OriginalFullContactData.PhoneNumbers': fullContact.phoneNumbers});

                if (fullContact.phoneNumbers) {
                    fullContact.phoneNumbers = fullContact.phoneNumbers
                        // Exclude empty values
                        .filter(phoneNumber => phoneNumber.number)
                        // Keep only mobile numbers
                        .filter(phoneNumber => phoneNumber.label.toLowerCase().includes('mobile'))
                        // Set the fullPhoneNumber field as e164 string
                        .map(phoneNumber => {
                            try {
                                // Note: the `countryCode` property is only available on iOS
                                const countryCode = this.getCountryCode(phoneNumber.countryCode)
                                const parsedNumber = parsePhoneNumberFromString(phoneNumber.number, countryCode)

                                if (!parsedNumber) {
                                    console.warn('Invalid phone number:', phoneNumber.number)
                                    // Return original value, item will be filtered out as `fullPhoneNumber` not set
                                    return phoneNumber
                                }

                                phoneNumber.number = parsedNumber.nationalNumber
                                phoneNumber.fullPhoneNumber = parsedNumber.number

                                //console.debug({phoneNumber: phoneNumber})
                            } catch (error) {
                                console.error(error)
                            }

                            return phoneNumber;
                        })
                        // Exclude contacts without a value for `fullPhoneNumber`
                        .filter(phoneNumber => phoneNumber.fullPhoneNumber);
                }

                return fullContact;
            })

            // Filter out contacts without phone numbers
        )).filter(contact => contact.phoneNumbers && contact.phoneNumbers.length > 0)
    }

    getCountryCode(countryCode = '') {
        // Todo: set country to match users locale, in case outside the UK(GB)
        return countryCode ? countryCode.toUpperCase() : 'GB';
    }

    async reportAccount(contactId, data) {
        const token = await getToken();
        try {
            const response = await axios.post(`${this.baseUrl}/contact/${contactId}/report`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response.data);
        } catch(error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }
}

export default new ContactsService();