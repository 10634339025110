import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgPadlock = (props) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 132 151" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path
                d="M33 56.625h80.143c10.4 0 18.857 8.464 18.857 18.875v56.625c0 10.41-8.456 18.875-18.857 18.875H18.857C8.442 151 0 142.536 0 132.125V75.5c0-10.41 8.442-18.875 18.857-18.875h9.429V37.75C28.286 16.902 45.169 0 66 0c17.354 0 31.969 11.723 36.359 27.684a2.347 2.347 0 0 1-1.65 2.9c-1.238.353-2.563-.384-3.153-1.643C93.962 14.973 81.174 4.719 66 4.719c-18.238 0-33 14.787-33 33.031v18.875zm-14.143 4.719c-7.81 0-14.143 6.34-14.143 14.156v56.625c0 7.815 6.332 14.156 14.143 14.156h94.286c7.808 0 14.143-6.34 14.143-14.156V75.5c0-7.815-6.335-14.156-14.143-14.156H18.857z"
                fill="#99A7BC"
                fill-rule="nonzero"
            />
        </Svg>
    </View>
)


export {
    SvgPadlock
}
