import { Image, StyleSheet, Text, TouchableOpacity, View, PixelRatio, TouchableWithoutFeedback, FlatList } from 'react-native'
import CloseButton from '../Components/CloseButton'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import * as Contacts from 'expo-contacts'
import { ThemeColours } from '../Styles/Theme'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { SvgUserCircle } from '../Components/UserCircleSVG'
import { lang } from '../Providers/LocalisationProvider'
import Database from '../data/ContactsDB'
import ContactsService from '../Services/ContactsService'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import ContactItem from '../Components/ContactItem'
import SearchInput from '../Components/SearchInput'
import dismissKeyboard from '../utils/dismissKeyboard'


const NewMessageScreen = () => {

    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const newContactIcon = require('../assets/images/newContactIcon-2x.png')
    const hasFocusedBefore = useRef(false)
    const [text, setText] = useState('')
    const [contacts, setContacts] = useState([])
    const [filteredContacts, setFilteredContacts] = useState([])

    // Update contacts when screen is focused
    useFocusEffect(
        useCallback(() => {
            if (hasFocusedBefore.current) {
                updateContacts().then(() => {}).catch((error) => console.error(error))
            } else {
                hasFocusedBefore.current = true
            }
        }, [])
    )

    const setupContacts = async (data) => {
        // Only use reset for testing, it will drop the contacts table.
        // Database.resetDatabase()

        if (data.length > 0) {
            // Fetch the users contacts with additional data including image
            const contactsWithImages = await ContactsService.getContactsWithImages(data)

            for (let contact of contactsWithImages) {
                // Save each contact into the SQLite database
                Database.getContactByRawId(contact.id, (match) => {
                    if (!match) {
                        Database.insertContact(contact)
                    }
                })
            }

            renderContactsFromLocalDB()

            // Check if contact is material network user, update the database accordingly
            const apiRequestPhoneNumbers = contactsWithImages.map(contact => contact.phoneNumbers[0].fullPhoneNumber)
            await syncContactsWithAPI(apiRequestPhoneNumbers)
        }
    }

    const updateContacts = async (data = []) => {
        //TODO: Handle syncronising the users contact list at scheduled interval instead of every time the component renders.

        // The data parameter is optional
        if (data && data.length) {
            const apiRequestPhoneNumbers = []
            const contactsWithImages = await ContactsService.getContactsWithImages(data)

            for (let contact of contactsWithImages) {
                Database.getContactByRawId(contact.id, (match) => {
                    // Save each new contact into the SQLite database
                    if (!match) {
                        Database.insertContact(contact)
                    }
                    // Otherwise, update the contact in our SQLite database if they are NOT already a material network user
                    if (match && match.is_material_network_user == 0) {
                        Database.updateExistingNonMember(match.id, contact, () => {
                            //console.log('Contact updated!')
                        })
                    }
                })

                // Build request data
                apiRequestPhoneNumbers.push(contact.phoneNumbers[0].fullPhoneNumber)
            }

            // Call our api so we can update the data
            await syncContactsWithAPI(apiRequestPhoneNumbers)

        } else {
            // If we don't have contacts data, attempt to sync existing data with our API
            Database.getContacts((rows) => {
                const apiRequestPhoneNumbers = rows._array
                    .filter(contact => contact?.full_phone_number)
                    .map(contact => contact.full_phone_number)

                // Call our api so we can update the data.
                if (apiRequestPhoneNumbers.length > 0) {
                    syncContactsWithAPI(apiRequestPhoneNumbers)
                }
            })
        }

        // Rerender the updated contacts with data from the database
        renderContactsFromLocalDB()
    }


    const syncContactsWithAPI = async (phoneNumbers) => {
        const contactsData = {phone_numbers: phoneNumbers}
        ContactsService.syncContacts(contactsData).then(data => {
            data.forEach((contact) => {
                // Update the value in the database
                Database.updateContact(contact.full_phone_number, {
                    is_material_network_user: contact.id ? 1 : 0,
                    material_network_id: contact.id,
                    is_blocked: contact.is_blocked ? 1 : 0,
                    photo_url: contact.photo_url,
                }, () => {
                    //console.log('Contact updated!')
                })
            })
            
            renderContactsFromLocalDB()

        }).catch((error) => console.error(error))
    }

    const renderContactsFromLocalDB = () => {
        Database.getContacts((rows) => {
            if (rows.length) {
                setContacts(rows._array)
            }
        })
    }

    useEffect(() => {

        (async () => {
            const { status } = await Contacts.requestPermissionsAsync()

            // If we are given access to the users contacts then update the database
            if (status === 'granted') {
                const { data } = await Contacts.getContactsAsync({
                    fields: [Contacts.Fields.PhoneNumbers],
                })
    
                Database.getContactCount((count) => {
                    if (count === 0) {
                        setupContacts(data)
                    } else {
                        updateContacts(data)
                    }
                })
            } else {
                // Render what we have in the database.
                renderContactsFromLocalDB()

                // Update the existing contacts in the database, using data from our api.
                Database.getContactCount((count) => {
                    if (count > 0) {
                        updateContacts()
                    }
                })
            }

        })()

    }, [])

    const openNewContactScreen = () => {
        navigation.navigate('NewContactScreen')
    }

    const search = (value) => {
        setText(value)

        const query = '' + value.toLowerCase()
        let filteredData = contacts.filter(function (item) {
            return item.display_name.toLowerCase().includes(query)
        })

        if (filteredData && filteredData.length > 0) {
            setFilteredContacts(filteredData)
        } else {
            setFilteredContacts(contacts)
        }
    }

    useEffect(() => {

        // If there is no search text, set the contacts to display
        if (!text) {
            setFilteredContacts(contacts)
        }

    }, [contacts, text])

    const handleCloseButton = () => {
        navigation.goBack()
    }

    const renderContactItem = ({item}) => (
        <ContactItem
            item={item}
            materialNetworkId={item.material_network_id}
            fullPhoneNumber={item.full_phone_number}
            displayName={item.display_name}
        />
    )

    const keyExtractor = useCallback(item => item.id, [])

    return (
    <View style={styles.screen}>
        <TouchableWithoutFeedback style={styles.flex1} onPress={dismissKeyboard}>
            <View style={{flex: 1, paddingTop: insets.top}}>
                <View>
                    <View style={styles.header}>
                        <Text style={styles.headerText}>{lang.t('new_message')}</Text>
                        <CloseButton onPress={handleCloseButton} />
                    </View>
                    <SearchInput
                        text={text}
                        onSearch={search}
                        placeholder={lang.t('search')}
                        containerStyle={styles.searchInput}
                    />
                    <View style={styles.newContactContainer}>
                        <TouchableOpacity onPress={openNewContactScreen} style={styles.newContactButton}>
                            <View style={styles.newContactButtonIcon}>
                                <Image source={newContactIcon} style={styles.newContactIcon} />
                            </View>
                            <View style={styles.flex1}>
                                <Text style={styles.newContactText}>{lang.t('new_contact')}</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                <FlatList
                    data={filteredContacts}
                    renderItem={renderContactItem}
                    keyExtractor={keyExtractor}
                />
                {!contacts ?
                <View style={styles.content}>
                    <View style={styles.userCircleContainer}><SvgUserCircle /></View>
                    <Text style={styles.noContactsHeading}>{lang.t('no_contacts')}</Text>
                    <Text style={styles.noContactsText}>{lang.t('add_new_contact_instructions')}</Text>
                </View>
                : null}
            </View>
        </TouchableWithoutFeedback>
    </View>
    )
}

export default NewMessageScreen

const styles = StyleSheet.create({
    screen: {
        flex: 1,
        backgroundColor: ThemeColours.cloudWhite,
    },
    header: {
        paddingVertical: 15,
        paddingHorizontal: 20,
    },
    headerText: {
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 23,
        color: ThemeColours.midnightBlue,
        paddingTop: 5,
        lineHeight: 40 * PixelRatio.getFontScale(),
    },
    searchInput: {
        marginBottom: 20,
    },
    newContactContainer: {
        paddingHorizontal: 25,
        paddingBottom: 15,
        borderBottomWidth: 1,
        borderBottomColor: ThemeColours.steelBlue,
        marginBottom: 15,
    },
    newContactText: {
        fontSize: 18,
        color: ThemeColours.blueGrey,
        padding: 10,
    },
    newContactButton: {
        minHeight: 40,
        flexDirection: 'row',
    },
    newContactButtonIcon: {
        width: 40,
        height: 40,
        paddingTop: 10,
        paddingLeft: 8,
        backgroundColor: ThemeColours.blueGrey,
        borderRadius: 3,
        borderStyle: 'solid',
        borderColor: ThemeColours.white,
    },
    content: {
        flex: 1,
        // justifyContent: 'center',
        alignItems: 'center',
    },
    noContactsHeading: {
        marginBottom: 20,
        fontFamily: 'ITCAvantGardeProBold',
        fontSize: 25,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        lineHeight: 32,
    },
    noContactsText: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        lineHeight: 22,
        maxWidth: 280,
    },
    newContactIcon: {
        width: 22,
        height: 19,
    },
    flex1: {
        flex: 1,
    },
    userCircleContainer: {
        width: 50,
        height: 50,
        marginBottom: 5,
        marginTop: 70,
    }
})