import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgFlag = (props) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 151 151" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path
                d="M147.019 1.838A12.461 12.461 0 0 0 140.47 0c-1.957 0-3.91.43-5.704 1.248-12.315 5.622-22.422 8.24-31.792 8.24-8.541 0-15.265-2.16-22.385-4.45C72.993 2.606 64.912.091 54.62.09c-8.288 0-19.318.88-45.183 11.365l-4.718 1.91V2.36A2.36 2.36 0 1 0 0 2.36v146.282C0 149.938 1.06 151 2.36 151c1.299 0 2.359-1.06 2.359-2.36v-31.202l6.099-2.736c5.786-2.69 23.337-10.847 42.527-10.847 10.865 0 19.804 2.276 29.271 4.686 9.06 2.305 18.43 4.69 29.29 4.69 10.457 0 21.257-2.227 32.765-6.81a9.37 9.37 0 0 0 6.01-8.783L151 9.54c0-3.265-1.534-6.214-3.981-7.703zm-.797 95.81a4.688 4.688 0 0 1-3.005 4.392c-11.207 4.368-21.447 6.488-31.32 6.488-10.267 0-19.345-2.31-28.124-4.544-9.777-2.501-18.979-4.831-30.451-4.831-20.226 0-38.487 8.49-44.474 11.278l-4.13 1.846v-93.82l6.492-2.631C36.296 5.663 46.778 4.81 54.622 4.81c9.807.001 16.944 2.293 24.506 4.719 7.154 2.3 14.551 4.677 23.826 4.677 10.08 0 20.807-2.753 33.769-8.664a9.276 9.276 0 0 1 3.778-.823c1.473 0 2.81.378 4.09 1.154 1.012.627 1.69 2.066 1.69 3.665l-.059 88.11z"
                fill="#E21513"
                fill-rule="nonzero"
            />
        </Svg>
    </View>
)


export {
    SvgFlag
}
