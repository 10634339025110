import 'react-native-gesture-handler';
import * as React from 'react';
import { Platform, StatusBar } from 'react-native';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import analytics from '@react-native-firebase/analytics';
import Purchases from 'react-native-purchases';
import { PURCHASES_API_KEY_IOS, PURCHASES_API_KEY_ANDROID } from './config';
import { store } from './Store';
import { fetchRequests } from './Store/requestsSlice'
import { fetchNotifications } from './Store/notificationsSlice';
import { Provider } from 'react-redux';
import { AuthProvider } from './auth';
import SearchScreen from './Screens/SearchScreen';
import MerchantScreen from './Screens/MerchantScreen';
import MerchantEditScreen from './Screens/MerchantEditScreen';
import LoginScreen from './Screens/LoginScreen';
import RegisterScreen from './Screens/RegisterScreen';
import ForgottenPasswordScreen from './Screens/ForgottenPasswordScreen';
import ConversationScreen from './Screens/ConversationScreen';
import MaterialsSearchScreen from './Screens/MaterialsSearchScreen';
import MaterialSearchResultsScreen from './Screens/MaterialSearchResultsScreen';
import CreateRequestScreen from './Screens/CreateRequestScreen';
import ProfileEditScreen from './Screens/ProfileEditScreen';
import AccountSetupScreen from './Screens/AccountSetupScreen';
import LocationEditScreen from './Screens/LocationEditScreen';
import AccountEmailVerifyScreen from './Screens/AccountEmailVerifyScreen';
import ReferAndEarnScreen from './Screens/ReferAndEarnScreen';
import NotificationPreferencesScreen from './Screens/NotificationPreferencesScreen';
import DeleteAccountScreen from './Screens/DeleteAccountScreen';
import PaywallScreen from './Screens/PaywallScreen';
import TermsAndConditionsScreen from './Screens/Legal/TermsAndConditionsScreen';
import PrivacyPolicyScreen from './Screens/Legal/PrivacyPolicyScreen';
import AboutScreen from './Screens/AboutScreen';
import NewMessage from './Screens/NewMessageScreen';
import NewContact from './Screens/NewContactScreen';
import ContactInfo from './Screens/ContactInfoScreen';
import ContactEdit from './Screens/ContactEditScreen';
import ContactReportAccount from './Screens/ContactReportAccountScreen';
import * as Notifications from 'expo-notifications';
import * as SplashScreen from 'expo-splash-screen';
import * as Font from 'expo-font';
import { LocalisationProvider } from './Providers/LocalisationProvider';
// import { MessageProvider } from './Providers/MessageProvider';
import { MessageProvider } from './Providers/MessageProvider.web';
import { HomeTabs } from './Components/HomeTabs';
import BackendService from './Services/BackendService'

// Geolocation needs to be added at top level of app.
import * as Location from 'expo-location'
import EulaScreen from './Screens/Legal/EulaScreen';
import CookiePolicyScreen from './Screens/Legal/CookiePolicyScreen';
import ContactInfoScreen from "./Screens/ContactInfoScreen";
import MerchantSpecialsOfferScreen from './Screens/MerchantSpecialsOfferScreen';

Location.installWebGeolocationPolyfill()


const Stack = createNativeStackNavigator();


// Keep the splash screen visible while we fetch resources
SplashScreen.preventAutoHideAsync();


// Setup How notifications should be handeled in the app.
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});


export default function App() {

  const routeNameRef = React.useRef();
  const navigationRef = useNavigationContainerRef();
  const [appIsReady, setAppIsReady] = React.useState(false);
  const [pendingNotification, setPendingNotification] = React.useState(null);
  const notificationListener = React.useRef();
  const responseListener = React.useRef();

  const _handleNewMessageNotification = (merchantId, conversationId) => {
    BackendService.getMerchant(merchantId).then((merchantResponse) => {
      try {
        const imgUrl = { uri: merchantResponse.data.cover_photo_url }
        const logoUrl = { uri: merchantResponse.data.logo_url }
        navigationRef.navigate('HomeTabs', { screen: 'Requests', })
        setTimeout(() => {
          navigationRef.navigate('ConversationScreen', {
            id: conversationId,
            logoUrl,
            imgUrl,
            name: merchantResponse.data.name,
            merchantId,
            addressString: merchantResponse.data.merchant_address,
            merchantLat: merchantResponse.data.lat,
            merchantLng: merchantResponse.data.lng
          })
        }, 250)

      } catch (error) {
        console.log('navigationRef' + JSON.stringify(error))

      }
    }).catch((error) => {
      //alert(JSON.stringify(error))
      console.error(error)
    })
  }

  const _handleNotification = async (response) => {
    const notificationData = response.notification.request.content.data;
    const conversationId = notificationData?.conversation_id;
    const merchantId = notificationData?.merchant_id;
    const notificationType = notificationData?.notification_type;
    // Navigate to the message screen if it is defined
    // if (notificationType === 'new_message') {
    //   Notifications.setBadgeCountAsync(1914);
    // }
    if (!navigationRef.isReady()) {
      setPendingNotification(notificationData)
    } else {
      if (notificationType === 'new_special_offer' && merchantId) {
        navigationRef.navigate('MerchantSpecialsOfferScreen', { merchantId });
      }
      else if (notificationType === 'new_message' && conversationId && merchantId) {
        _handleNewMessageNotification(merchantId, conversationId);
      }
    }
  };

  React.useEffect(() => {
    const loadFontsAsync = async () => {
      // await Notifications.setBadgeCountAsync(Math.floor(Math.random() * 101));
      // const a = await Notifications.getBadgeCountAsync();

      try {
        await Font.loadAsync({
          'ITCAvantGardeProBold': require('./assets/fonts/ITCAvantGardePro-Bold.otf'),
          'ITCAvantGardeProMd': require('./assets/fonts/ITCAvantGardePro-Md.ttf'),
        });
      } catch (error) {
        console.warn(error);
      } finally {
        setAppIsReady(true);
        await SplashScreen.hideAsync();
      }
    }

    loadFontsAsync();

    // Reload requests & notifications in the background
    store.dispatch(fetchRequests());
    store.dispatch(fetchNotifications());

    /** Purchases */
    try {
      // TODO: Double check the logic here. !!!!
      if (Platform.OS !== 'web') {
        Purchases.setDebugLogsEnabled(true);
      }
      else if (Platform.OS === 'ios') {
        Purchases.configure({ apiKey: PURCHASES_API_KEY_IOS, appUserID: null, observerMode: false, useAmazon: false });
      }
      else if (Platform.OS === 'android') {
        Purchases.configure({ apiKey: PURCHASES_API_KEY_ANDROID, appUserID: null, observerMode: false, useAmazon: false });
      }
    } catch (error) {
      console.error(error);
    }
    const loadLastNotifications = async () => {
      const response = await Notifications.getLastNotificationResponseAsync();
      if (!!response) {
        _handleNotification(response);
      }
    }
    if (Platform.OS !== 'web') {
      loadLastNotifications();
    }
    notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
      // alert(JSON.stringify(notification));
    });

    // Handle when users tap notifications.
    responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
      _handleNotification(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  const onLayoutRootView = React.useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      await SplashScreen.hideAsync();
    }
  }, [appIsReady]);

  if (!appIsReady) {
    return null;
  }

  return (
    <LocalisationProvider onLayout={onLayoutRootView}>
      <Provider store={store}>
        <AuthProvider>
          <StatusBar hidden={false} barStyle="dark-content" />
          <MessageProvider>
            <NavigationContainer
              ref={navigationRef}
              onReady={() => {
                routeNameRef.current = navigationRef.current.getCurrentRoute().name;

                // TODO-Martin: Duplicated functionality; Refactor in future
                if (pendingNotification) {
                  const conversationId = pendingNotification.conversation_id;
                  const merchantId = pendingNotification.merchant_id;
                  const notificationType = pendingNotification.notification_type;

                  if (notificationType === 'new_special_offer' && merchantId) {
                    navigationRef.navigate('MerchantSpecialsOfferScreen', { merchantId });
                  }
                  else if (notificationType === 'new_message' && conversationId && merchantId) {
                    _handleNewMessageNotification(merchantId, conversationId);
                  }
                }
              }}
              onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.current.getCurrentRoute().name;

                if (previousRouteName !== currentRouteName && Platform.OS !== 'web') {
                  await analytics().logScreenView({
                    screen_name: currentRouteName,
                    screen_class: currentRouteName,
                  });
                }
                routeNameRef.current = currentRouteName;
              }}
            >
              <Stack.Navigator>
                {/* <Stack.Screen name="SplashScreen" component={SplashScreen} /> */}
                <Stack.Screen name="Search" component={SearchScreen} />
                <Stack.Screen name="HomeTabs" component={HomeTabs} />
                <Stack.Screen name="LocationEditScreen" component={LocationEditScreen} />
                <Stack.Screen name="MaterialsSearchScreen" component={MaterialsSearchScreen} initialParams={{ searchString: '' }} />
                <Stack.Screen name="MaterialSearchResultsScreen" component={MaterialSearchResultsScreen} initialParams={{ searchString: '' }} />
                <Stack.Screen name="CreateRequestScreen" component={CreateRequestScreen} />
                <Stack.Screen name="MerchantScreen" component={MerchantScreen} />
                <Stack.Screen name="MerchantEditScreen" component={MerchantEditScreen} />
                <Stack.Screen name="MerchantSpecialsOfferScreen" component={MerchantSpecialsOfferScreen} />
                <Stack.Screen name="LoginScreen" component={LoginScreen} />
                <Stack.Screen name="RegisterScreen" component={RegisterScreen} />
                <Stack.Screen name="AccountSetupScreen" component={AccountSetupScreen} />
                <Stack.Screen name="ForgottenPasswordScreen" component={ForgottenPasswordScreen} />
                <Stack.Screen name="ConversationScreen" component={ConversationScreen} />
                <Stack.Screen name="ProfileEditScreen" component={ProfileEditScreen} />
                <Stack.Screen name="AccountEmailVerifyScreen" component={AccountEmailVerifyScreen} />
                <Stack.Screen name="ReferAndEarnScreen" component={ReferAndEarnScreen} />
                <Stack.Screen name="NotificationPreferencesScreen" component={NotificationPreferencesScreen} />
                <Stack.Screen name="DeleteAccountScreen" component={DeleteAccountScreen} />
                <Stack.Screen name="PaywallScreen" component={PaywallScreen} options={{ title: 'Subscription' }} />
                <Stack.Screen name="TermsAndConditionsScreen" component={TermsAndConditionsScreen} options={{ title: 'Terms And Conditions' }} />
                <Stack.Screen name="PrivacyPolicyScreen" component={PrivacyPolicyScreen} options={{ title: 'Privacy Policy' }} />
                <Stack.Screen name="EulaScreen" component={EulaScreen} options={{ title: 'EULA' }} />
                <Stack.Screen name="CookiePolicyScreen" component={CookiePolicyScreen} options={{ title: 'Cookie Policy' }} />
                <Stack.Screen name="AboutScreen" component={AboutScreen} options={{ title: 'About' }} />
                <Stack.Screen name="NewMessageScreen" component={NewMessage} options={{ title: 'New Message', headerShown: false }} />
                <Stack.Screen name="NewContactScreen" component={NewContact} options={{ title: 'New Contact', headerShown: false }} />
                <Stack.Screen name="ContactInfoScreen" component={ContactInfo} options={{ title: 'Contact Info', headerShown: false }} />
                <Stack.Screen name="ContactEditScreen" component={ContactEdit} options={{ title: 'Edit Contact', headerShown: false }} />
                <Stack.Screen name="ContactReportAccountScreen" component={ContactReportAccount} options={{ title: 'Report Account', headerShown: false }} />
              </Stack.Navigator>
            </NavigationContainer>
          </MessageProvider>
        </AuthProvider>
      </Provider>
    </LocalisationProvider>
  );
}
