import { ActivityIndicator, Alert, Dimensions, Image, ImageBackground, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Switch, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useAuthContext } from '../auth'
import { Theme, ThemeColours } from '../Styles/Theme'
import { useState, useEffect, useLayoutEffect, useRef } from 'react'
import BackendService from '../Services/BackendService'
import * as ImagePicker from 'expo-image-picker'
import * as DocumentPicker from 'expo-document-picker';
import DateTimePicker from '@react-native-community/datetimepicker'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { useLocalisationContext } from '../Providers/LocalisationProvider'
import FocusAwareStatusBar from '../Components/FocusAwareStatusBar'
import CategoryTag from '../Components/CategoryTag'
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete'
import { GOOGLE_MAPS_API_KEY } from '../config'
import MapView, { Marker, PROVIDER_GOOGLE } from 'react-native-maps'
import { useIsFocused } from '@react-navigation/native'
import formatOpeningTime from "../utils/formatOpeningTime"
import PhoneNumberInput from '../Components/PhoneNumberInput'



const MerchantEditScreen = () => {
    const { params: { openAddSpecialsPhoto, openAddSpecialsPdf } } = useRoute();
    const scrollViewRef = useRef();
    const navigation = useNavigation()
    const insets = useSafeAreaInsets()
    const { user, setAuthenticated } = useAuthContext()
    const lang = useLocalisationContext()
    const [isLoading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const map = useRef()
    const isFocused = useIsFocused()
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [phoneCountry, setPhoneCountry] = useState()
    const defualtOpenTime = new Date('Jan 1, 2000, 09:00:00')
    const defualtCloseTime = new Date('Jan 1, 2000, 17:00:00')
    let openingTimesDefault = [
        { name: 'Monday', day_of_week: 1, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Tuesday', day_of_week: 2, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Wednesday', day_of_week: 3, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Thursday', day_of_week: 4, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Friday', day_of_week: 5, is_open: true, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Saturday', day_of_week: 6, is_open: false, open_at: defualtOpenTime, close_at: defualtCloseTime },
        { name: 'Sunday', day_of_week: 7, is_open: false, open_at: defualtOpenTime, close_at: defualtCloseTime },
    ]
    const [openingTimes, setOpeningTimes] = useState(openingTimesDefault)
    const [hasToilets, setHasToilets] = useState(false)
    const [hasRefreshments, setHasRefreshments] = useState(false)
    const [hasParking, setHasParking] = useState(false)
    const [categories, setCategories] = useState([])
    const [materialCategories, setMaterialCategories] = useState([])

    const [locationChosen, setLocationChosen] = useState(false)
    const [addressLine1, setAddressLine1] = useState('')
    const [addressPostcode, setAddressPostcode] = useState('')
    const [addressTownCity, setAddressTownCity] = useState('')
    const [addressCounty, setAddressCounty] = useState('')
    const [locationLat, setLocationLat] = useState(0)
    const [locationLon, setLocationLon] = useState(0)
    //
    const [logo, setLogo] = useState()
    const [coverPhoto, setCoverPhoto] = useState()
    const [specialsTitle, setSpecialsTitle] = useState('')
    const [specials, setSpecials] = useState()
    const [webSpecials, setWebSpecials] = useState()
    const [removeSpecials, setRemoveSpecials] = useState(false)
    const [openedDatepicker, setOpenedDatepicker] = useState({
        day: null,
        index: null,
        time: null
    })

    useEffect(() => {
        // Merchant 
        setName(user.merchant?.name ?? '')
        setEmail(user.merchant?.email ?? '')
        setPhone(user.merchant?.phone ?? '')
        setPhoneCountry(user.merchant?.phone_country_code ?? 'GB')
        setHasToilets(user.merchant?.has_toilets)
        setHasRefreshments(user.merchant?.has_refreshments)
        setHasParking(user.merchant?.has_parking)
        setLocationLat(user.merchant?.lat)
        setLocationLon(user.merchant?.lng)
        setLogo({ uri: user.merchant?.logo_url })
        setCoverPhoto({ uri: user.merchant?.cover_photo_url })
        setAddressCounty(user.merchant?.address_county)
        setAddressLine1(user.merchant?.address_line_1)
        // setAddressLine2(user.merchant?.address_line_2)
        setAddressPostcode(user.merchant?.address_postcode)
        setSpecialsTitle(user.merchant?.specials_photo_title)
        const formatSpecials = (specials_photo) => {
            return {
                name: specials_photo.file_name,
                type: specials_photo.mime_type,
                uri: specials_photo.original_url
            }
        }
        if (user.merchant?.specials_photo) {
            setSpecials(formatSpecials(user.merchant?.specials_photo))

            if (Platform.OS === 'web') {
                setWebSpecials(formatSpecials(user.merchant?.specials_photo))
            }
        }
        setAddressTownCity(user.merchant?.address_town_city)

        // Set cats form data.
        const chosenCats = []
        user.merchant.material_categories.map(({ id }) => {
            chosenCats.push(id)
        })
        setCategories(chosenCats)

        // Set opening times from local data.
        try {
            let openingTimes = []
            user.merchant.opening_times.map(({ close_at, day_of_week, is_open, open_at }) => {
                openingTimes.push({
                    name: getWeekDayName(day_of_week),
                    day_of_week,
                    is_open,
                    open_at: new Date(`Jan 1, 2000, ${open_at}`),
                    close_at: new Date(`Jan 1, 2000, ${close_at}`)
                })
            })
            setOpeningTimes(openingTimes)
        } catch (error) {
            console.error(error)
        }


        //TODO: load trades via Redux
        const loadCategories = async () => {
            const response = await BackendService.getCategories().catch((error) => console.error(error))
            // console.log(response.data);
            const selectable = response.data.filter((cat) => cat.parent === null)
            setMaterialCategories(selectable)
        }
        loadCategories()

    }, [user])

    useEffect(() => {
        if (isFocused && openAddSpecialsPhoto) {
            choosePhotoAsync(updateSpecials, [16, 9]);
            setTimeout(() => {
                scrollViewRef.current.scrollToEnd({ animated: true })
            }, 200)
        } else if (isFocused && openAddSpecialsPdf) {
            choosePdfAsync(updateSpecials);
            setTimeout(() => {
                scrollViewRef.current.scrollToEnd({ animated: true })
            }, 200)
        }
    }, [isFocused])

    useLayoutEffect(() => {
        navigation.setOptions({
            headerShown: false,
        })
    }, [navigation])


    const getWeekDayName = (day_of_week) => {
        const days = {
            '1': lang.t('weekday_short_1'),
            '2': lang.t('weekday_short_2'),
            '3': lang.t('weekday_short_3'),
            '4': lang.t('weekday_short_4'),
            '5': lang.t('weekday_short_5'),
            '6': lang.t('weekday_short_6'),
            '7': lang.t('weekday_short_7'),
        }

        return days[day_of_week] ? days[day_of_week] : ''
    }

    const haveFromValuesChanged = () => {
        let changed = false

        //TODO: check for category selection changes, and image changes.
        // TODO: Refactor:
        // new Map -> 'has_toilets': 'hasToilets'
        // loop and check for changes

        if (user.merchant?.name !== name) {
            changed = true
        }
        if (user.merchant?.email !== email) {
            changed = true
        }
        if (user.merchant?.phone !== phone) {
            changed = true
        }
        if (user.merchant?.phone_country_code !== phoneCountry) {
            changed = true;
        }
        if (user.merchant?.has_toilets !== hasToilets) {
            changed = true
        }
        if (user.merchant?.has_refreshments !== hasRefreshments) {
            changed = true
        }
        if (user.merchant?.has_parking !== hasParking) {
            changed = true
        }
        if (user.merchant?.lat !== locationLat) {
            changed = true
        }
        if (user.merchant?.lng !== locationLon) {
            changed = true
        }
        if (user.merchant?.address_county !== addressCounty) {
            changed = true
        }
        if (user.merchant?.address_line_1 !== addressLine1) {
            changed = true
        }
        if (user.merchant?.address_postcode !== addressPostcode) {
            changed = true
        }
        if (user.merchant?.address_town_city !== addressTownCity) {
            changed = true
        }
        if (user.merchant?.specials_photo_title !== specialsTitle) {
            changed = true
        }

        return changed
    }


    const maybeGoBack = () => {

        const changed = haveFromValuesChanged()

        if (changed) {
            Alert.alert(lang.t('save_changes'),
                lang.t('do_you_want_to_save_your_changes'),
                [
                    {
                        text: lang.t('save'),
                        onPress: () => saveChanges,
                        style: "default",
                    },
                    {
                        text: lang.t('discard'),
                        onPress: () => navigation.goBack(),
                        style: "destructive",
                    },
                ]);
            return
        }
        navigation.goBack()
    }

    const choosePhotoAsync = async (setter, aspect) => {
        // Display the media libary to the user and wait for them to take a photo or to cancel
        if (Platform.OS !== 'web') {
            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.All,
                allowsEditing: true,
                aspect: aspect ? aspect : [16, 9],
                quality: 0.2,
            })

            if (result.canceled) {
                setter(undefined)
                return
            }

            // ImagePicker saves the taken photo to disk and returns a local URI to it
            const localUri = result.assets[0].uri
            const filename = localUri.split('/').pop();

            // Infer the type of the image
            const match = /\.(\w+)$/.exec(filename);
            const type = match ? `image/${match[1]}` : `image`;

            setter({
                uri: localUri,
                name: filename,
                type
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.onchange = () => {
                const selectedFile = input.files[0];
                if (Platform.OS === 'web') {
                    setWebSpecials({
                        type: 'image/*',
                        name: selectedFile.name,
                    })
                }
                setter(selectedFile);
            };
            input.click();
        }
    }

    const choosePdfAsync = async (setter) => {
        // Display the media libary to the user and wait for them to take a photo or to cancel
        if (Platform.OS !== 'web') {
            const result = await DocumentPicker.getDocumentAsync({
                type: ['application/pdf']
            });
            if (result.type !== 'success') {
                return;
            }
            setter({
                uri: result.uri,
                name: result.name,
                type: result.mimeType
            });
        } else {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'application/pdf');

            input.onchange = () => {
                setter(input.files[0]);
            };
            input.click();
        }
    }

    const updateSpecials = (chooseFileAsyncResult) => {
        if (chooseFileAsyncResult) {
            setSpecials(chooseFileAsyncResult);
        }
    }

    const canSave = () => {
        let validation = {}

        if (!name || name.length < 2) {
            validation.name = lang.t('please_enter_a_business_name')
        }
        if (!phone || phone.length < 5) {
            validation.phone = lang.t('please_enter_your_company_phone_number')
        }

        if (specials?.name && !specialsTitle) {
            validation.specialsTitle = lang.t('please_enter_specials_photo_title')
        }

        setErrors(validation)
        if (Object.keys(validation).length) {
            return false
        }
        return true
    }

    const saveChanges = async () => {
        if (false === canSave()) {
            return
        }

        setLoading(true)

        // Build form request
        const formData = new FormData()
        formData.append('name', name)
        formData.append('email', email)
        formData.append('phone', phone)
        if (phoneCountry) {
            formData.append('phone_country_code', phoneCountry);
        }
        formData.append('specials_photo_title', specialsTitle)
        formData.append('remove_specials', removeSpecials)

        if (logo?.name) {
            formData.append('logo', logo)
        }
        if (coverPhoto?.name) {
            formData.append('cover_photo', coverPhoto)
        }
        if (specials?.name) {
            formData.append('specials_photo', specials)
        }
        categories.forEach((value) => {
            formData.append('categories[]', value)
        })
        formData.append('has_toilets', hasToilets ? '1' : '0')
        formData.append('has_refreshments', hasRefreshments ? '1' : '0')
        formData.append('has_parking', hasParking ? '1' : '0')
        if (addressLine1) {
            formData.append('address_line_1', addressLine1)
        }
        if (addressTownCity) {
            formData.append('address_town_city', addressTownCity)
        }
        if (addressCounty) {
            formData.append('address_county', addressCounty)
        }
        if (addressPostcode) {
            formData.append('address_postcode', addressPostcode)
        }
        if (locationLat) {
            formData.append('lat', locationLat)
        }
        if (locationLon) {
            formData.append('lng', locationLon)
        }
        openingTimes.forEach((value, index) => {
            formData.append(`opening_times[${index}][day_of_week]`, value.day_of_week)
            formData.append(`opening_times[${index}][is_open]`, value.is_open ? '1' : '0')
            formData.append(`opening_times[${index}][open_at]`, value.open_at.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }))
            formData.append(`opening_times[${index}][close_at]`, value.close_at.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false }))
        })

        // Post to the backend
        try {
            const merchant_id = Number(user.merchant?.id)

            const response = await BackendService.updateMerchant(merchant_id, formData)

            // // Success: update the local user data
            const userCopy = user
            userCopy.merchant = response.data
            userCopy.last_updated = new Date()
            setAuthenticated(userCopy)
            setLoading(false)
            navigation.goBack()

        } catch (error) {
            // on fail: set errors
            if (error?.code && error.code === 422) {
                Alert.alert(error.message)
                let validation = {}
                if (error?.response?.data) {
                    Object.keys(error.response.data.errors).forEach((key) => {
                        validation[key] = error.response.data.errors[key][0]
                    })
                    setErrors(validation)
                }
            } else {
                console.error(error)
                Alert.alert(lang.t('generic_error_message'))
            }
            setLoading(false)
        }
    }

    const selectCategory = (id) => {
        const myCats = [...categories]
        const index = myCats.indexOf(id);
        if (index !== -1) {
            myCats.splice(index, 1)
        } else {
            myCats.push(id)
        }
        setCategories(myCats)
    }

    function isDatePickerOpened(day, index, time) {
        if (Platform.OS === "ios") {
            return true;
        }
        return openedDatepicker &&
            openedDatepicker.time === time &&
            openedDatepicker.day === day &&
            openedDatepicker.index === index;
    }

    const handlePhoneNumberChange = (phoneDigits, phoneCountry) => {
        setPhone(phoneDigits);
        setPhoneCountry(phoneCountry);
    }

    if (isLoading) {
        return (
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <ActivityIndicator size="large" color={ThemeColours.sunshineGold} />
            </View>
        );
    }

    return (
        <KeyboardAvoidingView style={{ flex: 1 }} behavior={Platform.OS === "ios" ? "padding" : "height"}>
            <View style={[styles.container, { backgroundColor: ThemeColours.white }]}>
                <FocusAwareStatusBar hidden={true} barStyle="light-content" />
                <ScrollView
                    horizontal={false}
                    keyboardShouldPersistTaps={'handled'}
                    ref={scrollViewRef}>
                    <ImageBackground
                        source={coverPhoto}
                        style={{ width: '100%', height: (148 + insets.top), paddingTop: insets.top, resizeMode: 'cover' }}
                        resizeMode='cover'
                    >
                        <View style={styles.header}>
                            <View style={styles.headerRow}>
                                <View style={{ position: 'absolute', left: 15 }}>
                                    <TouchableOpacity onPress={maybeGoBack}>
                                        <Image source={require('../assets/images/arrowLeftWhiteIcon-2x.png')} style={{ width: 17, height: 14 }} />
                                    </TouchableOpacity>
                                </View>
                                <TouchableOpacity onPress={() => choosePhotoAsync(setCoverPhoto, [16, 9])}>
                                    <Text style={styles.headerTextLight}>{lang.t('change_background_image')}</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </ImageBackground>
                    <View style={{ backgroundColor: ThemeColours.white, paddingHorizontal: 15 }}>

                        <View style={styles.col}>
                            <Image source={logo} style={styles.logo} />
                            <TouchableOpacity onPress={() => choosePhotoAsync(setLogo, [1, 1])} style={styles.changeLogoButton}>
                                <Text style={styles.changeLogoText}>{lang.t('change_profile_image')}</Text>
                            </TouchableOpacity>
                        </View>

                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>{lang.t('merchant_name')}</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                    style={styles.formControl}
                                    onChangeText={setName}
                                    value={name}
                                    placeholder={lang.t('merchant_name')}
                                />
                                {errors.name ? <Text style={styles.formValidationLabel}>{errors.name}</Text> : null}
                            </View>
                        </View>
                        <View style={styles.formGroup}>
                            <PhoneNumberInput
                                onPhoneNumberChange={handlePhoneNumberChange}
                                phoneDigits={phone}
                                phoneCountry={phoneCountry}
                            />
                            {errors.phone ? <Text style={styles.formValidationLabel}>{errors.phone}</Text> : null}
                        </View>
                        <View style={styles.formGroup}>
                            <Text style={styles.formLabel}>{lang.t('company_email_address')}</Text>
                            <View style={styles.inputContainer}>
                                <TextInput
                                    style={styles.formControl}
                                    onChangeText={setEmail}
                                    value={email}
                                    placeholder={lang.t('email')}
                                    keyboardType='email-address'
                                    spellCheck={false}
                                    autoCapitalize='none'
                                    autoCorrect={false}
                                />
                                {errors.email ? <Text style={styles.formValidationLabel}>{errors.email}</Text> : null}
                            </View>
                        </View>
                        {/* Product categories */}
                        <View style={{ ...styles.container, ...styles.productCategoriesContainer }}>
                            <Text style={styles.subHeading}>{lang.t('product_categories')}</Text>
                            <View style={{ flex: 1, width: '100%', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {materialCategories.map((cat, index) => {
                                    const isSelected = categories.indexOf(cat.id) !== -1;
                                    return (
                                        <CategoryTag
                                            key={index}
                                            name={cat.name}
                                            id={cat.id}
                                            isSelected={isSelected}
                                            onPress={() => selectCategory(cat.id)}
                                        />
                                    )
                                })}
                            </View>
                        </View>
                        {/* When are you open */}
                        <View style={{ ...styles.container, ...styles.openingTimesContainer }}>
                            <Text style={styles.subHeading}>{lang.t('opening_times')}</Text>
                            {openingTimes.map((day, index) => (
                                <View style={[styles.row, { marginBottom: 8, minHeight: 34 }]} key={index}>
                                    <View style={styles.col}>
                                        <Text style={styles.dayName}>{day.name}</Text>
                                    </View>
                                    <View style={styles.col}>
                                        <View style={{ flex: 1, flexDirection: 'row' }}>
                                            <Switch
                                                trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                                                thumbColor={day.is_open ? ThemeColours.white : ThemeColours.white}
                                                ios_backgroundColor="#efeff0"
                                                onValueChange={(value) => {
                                                    const times = [...openingTimes];
                                                    times[index] = { ...times[index], is_open: value };
                                                    setOpeningTimes(times);
                                                }}
                                                value={day.is_open}
                                            />
                                            {/* <Text>{day.is_open ? 'Opem' : 'Closed'}</Text> */}
                                        </View>
                                    </View>
                                    {!day.is_open && <View style={styles.col}></View>}
                                    {!day.is_open && <View style={styles.col}></View>}
                                    {day.is_open &&
                                        <View style={[styles.col, { paddingRight: 5 }]}>
                                            {Platform.OS !== "ios" ? <TouchableOpacity onPress={() => {
                                                setOpenedDatepicker({
                                                    day,
                                                    index,
                                                    time: 'open_at'
                                                })
                                            }} style={styles.datePickerButton} activeOpacity={0.5}><Text>{formatOpeningTime(day.open_at)}</Text></TouchableOpacity> : null}
                                            {isDatePickerOpened(day, index, 'open_at') ? <DateTimePicker
                                                mode="time"
                                                value={day.open_at}
                                                onChange={(event, time) => {
                                                    const times = [...openingTimes];
                                                    times[index] = { ...times[index], open_at: time };
                                                    setOpeningTimes(times);
                                                }} /> : null
                                            }
                                        </View>
                                    }
                                    {day.is_open &&
                                        <View style={styles.col}>
                                            {Platform.OS !== "ios" ? <TouchableOpacity onPress={() => {
                                                setOpenedDatepicker({
                                                    day,
                                                    index,
                                                    time: 'close_at'
                                                })
                                            }} style={styles.datePickerButton} activeOpacity={0.5}><Text>{formatOpeningTime(day.close_at)}</Text></TouchableOpacity> : null}
                                            {isDatePickerOpened(day, index, 'close_at') ? <DateTimePicker
                                                mode="time"
                                                value={day.close_at}
                                                onChange={(event, time) => {
                                                    const times = [...openingTimes];
                                                    times[index] = { ...times[index], close_at: time };
                                                    setOpeningTimes(times);
                                                }} /> : null
                                            }
                                        </View>
                                    }
                                </View>
                            ))
                            }
                        </View>
                        <Text style={styles.subHeading}>{lang.t('onsite_facilities')}</Text>
                        {/* Facilities */}
                        <View style={{ marginTop: 10 }}>
                            <View style={[styles.row, { minHeight: 34, marginBottom: 30 }]}>
                                <Text style={styles.facalityLabel}>{lang.t('have_toilets_that_the_public_can_use')}</Text>
                                <Switch
                                    trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                                    thumbColor={hasToilets ? ThemeColours.white : ThemeColours.white}
                                    ios_backgroundColor="#efeff0"
                                    onValueChange={setHasToilets}
                                    value={hasToilets}
                                />
                            </View>
                            <View style={[styles.row, { minHeight: 34, marginBottom: 30 }]}>
                                <Text style={styles.facalityLabel}>{lang.t('have_food_and_refreshments_on_site')}</Text>
                                <Switch
                                    trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                                    thumbColor={hasRefreshments ? ThemeColours.white : ThemeColours.white}
                                    ios_backgroundColor="#efeff0"
                                    onValueChange={setHasRefreshments}
                                    value={hasRefreshments}
                                />
                            </View>
                            <View style={[styles.row, { minHeight: 34, marginBottom: 30 }]}>
                                <Text style={styles.facalityLabel}>{lang.t('free_parking_to_customers')}</Text>
                                <Switch
                                    trackColor={{ false: "#efeff0", true: ThemeColours.grantedGreen }}
                                    thumbColor={hasParking ? ThemeColours.white : ThemeColours.white}
                                    ios_backgroundColor="#efeff0"
                                    onValueChange={setHasParking}
                                    value={hasParking}
                                />
                            </View>
                        </View>
                        <Text style={styles.subHeading}>{lang.t('merchant_location')}</Text>
                        {/* Map */}
                        <View style={styles.mapContainer}>
                            <MapView
                                ref={map}
                                provider={PROVIDER_GOOGLE}
                                style={styles.map}
                                initialRegion={{
                                    latitude: locationLat,
                                    longitude: locationLon,
                                    latitudeDelta: 0.0922,
                                    longitudeDelta: 0.0421,
                                }}
                                region={{
                                    latitude: locationLat,
                                    longitude: locationLon,
                                    latitudeDelta: 0.0922,
                                    longitudeDelta: 0.0421,
                                }}
                            >
                                <Marker coordinate={{ latitude: locationLat, longitude: locationLon }} title={name} />
                            </MapView>
                        </View>
                        <ScrollView keyboardShouldPersistTaps={'handled'} horizontal style={{ marginBottom: 20 }}>
                            <View style={{ width: Dimensions.get('window').width - 30 }}>
                                <GooglePlacesAutocomplete
                                    placeholder={lang.t('enter_your_location')}
                                    onPress={(data, details = null) => {
                                        // Set bool to show address fields, set address fields, render ui
                                        setLocationChosen(true)
                                        const { address_components } = details;
                                        const _streetNumber = address_components.find(component => component.types.indexOf('street_number') !== -1)
                                        const _street = address_components.find(component => component.types.indexOf('route') !== -1)
                                        const _postcode = address_components.find(component => component.types.indexOf('postal_code') !== -1)
                                        let _town = address_components.find(component => component.types.indexOf('locality') !== -1)
                                        if (!_town) {
                                            _town = address_components.find(component => component.types.indexOf('postal_town') !== -1)
                                        }
                                        const _region = address_components.find(component => component.types.indexOf('administrative_area_level_2') !== -1)
                                        // const _country = address_components.find(component => component.types.indexOf('country') !== -1)

                                        if (details?.geometry?.location) {
                                            setLocationLat(details.geometry.location.lat)
                                            setLocationLon(details.geometry.location.lng)
                                        }
                                        if (_street?.long_name) {
                                            setAddressLine1(`${_streetNumber?.long_name} ${_street.long_name}`)
                                        }
                                        if (_postcode?.long_name) {
                                            setAddressPostcode(_postcode.long_name)
                                        }
                                        if (_town?.long_name) {
                                            setAddressTownCity(_town.long_name)
                                        }
                                        if (_region?.long_name) {
                                            setAddressCounty(_region.long_name)
                                        }
                                    }}
                                    nearbyPlacesAPI={'GooglePlacesSearch'}
                                    enablePoweredByContainer={false}
                                    minLength={2}
                                    fetchDetails={true}
                                    debounce={400}
                                    styles={{
                                        container: {
                                            flex: 0,
                                        },
                                        textInput: {
                                            ...styles.formControl,
                                        }
                                    }}
                                    query={{
                                        key: GOOGLE_MAPS_API_KEY,
                                        language: 'en',
                                    }}
                                />
                            </View>
                        </ScrollView>

                        <View>
                            <View style={styles.formGroup}>
                                <Text style={styles.formLabel}>{lang.t('address_line_1')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formControl}
                                        onChangeText={setAddressLine1}
                                        value={addressLine1}
                                        placeholder={lang.t('address_line_1')}
                                    />
                                    {errors.address_line_1 ? <Text style={styles.formValidationLabel}>{errors.address_line_1}</Text> : null}
                                </View>
                            </View>
                            <View style={styles.formGroup}>
                                <Text style={styles.formLabel}>{lang.t('town_city')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formControl}
                                        onChangeText={setAddressTownCity}
                                        value={addressTownCity}
                                        placeholder={lang.t('town_city')}
                                    />
                                    {errors.town_city ? <Text style={styles.formValidationLabel}>{errors.town_city}</Text> : null}
                                </View>
                            </View>
                            <View style={styles.formGroup}>
                                <Text style={styles.formLabel}>{lang.t('region_county')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formControl}
                                        onChangeText={setAddressCounty}
                                        value={addressCounty}
                                        placeholder={lang.t('region_county')}
                                    />
                                    {errors.region_county ? <Text style={styles.formValidationLabel}>{errors.region_county}</Text> : null}
                                </View>
                            </View>
                            <View style={styles.formGroup}>
                                <Text style={styles.formLabel}>{lang.t('postcode')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formControl}
                                        onChangeText={setAddressPostcode}
                                        value={addressPostcode}
                                        placeholder={lang.t('postcode')}
                                    />
                                    {errors.postcode ? <Text style={styles.formValidationLabel}>{errors.postcode}</Text> : null}
                                </View>
                            </View>
                        </View>
                        <View>
                            <Text style={styles.subHeading}>{lang.t('specials_photo_heading')}</Text>
                            <View style={styles.formGroup}>
                                <Text style={styles.formLabel}>{lang.t('specials_photo_title')}</Text>
                                <View style={styles.inputContainer}>
                                    <TextInput
                                        style={styles.formControl}
                                        onChangeText={(text) => {
                                            setSpecialsTitle(text);
                                            setRemoveSpecials(false);
                                        }}
                                        value={specialsTitle}
                                        placeholder={lang.t('specials_photo_title')}
                                    />
                                    {errors.specialsTitle ? <Text style={styles.formValidationLabel}>{errors.specialsTitle}</Text> : null}
                                </View>
                            </View>
                            <View style={styles.col}>
                                {
                                    Platform.OS !== 'web' ?
                                        ((specials?.uri && specials?.type !== 'application/pdf') ?
                                            <Image source={specials} style={styles.specials} />
                                            : null) :

                                        (
                                            (specials?.uri && specials?.type !== 'application/pdf') ?
                                                <Image source={specials} style={styles.specials} resizeMode='contain' /> : (
                                                    (webSpecials?.type !== 'application/pdf' && webSpecials) ?
                                                        <Text style={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'center'
                                                        }}>{lang.t('uploaded_file')} {webSpecials.name}</Text> : null)
                                        )


                                }
                                {
                                    specials?.type === 'application/pdf' ?
                                        <Text style={{
                                            fontWeight: 'bold',
                                            textAlign: 'center'
                                        }}>{lang.t('uploaded_file')} {specials.name}</Text>
                                        : null
                                }
                                <Text style={{ textAlign: 'center', marginVertical: 16 }}>{lang.t('upload_specials_file')}</Text>
                                <View style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'space-around',
                                }}>
                                    <TouchableOpacity onPress={() => {
                                        choosePhotoAsync(updateSpecials, [16, 9])
                                        setRemoveSpecials(false);
                                    }} style={{ ...styles.changeSpecialsButton, marginTop: 0 }}>
                                        <Text style={styles.changeSpecialsText}>{lang.t('upload_specials_image')}</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity onPress={() => {
                                        choosePdfAsync(updateSpecials)
                                        setRemoveSpecials(false);
                                    }} style={{ ...styles.changeSpecialsButton, marginTop: 0 }}>
                                        <Text style={styles.changeSpecialsText}>{lang.t('upload_specials_pdf')}</Text>
                                    </TouchableOpacity>
                                </View>
                                {
                                    Platform.OS !== 'web' ?
                                        (specials?.uri ?
                                            <TouchableOpacity onPress={() => {
                                                setSpecialsTitle('');
                                                setSpecials('');
                                                setRemoveSpecials(true);
                                            }} style={styles.removeSpecialsButton}>
                                                <Text style={styles.removeSpecialsButtonText}>{lang.t('remove_specials_file')}</Text>
                                            </TouchableOpacity> : null) :

                                        (webSpecials || specials ? <TouchableOpacity onPress={() => {
                                            setSpecialsTitle('');
                                            setSpecials('');
                                            setWebSpecials('')
                                            setRemoveSpecials(true);
                                        }} style={styles.removeSpecialsButton}>
                                            <Text style={styles.removeSpecialsButtonText}>{lang.t('remove_specials_file')}</Text>
                                        </TouchableOpacity> : null)

                                }
                            </View>
                        </View>
                        <View style={{ height: 100 }}></View>
                    </View>
                </ScrollView>
                <View style={{ padding: 15, paddingBottom: insets.bottom, flexDirection: 'row', justifyContent: 'center', backgroundColor: ThemeColours.white }}>
                    <TouchableOpacity style={styles.button} onPress={saveChanges}>
                        <Text style={styles.buttonText}>{lang.t('save')}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </KeyboardAvoidingView>
    )
}

export default MerchantEditScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    productCategoriesContainer: (Platform.OS === 'web' ? {
        minHeight: 420,
    } : {}),
    openingTimesContainer: (Platform.OS === 'web' ? {
        minHeight: 380,
    } : {}),
    row: {
        flexDirection: 'row',
    },
    col: {
        flex: 1,
    },
    header: {
        height: 73,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headerRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
    },
    headerText: {
        fontSize: 18,
        lineHeight: 32,
        textAlign: 'center',
        fontWeight: 'bold',
        color: ThemeColours.midnightBlue,
    },
    headerTextLight: {
        fontSize: 14,
        lineHeight: 32,
        textAlign: 'center',
        color: ThemeColours.white,
    },
    buttonTextEdit: {
        fontSize: 18,
        lineHeight: 20,
        color: ThemeColours.white,
    },
    content: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 20,
        paddingRight: 20,
    },
    h1: {
        ...Theme.h1,
        color: ThemeColours.midnightBlue,
        marginBottom: 25,
        fontSize: 25,
        letterSpacing: 0,
        textAlign: 'center',
        lineHeight: 32,
        maxWidth: 275,
        fontWeight: 'bold',
    },
    subHeading: {
        fontSize: 23,
        lineHeight: 32,
        marginTop: 30,
        marginBottom: 20,
        color: ThemeColours.midnightBlue,
        fontFamily: 'ITCAvantGardeProBold',
    },
    logo: {
        marginTop: -58,
        height: 125,
        width: 125,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundColor: ThemeColours.white,
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowRadius: 19,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 4,
        borderColor: ThemeColours.white,
        borderRadius: 7,
    },
    specials: {
        minHeight: 600, // TODO: in future we might need to adjust the height, depending on what images the merchents uploads
        width: '100%',
        backgroundColor: ThemeColours.white,
        shadowColor: 'rgba(153,167,188,0.3)',
        shadowRadius: 19,
        shadowOpacity: 1,
        borderStyle: 'solid',
        borderWidth: 4,
        borderColor: ThemeColours.white,
        borderRadius: 7,
    },
    changeLogoButton: {
        marginTop: 19,
        marginBottom: 31,
    },
    changeSpecialsButton: {
        marginTop: 19,
        marginBottom: 24,
        paddingVertical: 6,
        paddingHorizontal: 10,
        alignSelf: 'center',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: ThemeColours.sunshineGold,
        borderRadius: 11,
    },
    changeLogoText: {
        textAlign: 'center',
        fontSize: 14,
        color: ThemeColours.steelBlue,
    },
    changeSpecialsText: {
        textAlign: 'center',
        fontSize: 14,
    },
    removeSpecialsButton: {
        marginTop: 8,
    },
    removeSpecialsButtonText: {
        textAlign: 'center',
        fontSize: 14,
        color: ThemeColours.denialRed,
    },
    button: {
        ...Theme.button,
        maxWidth: 335,
        width: '100%',
    },
    buttonText: {
        ...Theme.buttonText,
    },
    messageIcon: {
        width: 59,
        height: 44,
        marginBottom: 25,
    },
    loadingIcon: {
        marginBottom: 25,
    },
    profileContainer: {
        flex: 1,
        flexGrow: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3
    },
    profileMeta: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        lineHeight: 20,
        marginBottom: 10,
    },
    profileTrade: {
        fontSize: 18,
        textAlign: 'center',
        color: ThemeColours.midnightBlue,
        lineHeight: 20,
        paddingVertical: 5,
        paddingHorizontal: 15,
        marginBottom: 20,

        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#ccd3dd',
        borderRadius: 16
    },
    profileImageContainer: {
        position: 'relative',
        height: 100,
        width: 100,
        marginBottom: 25,
        marginTop: -46,
        borderRadius: 3,
    },
    profileImage: {
        height: 100,
        width: 100,
        borderRadius: 6,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: ThemeColours.white,
    },
    facalityLabel: {
        flex: 1,
        width: '100%',
        fontSize: 16,
        color: ThemeColours.midnightBlue,
    },
    mapContainer: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },
    map: {
        height: 164,
        width: Dimensions.get('window').width - 40,
    },
    datePickerButton: {
        padding: 6,
        backgroundColor: '#ccc',
        borderRadius: 10,
        alignItems: 'center'
    },
    //Form
    form: {
        ...Theme.form,
    },
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
})