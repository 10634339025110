import { createContext, useState, useEffect, useContext } from 'react';
import { Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { BASE_URL, LOGOUT_URL, PROJECT_ID } from './config';
import axios from 'axios';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import Purchases from 'react-native-purchases';

const AuthContext = createContext({
    user: undefined,
    setAuthenticated: (authUser) => { },
});

async function saveToken(value) {
    try {
        let result;
        if (Platform.OS === 'web') {
            result = await AsyncStorage.setItem('auth_token', value);
        } else {
            result = await SecureStore.setItemAsync('auth_token', value);
        }

        return Promise.resolve(result);
    } catch (error) {
        console.error(error);
        return Promise.reject(error);
    }
}

async function getToken() {
    let result = null;
    try {
        if (Platform.OS === 'web') {
            result = AsyncStorage.getItem('auth_token');
        } else {
            result = await SecureStore.getItemAsync('auth_token');
        }
    } catch (e) {
        console.error(e);
    }

    return result;
}

async function registerForPushNotificationsAsync() {
    let token;
    if (Device.isDevice) {
        const { status: existingStatus } = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            console.error('Failed to get push token for push notification!');
            return;
        }
        token = (await Notifications.getExpoPushTokenAsync({ projectId: PROJECT_ID })).data;
    } else {
        console.warn('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}

async function getUser() {
    const token = await getToken();

    if (!token) {
        return Promise.reject('No token');
    }

    const options = { headers: { Authorization: `Bearer ${token}` } };

    try {
        // Calls that don't depend on each other can be run in parallel
        const requests = [
            // Get the user data from our API
            axios.get(`${BASE_URL}/user`, options),
        ];
        if(Platform.OS !== 'web'){
            requests.push(
                // Get the users push notification token, then save to our API
                registerForPushNotificationsAsync().then((expoPushToken) => {
                    axios.post(`${BASE_URL}/push-notifications/register`, { 'token': expoPushToken }, options).catch((error) => {
                        console.error(error);

                        return error;
                    });
                }).catch((error) => {
                    console.error('registerForPushNotificationsAsync error', error);

                    return error;
                }))
        }
        const [userResponse, pushNotificationResponse] = await Promise.all(requests);

        return Promise.resolve(userResponse.data);

    } catch (error) {
        console.error(error);

        return Promise.reject(error);
    }
}

async function signOut() {
    const token = await getToken();

    if (!token) {
        return Promise.reject('No token');
    }

    try {
        const response = await axios.post(LOGOUT_URL, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        // Reset token
        saveToken('');

        return Promise.resolve(response);

    } catch (error) {
        console.error(error);
        // Should we reset the key on error?
        saveToken('');
        return Promise.reject(error);
    }
}

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState();

    const setAuthenticated = async (authUser) => {
        // Create a new user object instead of modifying the existing one
        const updatedUser = { ...authUser };
        setUser(updatedUser);

        // Handle Auth of Payment Processor
        // if (authUser?.subscription_account_id) {
        //     try {
        //         await Purchases.logIn(authUser.subscription_account_id);
        //     } catch (error) {
        //         console.error('Error when loging the user into purchases: /n' + error.message);
        //     }
        // }
    }


    useEffect(() => {
        const loadUser = async () => {
            const currentUser = await getUser();
            setAuthenticated(currentUser);
        }
        loadUser().catch(error => console.error(error));

    }, []);

    return (
        <AuthContext.Provider value={{ user, setAuthenticated }}>{children}</AuthContext.Provider>
    );
};

const useAuthContext = () => {
    const user = useContext(AuthContext);
    if (user === undefined) {
        throw new Error("useAuthContext can only be used inside AuthProvider");
    }
    return user;
};


export {
    AuthContext,
    AuthProvider,
    useAuthContext,
    saveToken,
    getToken,
    signOut,
    getUser,
};