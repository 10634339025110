import {Text, TouchableOpacity, View, StyleSheet} from 'react-native'
import {SvgArchive} from './SVG/ArchiveUnarchiveSVG'
import {ThemeColours} from '../Styles/Theme'
import { lang } from '../Providers/LocalisationProvider'

const ArchivedMessagesButton = ({onPress, count = 0}) => {
    return (
        <TouchableOpacity
            activeOpacity={0.6}
            onPress={onPress}
            style={styles.button}
        >
            <View style={styles.iconContainer}>
                <SvgArchive width={20} height={19} />
            </View>
            <View style={{flex: 1}}>
                <Text style={styles.text}>{lang.t('archived_messages')}</Text>
            </View>
            <Text style={styles.text}>{`(${count})`}</Text>
        </TouchableOpacity>
    )
}

export default ArchivedMessagesButton

const styles = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 25,
        marginBottom: 20,
    },
    text: {
        fontSize: 20,
        color: ThemeColours.steelBlue,
    },
    iconContainer: {
        marginRight: 12,
        paddingTop: 2,
    }
})