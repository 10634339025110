import axios from 'axios';
import { Platform } from 'expo-modules-core';
import { getToken } from '../auth';
import { BASE_URL, BROADCASTING_AUTH_URL } from '../config';


class BackendService {

    baseUrl = BASE_URL;
    broadCastingAuthUrl = BROADCASTING_AUTH_URL;
    cdnUrl = '';

    constructor() {
        if (BackendService._instance) {
            return BackendService._instance
        }

        console.log('>>>BASE_URL', BASE_URL, this.baseUrl)
        BackendService._instance = this;
    }

    getMerchants() {
        let data = null;
        try {
            axios.get(this.baseUrl + '/merchant').then(data => {
                data = response.data;
            });
        } catch (error) {
            throw error;
        }
        return data;
    }

    fixImageUrl(url) {
        return this.cdnUrl + url;
    }

    async getRequests() {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + '/conversation', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getResults(queryParams) {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + '/search', {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                params: queryParams
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    async getUser() {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + '/user', {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            return Promise.reject(error);
        }
    }

    async createRequest(formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + '/request', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'multipart/form-data',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async saveProfile(formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + '/user', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'multipart/form-data',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getTrades() {
        try {
            const response = await axios.get(this.baseUrl + '/trade');
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getCategories() {
        try {
            const response = await axios.get(this.baseUrl + '/material-category');
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getMerchants(params = {}) {
        try {
            const response = axios.get(this.baseUrl + '/merchant', { params });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async accountSetup(formData) {
        const token = await getToken();

        try {
            // This is suposed to be a patch request, but we use HTTP Post and pass `_method: PATCH` as a field in form data
            formData.append('_method', 'PATCH')
            const response = await axios.post(this.baseUrl + '/account-setup', formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getMessages(id) {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + `/conversation/${id}/message`, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async markMessagesAsRead(id) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation/${id}/mark-as-read`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async saveMessage(conversation_id, formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation/${conversation_id}/message`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getMerchant(merchant_id) {
        try {
            const response = await axios.get(this.baseUrl + `/merchant/${merchant_id}`, {
                headers: {
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async getReviews(merchant_id) {
        try {
            const response = await axios.get(this.baseUrl + `/merchant/${merchant_id}/reviews`, {
                headers: {
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async createReview(merchant_id, formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/merchant/${merchant_id}/review`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async sendPasswordReminder(formData) {
        try {
            const response = await axios.post(this.baseUrl + `/forgotten-password`, formData, {
                headers: {
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async recoverAccount(data) {
        try {
            const response = await axios.post(this.baseUrl + `/account-recover`, data, {
                headers: {
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async resetPassword(formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/password-reset`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async requestEmailVerificationCode(user_id) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/account/${user_id}/email-verification-code`, {}, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async verifyEmail(user_id, data) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/account/${user_id}/verify-email`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async sendReferral(data) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/referral`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async updateMerchant(merchant_id, formData) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/merchant/${merchant_id}`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async updateNotificationPreferences(user_id, data) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/account/${user_id}/notification-preferences`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            console.error(error);
            if (error?.response?.data) {
                console.error(error.response.data);
            }
            return Promise.reject(error);
        }
    }

    async authenticateChannel(data) {
        const token = await getToken();
        try {
            const response = await axios.post(this.broadCastingAuthUrl, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async createFeedback(data) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/feedback`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async deleteUserAccount(userId) {
        const token = await getToken();
        const data = { '_method': 'DELETE' };
        try {
            const response = await axios.post(`${this.baseUrl}/account/${userId}/delete-account`, data, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return Promise.resolve(response);
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async doesConversationExist(recipientId)
    {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + `/conversation/exist`, {
                params: {
                    recipient_id: recipientId
                },
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async createConversation(recipientId, message)
    {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation`, {
                recipient_id: recipientId,
                message: message
            }, {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async archiveConversation(conversationId) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation/${conversationId}/archive`,
                {},
                {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async unarchiveConversation(conversationId) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation/${conversationId}/unarchive`,
                {},
                {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Accept': 'application/json',
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async searchConversations(searchString) {
        const token = await getToken();
        try {
            const response = await axios.get(this.baseUrl + `/conversation/search?q=${searchString}`,
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Accept': 'application/json',
                    }
                });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async deleteMessage(conversationId, messageId) {
        const token = await getToken();
        try {
            const response = await axios.post(this.baseUrl + `/conversation/${conversationId}/message/${messageId}`,
                {'_method': 'DELETE'},
                {
                    headers: {
                        'Authorization': 'Bearer ' + token,
                        'Accept': 'application/json',
                    }
                });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export default new BackendService();
