import { StyleSheet, Text, View, Image, TouchableOpacity, Animated } from 'react-native'
import React, {useCallback, useRef} from 'react'
import { ThemeColours } from '../Styles/Theme'
import { useNavigation } from '@react-navigation/native'
import RelativeLocalDateTime from './RelativeLocalDateTime'
import UnreadCount from './UnreadCount'
import Swipeable from 'react-native-gesture-handler/Swipeable'
import { RectButton } from 'react-native-gesture-handler'
import {SvgArchive, SvgUnarchive} from './SVG/ArchiveUnarchiveSVG'
import {lang} from '../Providers/LocalisationProvider'
import ConversationTypes from '../data/ConversationTypes'


const ConversationItem = ({
                              id,
                              item,
                              user,
                              unreadCount,
                              //logoUrl,
                              imgUrl,
                              //name,
                              dateTime,
                              lastMessage,
                              isArchived = false,
                              merchantId,
                              addressString,
                              merchantLat,
                              merchantLng,
                              onActionOpen = (swipeRow) => {},
                              onArchiveItem = (id) => {}
}) => {
    const navigation = useNavigation()
    const swipeRowRef = useRef(null)

    let name = ''
    let logoUrlString = ''
    if (item.conversation_type === ConversationTypes.REQUEST) {
        name = user.is_merchant ? item?.owner?.name : item?.merchant?.name
        logoUrlString = user.is_merchant ? item?.owner?.avatar_url : item?.merchant?.logo_url
    }
    else if (item.conversation_type === ConversationTypes.SOCIAL) {
        if (user.id === item?.owner?.id) {
            name = item?.recipient?.name
            logoUrlString = item?.recipient?.avatar_url
        } else {
            name = item?.owner?.name
            logoUrlString = item?.owner?.avatar_url
        }
    }
    const logoUrl = { uri: logoUrlString }

    const navigateToConversation = useCallback(() => {
        navigation.navigate('ConversationScreen', { id, logoUrl, imgUrl, name, merchantId, addressString, merchantLat, merchantLng })
    }, [navigation, id, logoUrl, imgUrl, name, merchantId, addressString, merchantLat, merchantLng])

    const handleToggleArchive = useCallback(() => {
        if (swipeRowRef.current) {
            swipeRowRef.current.close()
        }
        // Pass the item id to the callback so that we know which item to archive/unarchive
        onArchiveItem(id)
    }, [onArchiveItem, id])

    const handleSwipeOpen = useCallback(() => {
        // We pass a ref to the callback so the close method on the previous row can be called
        onActionOpen(swipeRowRef.current)
    }, [onActionOpen])

    const renderRightActions = useCallback((progress, dragX) => {
        const trans = dragX.interpolate({
            inputRange: [-80, 0],
            outputRange: [1, 0],
            extrapolate: 'clamp',
        })

        return (
            <RectButton style={styles.rightAction} onPress={handleToggleArchive}>
                <Animated.View style={[
                        {
                            transform: [{ translateX: trans }],
                            alignItems: 'center',
                        },
                    ]}>
                    <View style={{marginBottom: 10}}>
                    {isArchived ?
                        (<SvgUnarchive width={24} height={21} color={'#f8faff'} />)
                        :
                        (<SvgArchive width={24} height={22} color={'#f8faff'} />)
                    }
                    </View>
                    <Text style={styles.actionText}>{ isArchived ? lang.t('unarchive') : lang.t('archive') }</Text>
                </Animated.View>
            </RectButton>
        )
    }, [])

    return (
    <Swipeable
        ref={swipeRowRef}
        friction={2}
        leftThreshold={80}
        enableTrackpadTwoFingerGesture
        rightThreshold={40}
        renderRightActions={renderRightActions}
        childrenContainerStyle={styles.swipeRow}
        onSwipeableOpen={handleSwipeOpen}
    >
    <TouchableOpacity style={styles.container} activeOpacity={0.5}
        onPress={navigateToConversation}>
        <View style={styles.content}>
            <View style={styles.row}>
                <Image source={logoUrl} style={styles.avatar} />
                <View style={styles.col}>
                    <View style={styles.header}>
                        <Text style={styles.merchantName} numberOfLines={1} >{name}</Text>
                        <View>
                            <RelativeLocalDateTime dateTime={dateTime} />
                            <UnreadCount count={unreadCount} />
                        </View>
                    </View>
                    <Text style={styles.message} numberOfLines={1}>{lastMessage}</Text>
                    {isArchived ? (
                        <View style={styles.row}>
                            <View style={styles.badge}><Text style={styles.badgeText}>{lang.t('archived')}</Text></View>
                            <View style={styles.flex1}></View>
                        </View>
                    ) : null}
                </View>
            </View>
        </View>
    </TouchableOpacity>
    </Swipeable>
    )
}

export default ConversationItem

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: 15,
    },
    content: {
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
    },
    col: {
        flex: 1,
    },
    row: {
        flexDirection: "row",
        flexWrap: "wrap",
    },
    avatar: {
        width: 65,
        height: 65,
        marginRight: 10,
        borderRadius: 3,
    },
    header: {
        flex: 1,
        height: 20,
        flexDirection: 'row',
        marginBottom: 5,
    },
    merchantName: {
        flex: 1,
        flexGrow: 1,
        fontSize: 16,
        marginRight: 10,
        color: ThemeColours.midnightBlue,
    },
    message: {
        //width: '100%',
        fontSize: 14,
        color: ThemeColours.blueGrey,
        paddingRight: 80,
        marginBottom: 6,
    },
    swipeRow: {
        backgroundColor: ThemeColours.white,
    },
    rightAction: {
        backgroundColor: ThemeColours.blueGrey,
        minWidth: 80,
        justifyContent: 'center',
        alignItems: 'center',
    },
    actionText: {
        color: ThemeColours.white,
        textAlign: 'center',
    },
    badge: {
        backgroundColor: 'rgb(214, 224, 245)',
        borderRadius: 16,
        minHeight: 20,
        paddingVertical: 3,
        paddingHorizontal: 10,
    },
    badgeText: {
        fontSize: 12,
        textAlign: 'center',
        color: ThemeColours.blueGrey,
        textTransform: 'capitalize',
    },
    flex1: {
        flex: 1,
    }
})