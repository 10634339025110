import * as React from "react"
import { View } from "react-native"
import Svg, { Path } from "react-native-svg"

const SvgUserCircle = (props) => (
    <View style={{ aspectRatio: 1}}>
        <Svg height="100%" width="100%" viewBox="0 0 51 51" xmlns="http://www.w3.org/2000/svg" {...props}>
            <Path 
            d="M25.194 12.75a7.81 7.81 0 0 0-7.812 7.813 7.813 7.813 0 1 0 7.813-7.813zm0 14.063a6.257 6.257 0 0 1-6.25-6.25 6.257 6.257 0 0 1 6.25-6.25 6.257 6.257 0 0 1 6.25 6.25 6.256 6.256 0 0 1-6.25 6.25zm0-26.563c-13.808 0-25 11.191-25 25s11.192 25 25 25c13.809 0 25-11.191 25-25s-11.191-25-25-25zm0 48.438a22.853 22.853 0 0 1-13.974-4.73c-.059-6.013 4.834-10.895 10.85-10.895h6.25c6.016 0 10.908 4.884 10.85 10.898a22.896 22.896 0 0 1-13.976 4.727zm15.498-5.909C40.077 36.461 34.804 31.5 28.32 31.5h-6.25c-6.486 0-11.757 4.959-12.373 11.28-4.858-4.298-7.94-10.557-7.94-17.53 0-12.92 10.519-23.438 23.438-23.438 12.92 0 23.438 10.518 23.438 23.438 0 6.973-3.086 13.232-7.94 17.53z" 
            fill="#002357" 
            fill-rule="nonzero"
            />
        </Svg>
    </View>
)


export {
    SvgUserCircle
}
