import React, { useState } from 'react'
import { View, Text, TextInput, TouchableOpacity, Modal, Pressable, StyleSheet } from 'react-native'
import CountryCodes from '../data/CountryCodes.json'
import { Picker } from '@react-native-picker/picker'
import {Theme, ThemeColours} from '../Styles/Theme'
import {lang, useLocalisationContext} from '../Providers/LocalisationProvider'

const PhoneNumberInput = ({
      onPhoneNumberChange = (digits, phoneCountry) => {},
      phoneDigits = '',
      phoneCountry = 'GB',
      fullPhoneNumber = '',
      readonly = false,
      labelText = ''
}) => {

    const [showCountryModal, setShowCountryModal] = useState(false)

    const getCountryLabel = (countryCode) => {
        let country = {}
        if (!countryCode) {
            country = CountryCodes.find((item) => item.code === 'GB')
        } else {
            country = CountryCodes.find((item) => item.code === countryCode.toUpperCase())
        }

        return `${country.code} (${country.dial_code})`
    }

    const handleCountryChange = (itemValue) => {
        onPhoneNumberChange(phoneDigits, itemValue)
    }

    const handlePhoneDigitsChange = (digits) => {
        onPhoneNumberChange(digits, phoneCountry)
    }


    if (readonly) {
        return (
            <View style={styles.formGroup}>
                <Text style={styles.formLabel}>{labelText? labelText : lang.t('phone')}</Text>
                <View style={[styles.inputContainer, {flexDirection: 'row'}]}>
                    <View style={styles.formControl}>
                        <Text style={{fontSize: 18, lineHeight: 48, color: ThemeColours.midnightBlue}}>
                            {`${fullPhoneNumber}`}
                        </Text>
                    </View>
                </View>
            </View>
        )
    }

    return (
        <View style={styles.formGroup}>
        <Text style={styles.formLabel}>{labelText? labelText : lang.t('phone')}</Text>
        <View style={{width: '100%', flexDirection: 'row'}}>
            <View>
                <TouchableOpacity style={styles.modalControl} onPress={() => setShowCountryModal(true)}>
                    <Text style={{fontSize: 18, lineHeight: 48, color: ThemeColours.blueGrey}}>
                        { getCountryLabel(phoneCountry) }
                    </Text>
                </TouchableOpacity>
                <Modal
                    transparent={true}
                    visible={showCountryModal}
                    animationType="slide"
                    onRequestClose={() => {
                        setShowCountryModal(!showCountryModal)
                    }}
                    style={{flex: 1}}
                >
                    <Pressable style={{flex: 1, backgroundColor: 'rgba(0,0,0,0.2)'}} onPress={() => setShowCountryModal(false)}></Pressable>
                    <View style={{flex: 1, backgroundColor: 'white', width: '100%'}}>
                        <TouchableOpacity
                            onPress={() => setShowCountryModal(false)}
                            style={styles.dismissButton}
                        >
                            <Text style={styles.dismissText}>{ lang.t('done') }</Text>
                        </TouchableOpacity>
                        <Picker
                            selectedValue={phoneCountry}
                            onValueChange={(itemValue, itemIndex) => {
                                handleCountryChange(itemValue)
                            }}
                        >
                            {CountryCodes.map((country) => (
                                <Picker.Item
                                    label={`${country.name} (${country.dial_code})`}
                                    key={country.code}
                                    value={country.code}
                                />
                            ))}
                        </Picker>
                    </View>
                </Modal>
            </View>
            <View style={{flex: 1}}>
                <TextInput
                    style={styles.phoneDigitsControl}
                    onChangeText={handlePhoneDigitsChange}
                    value={phoneDigits}
                    placeholder={lang.t('phone')}
                    keyboardType="numeric"
                />
            </View>
        </View>
    </View>
    )
}

export default PhoneNumberInput

const styles = StyleSheet.create({
    formGroup: {
        ...Theme.formGroup,
    },
    formLabel: {
        ...Theme.formLabel,
    },
    inputContainer: {
        ...Theme.inputContainer,
    },
    formControl: {
        ...Theme.formControl,
    },
    phoneDigitsControl: {
        ...Theme.formControl,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    modalControl: {
        ...Theme.formControl,
        borderRightWidth: 0,
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },
    formValidationLabel: {
        ...Theme.formValidationLabel,
    },
    dismissButton:  {
        height: 40,
        width: '100%',
        paddingHorizontal: 20,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    dismissText: {
        color: ThemeColours.midnightBlue,
        lineHeight: 40,
        fontSize: 18,
        marginLeft: 'auto',
    }
});
