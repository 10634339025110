import { Image, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { ThemeColours } from '../Styles/Theme'
import { lang } from '../Providers/LocalisationProvider'
import { showLocation } from 'react-native-map-link'
import * as Application from 'expo-application'

const mapIcon = require('../assets/images/viewOnMapIcon-2x.png')
const backIcon = require('../assets/images/chevronLeftIcon-2x.png')

const ConversationHeader = ({merchantId, logoUrl, imgUrl, name, addressString, isMerchant, merchantLat, merchantLng}) => {

    const navigation = useNavigation();

    const openNavigation = () => {
        showLocation({
            latitude: merchantLat,
            longitude: merchantLng,
            //sourceLatitude: -8.0870631, // optionally specify starting location for directions
            //sourceLongitude: -34.8941619, // not optional if sourceLatitude is specified
            title: addressString, // optional
            googleForceLatLon: false, // optionally force GoogleMaps to use the latlon for the query instead of the title
            //googlePlaceId: 'ChIJGVtI4by3t4kRr51d_Qm_x58', // optionally specify the google-place-id
            alwaysIncludeGoogle: true, // optional, true will always add Google Maps to iOS and open in Safari, even if app is not installed (default: false)
            //dialogTitle: 'This is the dialog Title', // optional (default: 'Open in Maps')
            //dialogMessage: 'This is the amazing dialog Message', // optional (default: 'What app would you like to use?')
            //cancelText: 'This is the cancel button text', // optional (default: 'Cancel')
            appsWhiteList: ['apple-maps', 'google-maps', 'waze'],
            naverCallerName: Application.applicationId, // to link into Naver Map You should provide your appname which is the bundle ID in iOS and applicationId in android.
            // appTitles: { 'google-maps': 'My custom Google Maps title' }, // optionally you can override default app titles
            // app: 'uber',  // optionally specify specific app to use
            directionsMode: 'car',
        })
    }

    const viewProfile = () => {
        navigation.navigate('MerchantScreen', {id: merchantId, name, logoUrl, imgUrl})
    }

    return (
    <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Image source={backIcon} style={styles.backIcon} />
        </TouchableOpacity>
        <Image source={logoUrl} style={styles.logo} />
        <View style={styles.flex1}>
            <Text style={styles.merchantName} numberOfLines={1}>{name}</Text>
            {(merchantId && !isMerchant) ?
            <TouchableOpacity onPress={viewProfile}>
                <Text style={styles.profileLink}>{lang.t('view_profile')}</Text>
            </TouchableOpacity>
            : null}
        </View>
        {(merchantId && !isMerchant) ?
        <TouchableOpacity onPress={openNavigation}>
            <Image source={mapIcon} style={styles.mapIcon} />
        </TouchableOpacity>
        : null}
    </View>
    )
}

export default ConversationHeader

const styles = StyleSheet.create({
    header: {
        height: 70,
        flexDirection: 'row',
        backgroundColor: ThemeColours.white,
        paddingRight: 20,
        alignItems: 'center',
        borderBottomColor: ThemeColours.steelBlueShadow,
        borderBottomWidth: 1,
    },
    backButton: {
        paddingLeft: 20,
        paddingRight: 26,
        paddingTop: 12,
        paddingBottom: 12
    },
    logo: {
        width: 40,
        height: 40,
        marginRight: 10,
        borderRadius: 2,
    },
    merchantName: {
        fontSize: 16,
        marginRight: 10,
        color: ThemeColours.midnightBlue,
    },
    profileLink: {
        fontSize: 12,
        color: ThemeColours.blueGrey,
        textDecorationLine: 'underline',
    },
    flex1: {
        flex: 1,
    },
    mapIcon: {
        width: 22,
        height: 20,
    },
    backIcon: {
        width: 9,
        height: 16
    },
})